import FacebookIcon from "../../assets/img/facebook-icon.png";
import XIcon from "../../assets/img/x-icon.png";
import InstagramIcon from "../../assets/img/instagram-icon.png";

const navigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
      { name: 'Contacto', href: '/contacto' },
  ],
  company: [
      { name: 'Casos', href: '/casos' },
      { name: 'Servicios', href: '/servicios' },
      { name: 'Nosotros', href: '/nosotros' },
      { name: 'Carreras', href: '/carreras' },
      { name: 'Blog', href: '/blog' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/Fundacion-Arduvi-103873258896429',
      icon: FacebookIcon,
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/fundacionarduvi/',
      icon: InstagramIcon,
    },
    {
      name: 'X',
      href: 'https://x.com/arduvifundacion?s=21&t=Ff1wK_WTR7wsA-xB_OgMsg',
      icon: XIcon,
    },
  ],
}
function Header(){



    return(
      <main>
        <div className="relative px-6 lg:px-8">
          <div className="mx-auto max-w-full xl:mx-12 xl:pt-32 xl:pb-40 lg:pt-40 lg:pb-40 pt-24 pb-12 ">
            <div>
              <div>
                <h1 className="text-4xl font-semibold tracking-tight pb-8  sm:text-7xl">
                  Donaciones
                </h1>
                <p className="mt-6 text-xl leading-10 text-black max-w-5xl">
                  Puedes realizar donaciones a diferentes causas que apoyamos en la Fundación Arduvi.
                </p>
                <div className="absolute bottom-0 left-0 flex space-x-6 xl:mx-20 mx-6">
                  {navigation.social.map((item) => (
                    <a key={item.name} href={item.href} target='_blank' rel="noreferrer" className="text-gray-900 hover:text-gray-800">
                      <span className="sr-only">{item.name}</span>
                      <img src={item.icon} alt="Social media icon" className="h-8 w-8" aria-hidden="true" />
                    </a>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
}

export default Header
