import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import { useEffect, useState } from "react";
import { Helmet } from "react-helmet-async";
import { EnvelopeIcon, PhoneIcon } from "@heroicons/react/24/outline";
import { Switch } from "@headlessui/react";
import { Link } from "react-router-dom";
import axios from "axios";
import CircleLoader from "react-spinners/CircleLoader";
import { Button, Modal, Select } from 'flowbite-react';

function Contact() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);

	const [enabled, setEnabled] = useState(false);

	const [loading, setLoading] = useState(false);

	const [formData, setFormData] = useState({
		name: "",
		email: "",
		subject: "",
		message: "",
		phone: "",

	});

	const [openModalSuccess, setOpenModalSuccess] = useState(false);
	const [openModalFail, setOpenModalFail] = useState(false);
	const [openModalWarning, setOpenModalWarning] = useState(false);

	const { name, email, subject, message, phone } = formData;

	const onChange = (e) =>
		setFormData({ ...formData, [e.target.name]: e.target.value });

	const onSubmit = (e) => {
		e.preventDefault();

		if (enabled) {
			setLoading(true);

			const config = {
				headers: {
					"Content-Type": "application/json",
				},
			};
			const formData = new FormData();
			formData.append("name", name);
			formData.append("email", email);
			formData.append("subject", subject);
			formData.append("message", message);
			formData.append("phone", phone);
			// enviar el archivo al servidor


			const fecthData = async () => {
				try {
					const res = await axios.post(
						`${process.env.REACT_APP_API_URL}/contact/`,
						formData,
						config
					);
					setLoading(false);
					if (res.status === 200) {
						setOpenModalSuccess(true);
					} else {
						setOpenModalFail(true);
					}
				} catch (error) {
					if (error.response) {
						setLoading(false);
						alert("Disculpa los incovenientes estamos en construccion.");
						setOpenModalWarning(true);
					}

				}
			};
			fecthData();
		}
	};

	return (
		<Layout>
			<Helmet>
				<title>Fundación Arduvi | Contacto</title>
				<meta
					name="description"
					content="Organización sin ánimo de lucro."
				/>
				<meta
					name="keywords"
					content="Organización sin ánimo de lucro."
				/>
				<meta name="robots" content="all" />
				<link rel="canonical" href="https://fundacionarduvi.org/" />
				<meta name="author" content="Fundación Arduvi" />
				<meta name="publisher" content="Fundación Arduvi" />

				{/* Social Media Tags */}
				<meta property="og:title" content="Fundación Arduvi | Inclusión con Amor" />
				<meta
					property="og:description"
					content="Organización sin ánimo de lucro."
				/>
				<meta property="og:url" content="https://fundacionarduvi.org/" />
				<meta
					property="og:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>

				<meta name="twitter:title" content="Fundación Arduvi | Inclusión con Amor" />
				<meta
					name="twitter:description"
					content="Organización sin ánimo de lucro."
				/>
				<meta
					name="twitter:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>
			<header className="flex justify-center">
				<Navbar />
			</header>
			<div className="pt-28">
				<div className="relative bg-white">
					<div className="absolute inset-0">
						<div className="absolute inset-y-0 left-0 w-1/2 bg-white" />
					</div>
					<div className="relative mx-auto max-w-7xl lg:grid lg:grid-cols-5">
						<div className="bg-white py-16 px-4 sm:px-6 lg:col-span-2 lg:px-8 lg:py-24 xl:pr-12">
							<div className="mx-auto max-w-lg">
								<h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
									Puedes contactarnos si deseas apoyarnos o tener más información.
								</h2>
								<p className="mt-3 text-lg leading-6 text-gray-500">
									En la Fundación Arduvi estamos comprometidos con la inclusión y el amor, si deseas apoyarnos o necesitas más información, no dudes en contactarnos.
								</p>
								<dl className="mt-8 text-base text-gray-500">
									<div>
										<dt className="sr-only">Postal address</dt>
										<dd>
											<p>Calle 8n El Bosque</p>
											<p>Cucuta-Norte de Santander</p>
										</dd>
									</div>
									<div className="mt-6">
										<dt className="sr-only">Numero de Contacto</dt>
										<dd className="flex">
											<PhoneIcon
												className="h-6 w-6 flex-shrink-0 text-gray-400"
												aria-hidden="true"
											/>
											<span className="ml-3">+57(311)215-8125</span>
										</dd>
									</div>
									<div className="mt-3">
										<dt className="sr-only">Email</dt>
										<dd className="flex">
											<EnvelopeIcon
												className="h-6 w-6 flex-shrink-0 text-gray-400"
												aria-hidden="true"
											/>
											<span className="ml-3">info@fundacionarduvi.org</span>
										</dd>
									</div>
								</dl>
							</div>
						</div>
						<div className="bg-white py-16 px-4 sm:px-6 lg:col-span-3 lg:py-24 lg:px-8 xl:pl-12">
							<div className="mx-auto max-w-lg lg:max-w-none">
								<form
									onSubmit={(e) => onSubmit(e)}
									className="grid grid-cols-1 gap-y-6"
								>
									<div>
										<label htmlFor="full-name" className="sr-only">
											Nombre Completo
										</label>
										<input
											type="text"
											name="name"
											value={name}
											required
											onChange={(e) => onChange(e)}
											autoComplete="name"
											className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-yellow-200 focus:ring-orange-200"
											placeholder="Nombre Completo"
										/>
									</div>

									<div>
										<label htmlFor="email" className="sr-only">
											E-mail
										</label>
										<input
											value={email}
											onChange={(e) => onChange(e)}
											name="email"
											type="email"
											required
											className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-yellow-200 focus:ring-orange-200"
											placeholder="E-mail"
										/>
									</div>

									<div>
										<label htmlFor="price" className="sr-only">
											Número de Teléfono
										</label>
										<div className="relative mt-1 rounded-md shadow-sm">

											<input
												type="tel"
												name="phone"
												value={phone}
												required
												onChange={(e) => onChange(e)}
												className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-yellow-200 focus:ring-orange-200"
												placeholder="573201234567"
												aria-describedby="price-currency"
											/>
										</div>
									</div>

									<div>
										<label className="sr-only">Asunto</label>
										<input
											type="text"
											name="subject"
											value={subject}
											required
											onChange={(e) => onChange(e)}
											className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-yellow-200 focus:ring-orange-200"
											placeholder="Asunto"
										/>
									</div>

									<div>
										<label htmlFor="message" className="sr-only">
											Mensaje
										</label>
										<textarea
											id="message"
											name="message"
											value={message}
											required
											onChange={(e) => onChange(e)}
											rows={4}
											className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-yellow-200 focus:ring-orange-200"
											placeholder="Mensaje"
										/>
									</div>


									<div className="px-4 py-5 sm:px-6">
										<div className="-ml-4 -mt-2 flex flex-wrap items-center justify-between sm:flex-nowrap">
											<div className="grid-cols-12 ml-4 mt-2 flex">
												<Switch
													checked={enabled}
													onChange={setEnabled}
													className={`${
														enabled ? "bg-yellow-button" : "bg-gray-300"
													}
                                col-span-3 relative inline-flex h-[32px] w-[72px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2  focus-visible:ring-white focus-visible:ring-opacity-75`}
												>
													<span
														aria-hidden="true"
														className={`${
															enabled ? "translate-x-10" : "translate-x-0"
														}
                                    pointer-events-none inline-block h-[28px] w-[28px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out`}
													/>
												</Switch>
												<p className="col-span-9 ml-4 text-md font-regular leading-6 text-gray-600">
												Al seleccionar esta opción, estás aceptando nuestros{" "}
													<span className="text-blue-500">
														<Link to="/terminos-y-condiciones" target="_blank">Términos del Servicio</Link>
													</span>{" "}
													y{" "}
													<span className="text-blue-500">
														<Link to="/privacidad" target="_blank">Políticas de Privacidad</Link>
													</span>
													.
												</p>
											</div>
											<div className="ml-4 mt-2 flex-shrink-0">
												{loading ? (
													<div className="relative inline-flex items-center rounded-md border border-transparent bg-yellow-button px-4 py-3 text-lg font-medium text-white shadow-sm hover:bg-yellow-button focus:outline-none focus:ring-2 focus:bg-yellow-buttonfocus:ring-offset-2">
														<CircleLoader
															loading={loading}
															size={25}
															color="#ffffff"
														/>
													</div>
												) : (
													<button
														type="submit"
														className="inline-flex ml-6 items-center rounded-md border border-transparent bg-yellow-button px-6 py-1.5 text-base font-medium text-white shadow-sm hover:bg-blue-button transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2"
													>
														Enviar Mensaje
													</button>
												)}
											</div>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</div>
				<Modal show={openModalSuccess} size={"md"} onClose={() => setOpenModalSuccess(false)}>
					<Modal.Header>Mensaje enviado</Modal.Header>
					<Modal.Body>
						<div className="space-y-6 p-6">
							<p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
								Tu mensaje ha sido enviado con éxito, en breve nos pondremos en contacto contigo.
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => setOpenModalSuccess(false)}>Cerrar</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={openModalFail} size={"md"} onClose={() => setOpenModalFail(false)}>
					<Modal.Header>Ocurrió un error</Modal.Header>
					<Modal.Body>
						<div className="space-y-6 p-6">
							<p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
								Tu mensaje no ha sido enviado, por favor, intenta nuevamente.
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => setOpenModalFail(false)}>Cerrar</Button>
					</Modal.Footer>
				</Modal>
				<Modal show={openModalFail} size={"md"} onClose={() => setOpenModalWarning(false)}>
					<Modal.Header>Disculpa los incovenientes.</Modal.Header>
					<Modal.Body>
						<div className="space-y-6 p-6">
							<p className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
								Sentimos los invonvenientes ocaionados, estamos en construcción.
							</p>
						</div>
					</Modal.Body>
					<Modal.Footer>
						<Button onClick={() => setOpenModalWarning(false)}>Cerrar</Button>
					</Modal.Footer>
				</Modal>
			</div>
			<Footer />
		</Layout>
	);
}
export default Contact;
