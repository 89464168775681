import Modal from "../modals/modal";
import Img1 from "../../assets/img/icons/comunidad.png";
import Img2 from "../../assets/img/icons/social.png";
import Img3 from "../../assets/img/icons/estudiante.png";

const features = [
  { 
    id: 1,
    button: <>
      <div className="relative flex flex-col items-center">
        <div className="bg-gray-100 p-3 rounded-full shadow-2xl absolute -top-10">
          <img src={Img1} alt="ícono descriptivo" className="w-14" />
        </div>
        <div className="bg-gray-100 rounded-xl h-full w-full">
          <p className="text-base text-gray-600 pt-10 px-4 pb-3 text-center font-bold">Comunitario</p>
        </div>
      </div>
    </>,
    name: 'Voluntario Comunitario',
    content: <>
      <p>
        Es aquella persona que lidera, promueve y participa en movimientos cívicos, vecinales, colectivos y de participación ciudadana para el desarrollo y la cohesión de su comunidad o su colectivo. Este tipo de voluntario dedica un máximo de cuatro (4) horas semanales a la promoción de estrategias comunitarias que aporten a la prevención, la mitigación y la respuesta ante riesgos, así como a aportar a la integración local de personas de especial protección, desde una labor de paridad y red de apoyo entre iguales. Este tipo de voluntario no cuenta con un título académico en educación superior o técnica y centra sus acciones en el fortalecimiento del trabajo en red en las comunidades y con los colectivos priorizados por el programa.
      </p>
      <p className="font-bold mt-2">
        Duración del Voluntariado y certificación
      </p>
      <p>
        El tiempo de vinculación de un voluntario o voluntaria varía según el tipo de voluntariado que desarrolla, inicialmente el voluntariado se dará bajo un acuerdo de 6 meses con posibilidad de prórroga de mutuo acuerdo hasta los 12 meses en el caso del social y el estudiante, y hasta por 24 meses en el caso de los comunitarios.
      </p>
      <p>
        El tiempo cumplido se certificará por escrito, especificando el tiempo de horas semanales, el programa – proyecto al cual se estuvo vinculado y las acciones desarrolladas durante el voluntariado. Esta certificación será emitida por la coordinación del programa y contará con la firma de la persona encargada y de la Dirección General. En la certificación se dejará claridad del tipo de vinculación, de los límites de las funciones y la NO vinculación laboral o contractual entre el voluntario y la Fundación.
      </p>
      <p>
        La Fundación ARDUVI lanzará convocatoria a través de su página web y redes sociales oficiales con vigencia de 15 días calendario. El proceso de selección se desarrollará de la siguiente manera:
      </p>
      <ul className="list-disc pl-5 space-y-2">
        <li>La Dirección delegará un equipo interdisciplinar liderado por la coordinación del Programa de Voluntariado, que se encargará de la revisión de las hojas de vida (formato ARDUVI) de los postulantes. Se contará con una semana calendario para esta etapa de revisión.</li>
        <li>Serán preseleccionadas 40 hojas de vida para cada tipo de voluntariado, las personas serán citadas y entrevistadas. Se incluirá la solicitud de una carta de motivación.</li>
        <li>
          Posterior a esto se seleccionarán 20 hojas de vida por cada tipo de voluntariado así:
          <div className="flex w-full">
            <div className="w-1/3 font-bold border p-1 text-sm">
              Tipo de Voluntariado Comunitario
            </div>
            <div className="w-1/3 border-y border-e text-sm">
              <div className="font-bold border-y p-1">
                Liderazgo colectivo
              </div>
              <div className="p-1">
                8
              </div>
            </div>
            <div className="w-1/3 border-y border-e text-sm">
              <div className="font-bold border-y p-1">
                Liderazgo en Comunidad
              </div>
              <div className="p-1">
                12
              </div>
            </div>
          </div>
        </li>
        <li>
          Se garantizará que al menos el 50% de las personas seleccionadas sean mujeres y un porcentaje mayor del 10 % de personas OSIGD (orientación sexual e identidad de género diversa) si las personas postuladas así se identifican. Además, se deberá garantizar la selección de personas con capacidades especiales o aquellas con discapacidad, si cumplieran el perfil requerido, promoviendo así la inclusión y la equidad en el proceso de selección.</li>
        <li>
          Las personas seleccionadas serán contactadas directamente para iniciar las etapas del voluntariado.
        </li>
      </ul>
      
    </>,
  },
  { 
    id: 2,
    button: <>
      <div className="relative flex flex-col items-center">
        <div className="bg-gray-100 p-3 rounded-full shadow-2xl absolute -top-10">
          <img src={Img2} alt="ícono descriptivo" className="w-14" />
        </div>
        <div className="bg-gray-100 rounded-xl h-full w-full">
          <p className="text-base text-gray-600 pt-10 px-4 pb-3 text-center font-bold">Social</p>
        </div>
      </div>
    </>,
    name: 'Voluntario ⁠Social',
    content: <>
      <p>
        Es aquella persona que cuenta con título profesional, tecnológico o técnico y los conocimientos para aportar a los procesos de cohesión social, inclusión y fortalecimiento comunitario que se vienen adelantando desde la Fundación. El voluntario tendrá la oportunidad, con el acompañamiento del equipo base y la coordinación del voluntariado, de apoyar a niños, niñas, adolescentes, jóvenes, mujeres y familias mediante la incorporación a los programas y proyectos desarrollados por la Fundación, dedicando un máximo de cinco (5) horas por semana.​
      </p>
      <p className="font-bold mt-2">
        Duración del Voluntariado y certificación
      </p>
      <p>
        El tiempo de vinculación de un voluntario o voluntaria varía según el tipo de voluntariado que desarrolla, inicialmente el voluntariado se dará bajo un acuerdo de 6 meses con posibilidad de prórroga de mutuo acuerdo hasta los 12 meses en el caso del social y el estudiante, y hasta por 24 meses en el caso de los comunitarios.
      </p>
      <p>
        El tiempo cumplido se certificará por escrito, especificando el tiempo de horas semanales, el programa – proyecto al cual se estuvo vinculado y las acciones desarrolladas durante el voluntariado. Esta certificación será emitida por la coordinación del programa y contará con la firma de la persona encargada y de la Dirección General. En la certificación se dejará claridad del tipo de vinculación, de los límites de las funciones y la NO vinculación laboral o contractual entre el voluntario y la Fundación.
      </p>
      <p>
        La Fundación ARDUVI lanzará convocatoria a través de su página web y redes sociales oficiales con vigencia de 15 días calendario. El proceso de selección se desarrollará de la siguiente manera:
      </p>
      <ul className="list-disc pl-5 space-y-2">
        <li>La Dirección delegará un equipo interdisciplinar liderado por la coordinación del Programa de Voluntariado, que se encargará de la revisión de las hojas de vida (formato ARDUVI) de los postulantes. Se contará con una semana calendario para esta etapa de revisión.</li>
        <li>Serán preseleccionadas 40 hojas de vida para cada tipo de voluntariado, las personas serán citadas y entrevistadas. Se incluirá la solicitud de una carta de motivación.</li>
        <li>
          Posterior a esto se seleccionarán 20 hojas de vida por cada tipo de voluntariado así:
          <div className="flex w-full">
            <div className="w-1/3 font-bold border p-1 text-sm">
              Tipo de Voluntariado Social
            </div>
            <div className="w-1/3 border-y border-e text-sm">
              <div className="font-bold border-y p-1">
                Profesional
              </div>
              <div className="p-1">
                12
              </div>
            </div>
            <div className="w-1/3 border-y border-e text-sm">
              <div className="font-bold border-y p-1">
                Técnicos o tecnólogos
              </div>
              <div className="p-1">
                8
              </div>
            </div>
          </div>
        </li>
        <li>
          Se garantizará que al menos el 50% de las personas seleccionadas sean mujeres y un porcentaje mayor del 10 % de personas OSIGD (orientación sexual e identidad de género diversa) si las personas postuladas así se identifican. Además, se deberá garantizar la selección de personas con capacidades especiales o aquellas con discapacidad, si cumplieran el perfil requerido, promoviendo así la inclusión y la equidad en el proceso de selección.</li>
        <li>
          Las personas seleccionadas serán contactadas directamente para iniciar las etapas del voluntariado.
        </li>
      </ul>
    </>
  },
  { 
    id: 3,
    button: <>
      <div className="relative flex flex-col items-center">
        <div className="bg-gray-100 p-3 rounded-full shadow-2xl absolute -top-10">
          <img src={Img3} alt="ícono descriptivo" className="w-14" />
        </div>
        <div className="bg-gray-100 rounded-xl h-full w-full">
          <p className="text-base text-gray-600 pt-10 px-4 pb-3 text-center font-bold">Estudiante</p>
        </div>
      </div>
    </>,
    name: 'Voluntario Estudiante',
    content: <>
      <p>
        Esta modalidad de voluntariado varia de las anteriores al enmarcarse en acuerdo vigentes entre Fundación ARDUVI e instituciones educativas legalmente constituidas. El Voluntario Estudiante deberá ser mayor de 15 y menor de 25 años, estar inscrito en una institución educativa secundaria, de educación superior o universitaria y contar con el aval de esta institución para vincularse al programa de voluntariado. Su tiempo de dedicación podrán oscilar entre las 6 y las 12 horas semanales, donde se cumplirán actividades bajo cronograma y en acompañamiento a los profesionales designados como tutores en acuerdo con la coordinación del programa y la Dirección.
      </p>
      <p className="font-bold mt-2">
        Duración del Voluntariado y certificación
      </p>
      <p>
      Una vez se firmen los acuerdos con las instituciones educativas, se lanzará la convocatoria a través de los canales oficiales con una vigencia de una semana calendario. El proceso de selección se desarrollará de la siguiente manera:
      </p>
      <ul className="list-disc pl-5 space-y-2">
        <li>La Dirección delegará un equipo interdisciplinar liderado por la coordinación del Programa de Voluntariado, que se encargará de la revisión de las hojas de vida (formato ARDUVI) de los postulantes. Se contará con cinco (5) días calendario para esta etapa de revisión.</li>
        <li>Serán preseleccionadas 20 hojas de vida, las personas serán citadas y entrevistadas. Se incluirá la solicitud de una carta de motivación.</li>
        <li>
          Al final serán seleccionadas 10 hojas de vida para el voluntariado así:
          <div className="flex w-full">
            <div className="w-1/3 font-bold border p-1 text-sm flex items-center text-center">
              Tipo de Voluntariado Estudiantil
            </div>
            <div className="w-1/3 border-y border-e text-sm">
              <div className="font-bold border-y p-1">
                Grupo A (entre 15 y 17años)
              </div>
              <div className="p-1 text-center">
                4
              </div>
              <div className="font-bold border-y p-1">
                Secundaria y Media Técnica
              </div>
              <div className="p-1 text-center">
                5
              </div>
            </div>
            <div className="w-1/3 border-y border-e text-sm">
              <div className="font-bold border-y p-1">
                Grupo B (Entre 18 y 25 años)
              </div>
              <div className="p-1 text-center">
                6
              </div>
              <div className="font-bold border-y p-1">
                Estudiantes de educación superior o universitaria
              </div>
              <div className="p-1 text-center">
                5
              </div>
            </div>
          </div>
        </li>
        <li>
          Se garantizará que al menos el 60% de las personas seleccionadas sean mujeres y un porcentaje mayor del 10 % de personas OSIGD (orientación sexual e identidad de género diversa) si las personas postuladas así se identifican. Además, se deberá garantizar la selección de personas con habilidades excepcionales, incluyendo aquellas con discapacidad, si cumplieran el perfil requerido, promoviendo así la inclusión y la equidad en el proceso de selección.
        </li>
        <li>
          Las personas seleccionadas serán contactadas directamente para iniciar las etapas del voluntariado.
        </li>
      </ul>
    </>
  },
]

export default function Fundamentals() {
  return (
    <div className="bg-section-pattern bg-cover bg-no-repeat bg-fixed">
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:gap-x-8 lg:py-24 lg:px-8">
        <div>
          <h2 className="mt-2 text-3xl sm:text-4xl font-bold tracking-tight text-yellow-button text-center mb-8">Tipos de voluntariado</h2>
          <p className="mt-4 text-lg text-white text-center mb-14">
            Dentro de lo contemplado, la Fundación Arduvi promueve en su programa tres tipos que se muestran a continuación:
          </p>
        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0 container max-w-3xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-x-6 gap-y-14">
            {features.map((feature, index) => (
              <Modal button={feature.button} title={feature.name} content={feature.content} key={index}/>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}