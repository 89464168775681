// import Clients from "components/about/Clients";
import Header from "components/about/Header";
import Images from "components/about/Images";
import Team from "components/about/Team";
import TestStats from "components/about/TestStats";
import Mission from "components/about/Mission";
import Vision from "components/about/Vision";
import CTA from "components/home/CTA";
import Organization from "components/about/Organization";
import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";

function About() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout>
			<Helmet>
				<title>Fundación Arduvi | Nosotros</title>
				<meta
					name="description"
					content="Organización sin ánimo de lucro."
				/>
				<meta
					name="keywords"
					content="Organización sin ánimo de lucro."
				/>
				<meta name="robots" content="all" />
				<link rel="canonical" href="https://fundacionarduvi.org/" />
				<meta name="author" content="Fundación Arduvi" />
				<meta name="publisher" content="Fundación Arduvi" />

				{/* Social Media Tags */}
				<meta property="og:title" content="Fundación Arduvi | Inclusión con Amor" />
				<meta
					property="og:description"
					content="Organización sin ánimo de lucro."
				/>
				<meta property="og:url" content="https://fundacionarduvi.org/" />
				<meta
					property="og:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>

				<meta name="twitter:title" content="Fundación Arduvi | Inclusión con Amor" />
				<meta
					name="twitter:description"
					content="Organización sin ánimo de lucro."
				/>
				<meta
					name="twitter:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>

			<header className="flex justify-center">
				<Navbar />
			</header>
			<div className="pt-28">
				<Header />
				<TestStats />
				<Images />
				<Mission />
				<Vision />
				<CTA />
				<Organization />
				<Team />
			</div>
			<Footer />
		</Layout>
	);
}
export default About;
