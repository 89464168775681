
export default function Business() {

    return (
      <div className="bg-gray-50">
        <div className="container mx-auto max-w-3xl py-12 lg:py-16">
          <h2 className="text-3xl text-center font-bold tracking-tight text-yellow-button sm:text-4xl mb-4">
            Empresas Socialmente Responsables
          </h2>
          <p className="text-gray-500">La Fundación Arduvi tiene dentro sus líneas de acción asesorar a las empresas en la implementación y desarrollo  de la Responsabilidad Social Empresarial, un ejemplo de ello son:</p>
        </div>
      </div>
    )
  }
