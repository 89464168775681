import { BrowserRouter as Router } from 'react-router-dom'
import store from './store';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';


import AnimatedRoutes from 'Routes';


function App() {
  
  return (
    <HelmetProvider>
      <Helmet>
        <title>Fundación Arduvi | Inclusión con amor</title>
        <meta name="description" content="Organización sin ánimo de lucro." />
        <meta name="keywords" content='Organización sin ánimo de lucro' />
        <meta name="robots" content='all' />
        <link rel="canonical" href="https://fundacionarduvi.org/" />
        <meta name="author" content='Fundación Arduvi' />
        <meta name="publisher" content='Fundación Arduvi' />

        {/* Social Media Tags */}
        <meta property="og:title" content='Fundación Arduvi | Inclusión con Amor' />
        <meta property="og:description" content='Organización sin ánimo de lucro.' />
        <meta property="og:url" content="https://fundacionarduvi.org/" />
        <meta property="og:image" content='https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg' />

        <meta name="twitter:title" content='Fundación Arduvi | Inclusión con Amor' />
        <meta
            name="twitter:description"
            content='Organización sin ánimo de lucro.'
        />
        <meta name="twitter:image" content='https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg' />
        <meta name="twitter:card" content="summary_large_image" />
      </Helmet>
      <Provider store={store}>
        <Router>

            <AnimatedRoutes/>
        </Router>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
