import EmpanorteImg from '../../assets/img/business-empanorte.jpg';

export default function Case2() {
	return (
		<div className="relative py-16 sm:py-24">
			<div className="container mx-auto lg:grid lg:grid-cols-2 lg:items-start lg:gap-24 lg:px-8">
				<div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
					{/* Content area */}
					<div className="pt-12 sm:pt-16 lg:pt-20">
						<h2 className="text-3xl font-bold tracking-tight text-yellow-button sm:text-4xl">
							Empaquetadora del Norte
						</h2>
						<div className="mt-6 space-y-6 text-gray-500">
							<p className="text-lg">
								Desde el 2011 la Comercializadora Internacional Empaquetadora del Norte S.A.S BIC, empresa que es liderada por Arvey Duque Villamizar, tiene presencia en el mercado nortesantandereano, colombiano e internacional ofreciendo diferentes marcas en granos alimenticios, aceite y arroz.
							</p>
							<div>
								<a href="https://empaquetadoradelnorte.com/" className='border-2 border-yellow-button px-6 py-2  bg-transparent text-yellow-button rounded-md' target="_blank" rel="noreferrer">Ir al sitio web</a>
							</div>
						</div>
					</div>
				</div>
				<div className="relative sm:py-16 lg:py-0">
					<div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen -z-10">
						<svg
							className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
							width={404}
							height={392}
							fill="none"
							viewBox="0 0 404 392"
						>
							<defs>
								<pattern
									id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
									x={0}
									y={0}
									width={20}
									height={20}
									patternUnits="userSpaceOnUse"
								>
									<rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
								</pattern>
							</defs>
							<rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
						</svg>
					</div>
					<div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
						{/* Testimonial card*/}
						<div className="relative mt-10 lg:mt-0 overflow-hidden rounded-2xl py-64 shadow-xl">
							<img
								className="absolute inset-0 h-full w-full object-cover"
								src={EmpanorteImg}
								alt="Grupo de personas"
							/>
						</div>
					</div>
				</div>
			</div>
		</div>
	)
}
