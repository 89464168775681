import React from 'react';
import LogosSlider from '../slider/LogosSlider';
import ArduviHolding from '../../assets/img/arduvi-holding.png';
import Empanorte from '../../assets/img/empanorte.png';
import CafeArduvi from '../../assets/img/cafe-arduvi.png';
import Corpoindustrial from '../../assets/img/corpoindustrial.png';
import Refinorte from '../../assets/img/refinorte.png';
import Watti from '../../assets/img/watti.png';
import AlcaldiaCucuta from '../../assets/img/alcaldia-cucuta.png';
import BibliotecaPublica from '../../assets/img/biblioteca.png';
import Sena from '../../assets/img/sena.png';
import Dualidad from '../../assets/img/dualidad.png';
import Comfanorte from '../../assets/img/comfanorte.png';
import PuntoFama from '../../assets/img/punto-fama.png';
import TF from '../../assets/img/tf.png';

const logos = [
  {id: 1, img: ArduviHolding, alt: 'Arduvi Holding Empresarial'},
  {id: 2, img: Empanorte, alt: 'Empaquetadora del Norte'},
  {id: 3, img: CafeArduvi, alt: 'Restaurante y Café Arduvi'},
  {id: 4, img: Refinorte, alt: 'Refinorte'},
  {id: 5, img: Watti, alt: 'Watti'},
  {id: 6, img: Corpoindustrial, alt: 'Corpoinustrial'},
  {id: 7, img: AlcaldiaCucuta, alt: 'Alcaldía de Cúcuta'},
  {id: 8, img: BibliotecaPublica, alt: 'Biblioteca Pública Julio Pérez Ferrero'},
  {id: 9, img: Sena, alt: 'SENA'},
  {id: 10, img: Dualidad, alt: 'Dualidad'},
  {id: 11, img: Comfanorte, alt: 'Comfanorte'},
  {id: 12, img: PuntoFama, alt: 'Punto y Fama'},
  {id: 13, img: TF, alt: 'TF'},
]

export default function LogoCloud() {
    return (
      <div className="bg-white">
        <div className="container mx-auto max-w-7xl py-12 px-4 sm:px-6 lg:px-8">
          <div>
            <h2 className="mt-2 text-3xl font-bold tracking-tight text-yellow-button text-center mb-8">Aliados estratégicos</h2>
          </div>
          <LogosSlider>
            {logos.map ((logo, index) => (
              <div key={index}>
                <div className='flex justify-center'>
                  <div className="h-40 w-40">
                    <img src={logo.img} className='w-fit h-fit' alt={logo.alt} />
                  </div>
                </div>
              </div>
            ))}
          </LogosSlider>
        </div>
      </div>
    )
  }
