import IconFA from "../../assets/img/logo-bgl.png";

const features = [
	{
		id: 1,
		name: "Justicia",
	},
	{
		id: 2,
		name: "Respeto",
	},
	{
		id: 3,
		name: "Honestidad",
	},
	{
		id: 4,
		name: "Tolerancia",
	},
	{
		id: 5,
		name: "Fraternidad",
	},
	{
		id: 6,
		name: "Responsabilidad",
	},
	{
		id: 7,
		name: "Solidaridad",
	},
	{
		id: 8,
		name: "Amor",
	},
	{
		id: 9,
		name: "Integridad",
	},
];

export default function Features() {
	return (
		<div className="bg-white">
			<div className="container mx-auto py-16 px-4 sm:px-6 lg:grid lg:grid-cols-3 lg:gap-x-8 lg:py-24 lg:px-8">
				<div>
					<h2 className="text-lg font-semibold text-yellow-button">
						Nuestros valores
					</h2>
					<p className="mt-2 text-3xl font-bold tracking-tight text-gray-900">
						Inclusión con amor
					</p>
					<p className="mt-4 text-lg text-gray-500">
						En la Fundación Arduvi creemos en el amor, la inclusión, el respeto, la responsabilidad, la solidaridad y la transparencia.
					</p>
				</div>
				<div className="mt-12 lg:col-span-2 lg:mt-0">
					<dl className="space-y-10 sm:grid sm:grid-flow-col sm:grid-cols-3 sm:grid-rows-3 sm:gap-x-6 sm:gap-y-10 sm:space-y-0 lg:gap-x-8">
						{features.map((feature) => (
							<div key={feature.id} className="relative">
								<div className="flex items-center relative">
									<div className="bg-blue-button rounded-full shadow-2xl p-2 absolute left-0">
										<img src={IconFA} className="w-10" alt="Logo de Fundación Arduvi" />
									</div>
									<div className="bg-blue-button w-full rounded-full py-2 px-8">
										<p className="ml-9 text-lg font-medium leading-6 text-white">
											{feature.name}
										</p>
									</div>
								</div>
							</div>
						))}
					</dl>
				</div>
			</div>
		</div>
	);
}
