import { Link } from "react-router-dom";

import moment from "moment";
import "moment/locale/es";
function BlogCardHorizontal({ data, index }) {
	return (
		<article className="relative group">
			<div className="-inset-y-2.5 -inset-x-4 md:-inset-y-4 md:-inset-x-6 sm:rounded-2xl group-hover:bg-slate-100 dark:group-hover:bg-slate-800/50"></div>
			<svg
				viewBox="0 0 9 9"
				className="hidden absolute right-full mr-6 top-2 text-slate-200 dark:text-slate-600 md:mr-12 w-[calc(0.5rem+1px)] h-[calc(0.5rem+1px)] overflow-visible sm:block"
			>
				<circle
					cx="4.5"
					cy="4.5"
					r="4.5"
					stroke="currentColor"
					className="fill-blue-button dark:fill-slate-900"
					strokeWidth="2"
				></circle>
			</svg>
			<div className="relative">
				<h2 className="text-base font-semibold tracking-tight text-blue-button dark:text-slate-200 pt-8 lg:pt-0">
					{data.title}
				</h2>
				<div className="my-2 prose prose-slate prose-a:relative prose-a:z-10 dark:prose-dark">
					<p className="line-clamp-3">{data.description}</p>
				</div>
				<dl className="absolute left-0 top-0 lg:left-auto lg:right-full lg:mr-[calc(6.5rem+1px)]">
					<dt className="sr-only">Fecha</dt>
					<dd className="whitespace-nowrap text-sm leading-6 text-slate-500 dark:text-slate-400 flex items-center lg:block lg:text-right gap-2">
						<div className="capitalize order-2 lg:order-1">
							{moment(data.published).format("MMM D, YYYY")}
						</div>
					</dd>
				</dl>
			</div>
			<div className="flex gap-2 my-2">
				{data.categories.map((category, index) => (
					<div key={index}>
						<Link
							to={`/category/${category.slug}`}
							className="rounded-md bg-yellow-button/30 text-xs px-3 py-1.5 font-medium text-slate-500 hover:bg-red-button/60"
						>
							{category.name}
						</Link>
					</div>
				))}
				<div className="">
					<span className="text-slate-500 text-sm">
						{data.time_read} min de lectura
					</span>
				</div>
			</div>

			<Link
				className="flex items-center text-sm text-blue-button font-medium"
				to={`/blog/${data.slug}`}
			>
				<span className="-inset-y-2.5 -inset-x-4 md:-inset-y-4 md:-inset-x-6 sm:rounded-2xl"></span>
				<span className="relative">Leer artículo</span>
				<svg
					className="relative mt-px overflow-visible ml-2.5 text-blue-button dark:text-sky-700"
					width="3"
					height="6"
					viewBox="0 0 3 6"
					fill="none"
					stroke="currentColor"
					strokeWidth="2"
					strokeLinecap="round"
					strokeLinejoin="round"
				>
					<path d="M0 0L3 3L0 6"></path>
				</svg>
			</Link>
		</article>
	);
}
export default BlogCardHorizontal;
