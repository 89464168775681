import { useEffect } from "react";
import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";

function Terms() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout>
			<header className="flex justify-center">
				<Navbar />
			</header>
			<div data-scroll-section className="pt-28">
				<div className="container mx-auto max-w-4xl">
					<h1 className="font-bold text-xl mb-4">
						Aviso Legal y Condiciones de Uso
					</h1>
					<ol className="list-decimal list-inside dark:text-gray-400">
						<li className="mb-6">
							<span className="font-bold text-lg">Términos Generales</span>
							<p>
								Bienvenido al sitio web de la <strong>FUNDACIÓN ARDUVI</strong>. El acceso y uso de este sitio están sujetos a los siguientes términos y condiciones. Al acceder y utilizar nuestro sitio, aceptas cumplir con estos términos y condiciones en su totalidad.
							</p>
						</li>
						<li className="mb-6">
							<span className="font-bold text-lg">Propiedad Intelectual</span>
							<p>
								El contenido, diseño y logotipos presentes en este sitio web son propiedad exclusiva de <strong>FUNDACIÓN ARDUVI</strong> y están protegidos por leyes de propiedad intelectual. Queda prohibida la reproducción, distribución o modificación del contenido sin el consentimiento expreso de <strong>FUNDACIÓN ARDUVI</strong>.
							</p>
						</li>
						<li className="mb-6">
							<span className="font-bold text-lg">Enlaces a Terceros</span>
							<p>
								Nuestro sitio web puede contener enlaces a sitios de terceros. No tenemos control sobre el contenido o las prácticas de privacidad de estos sitios y no asumimos responsabilidad por ellos. La inclusión de enlaces no implica necesariamente un respaldo por parte de <strong>FUNDACIÓN ARDUVI</strong>.
							</p>
						</li>
						<li className="mb-6">
							<span className="font-bold text-lg">Uso Adecuado</span>
							<p>
								El sitio web debe ser utilizado de manera lícita y de acuerdo con estos términos. Queda prohibido el uso indebido, la interferencia con la funcionalidad del sitio o cualquier actividad que pueda dañar, sobrecargar o perjudicar a <strong>FUNDACIÓN ARDUVI</strong>.
							</p>
						</li>
						<li className="mb-6">
							<span className="font-bold text-lg">Exclusión de Responsabilidad</span>
							<p>
								El contenido del sitio se proporciona con la mayor noción de veracidad posible y sin garantías de ningún tipo. <strong>FUNDACIÓN ARDUVI</strong> no asume responsabilidad por cualquier pérdida o daño resultante del uso o la imposibilidad de uso del sitio.
							</p>
							<p>
								Con los límites establecidos en la normatividad actual vigente, la <strong>FUNDACIÓN ARDUVI</strong> no asume ninguna responsabilidad derivada de posibles errores en actualización y precisión de los datos o informaciones que se contienen en sus sitios web.
							</p>
							<p>
								La <strong>FUNDACIÓN ARDUVI</strong>, no asume responsabilidad alguna derivada del uso incorrecto, inapropiado o ilícito de la información aparecida en sus sitios web, o por cualquier pérdida o daño resultante del uso o la imposibilidad de uso del sitio.
							</p>
							<p>
								Los contenidos e información no vinculan a la <strong>FUNDACIÓN ARDUVI</strong> ni constituyen opiniones, consejos legales, posiciones o creencias religiosas y/o políticas de ningún tipo, pues se trata del sitio web oficial de la fundación enfocado en brindar información sobre el objeto y acciones propias de la <strong>FUNDACIÓN ARDUVI</strong>.
							</p>
						</li>
						<li className="mb-6">
							<span className="font-bold text-lg">Cambios en los Términos</span>
							<p>
								Nos reservamos el derecho de actualizar estos términos en cualquier momento. Los cambios entrarán en vigor inmediatamente después de su publicación en el sitio web.
							</p>
							<p>
								Se recomienda revisar regularmente los términos para estar al tanto de las actualizaciones.
							</p>
						</li>
						<li className="mb-6">
							<span className="font-bold text-lg">Contacto</span>
							<p>
								Si tienes preguntas o inquietudes sobre estos términos y condiciones, puedes ponerte en contacto con nosotros a través de <span className="text-blue-500 underline">info@fundacionarduvi.org</span>.
							</p>
						</li>
					</ol>
				</div>
			</div>
			<Footer />
		</Layout>
	);
}
export default Terms;
