import SmallSetPagination from "components/pagination/SmallSetPagination";
import BlogCardHorizontal from "./BlogCardHorizontal";

function BlogList({ posts, get_blog_list_page, count }) {
	console.log(get_blog_list_page);
	return (
		<div className="overflow-hidden px-8 bg-white">
			<div className="relative pt-6 sm:pb-12 sm:ml-[calc(2rem+1px)] md:ml-[calc(3.5rem+1px)] lg:ml-[max(calc(14.5rem+1px),calc(100%-48rem))]">
				<div className="hidden absolute top-3 bottom-0 right-full mr-7 md:mr-[3.25rem] w-px bg-slate-200 dark:bg-slate-800 sm:block"></div>
				<div className="space-y-16">
          {posts && posts.map((post, index) => (
						<BlogCardHorizontal data={post} key={index} index={index} />
					))}
				</div>
			</div>
			<SmallSetPagination
				list_page={get_blog_list_page}
				list={posts}
				count={count}
			/>
		</div>
	);
}
export default BlogList;
