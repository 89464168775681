// import BlogList from "components/home/BlogList";
import CTA from "components/home/CTA";
import Features from "components/home/Features";
import Values from "components/home/Values";
import Header from "components/home/Header";
import Incentives from "components/home/Incentives";
import LogoCloud from "components/home/LogoCloud";
import UseCases from "components/home/UseCases";
import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import { useEffect } from "react";

function Home() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout>
			<header className="flex justify-center">
				<Navbar />
			</header>
			<div data-scroll-section className="pt-28">
				<Header />
				<Incentives />
				<UseCases />
				<Features />
				<Values />
				<CTA />
				<LogoCloud />
				{/* <BlogList /> */}
			</div>
			<Footer />
		</Layout>
	);
}
export default Home;
