import curveTop from '../../assets/img/curve-top.svg';

import item_1 from "../../assets/img/item-1.png"
import item_2 from "../../assets/img/item-2.png"
import item_3 from "../../assets/img/item-3.png"
import item_4 from "../../assets/img/item-4.png"
import item_5 from "../../assets/img/item-5.png"
const incentives = [
    {
			id: 1,
      name: '',
      imageSrc: item_1,
      description: "Intervención y atención psicosocial y la inserción socio laboral y comunitaria a cada uno de nuestros participantes y núcleos familiares.",
    },
    {
			id: 2,
      name: '',
      imageSrc: item_2,
      description: "La gestión del conocimiento y la innovación social.",
    },
    {
			id: 3,
      name: '',
      imageSrc: item_3,
      description:"Liderazgo transformacional como modelo de formación interna y de réplica para organizaciones que requieran del proceso.",
    },
    {
			id: 4,
      name: '',
      imageSrc: item_4,
      description:"Sello dorado de Inclusión Social y Equidad ARDUVI – “Inclusión con Amor”.",
    },
		{
			id: 5,
      name: '',
      imageSrc: item_5,
      description:"La movilización y gestión de diferentes tipos de recursos que permitan ampliar el impacto de nuestro objeto social.",
    },
  ]

  export default function Incentives() {
    return (
      <div className="bg-section-pattern bg-fixed bg-cover relative">
        <img src={curveTop} className="absolute -top-[1px] h-20 w-full drop-shadow-[0_50px_25px_rgba(0,0,0,0.45)]" alt="" />
        <div className="container mx-auto py-32 sm:px-2 sm:py-48 lg:px-4">
          <div className="mx-auto max-w-2xl px-4 lg:max-w-none">
            <div className="max-w-3xl">
              <h2 className="text-4xl font-bold tracking-tight text-yellow-button">
                Nuestras líneas de acción
              </h2>
              <p className="mt-4 text-gray-200">
                Tenemos como objeto principal marcar la diferencia en la inclusión laboral y social de la población migrante en condición de vulnerabilidad, como también con condición de discapacidad cognitiva, física, auditiva y trastornos generalizados del desarrollo.
              </p>
            </div>
            <div className="mt-16 grid grid-cols-1 gap-y-10 gap-x-8 lg:grid-cols-5">
              {incentives.map((incentive) => (
                <div key={incentive.id} className="sm:flex lg:block backdrop-blur-md bg-white/20 p-4 rounded-xl">
                  <div className="flex justify-center">
                    <img className="h-28 w-28" src={incentive.imageSrc} alt="" />
                  </div>
                  <div className="mt-4 sm:mt-0 sm:ml-6 lg:mt-6 lg:ml-0">
                    <h3 className="text-sm font-medium text-gray-200">{incentive.name}</h3>
                    <p className="mt-2 text-sm text-gray-300">{incentive.description}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
        <img src={curveTop} className="absolute -bottom-[1px] h-20 w-full -scale-100 drop-shadow-[0_50px_25px_rgba(0,0,0,0.45)]" alt="" />
      </div>
    )
  }
