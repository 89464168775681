import { connect } from "react-redux";
import Layout from "hocs/layouts/Layout";
import Navbar from "components/navigation/Navbar";
import Footer from "components/navigation/Footer";
import { Link, useParams } from "react-router-dom";
import { useEffect } from "react";
import { get_blog } from "redux/actions/blog/blog";
import moment from "moment";
import "moment/locale/es";
import DOMPurify from "dompurify";


function transformOembedToIframe(content) {
  // Usar una expresión regular para encontrar todos los oembed
  const oembedRegex = /<oembed url="([^"]+)"><\/oembed>/g;
  let transformedContent = content;

  let match;
  while ((match = oembedRegex.exec(content)) !== null) {
    // Extraer la URL y convertirla a una URL de incrustación
    const originalURL = new URL(decodeURIComponent(match[1]));
    const videoId = originalURL.searchParams.get("v");
    if (videoId) {
      const embedUrl = `https://www.youtube.com/embed/${videoId}`;
      const iframe = `<iframe width="560" height="315" src="${embedUrl}" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`;
			// Reemplazar la etiqueta oembed con la etiqueta iframe
      transformedContent = transformedContent.replace(match[0], iframe);
    }
  }

  return transformedContent;
}


function PostDetail({ get_blog, post }) {
	const params = useParams();
	const slug = params.slug;

	useEffect(() => {
		window.scrollTo(0, 0);
		get_blog(slug);
	}, []);

	const transformedContent = transformOembedToIframe(post&&post.content);
	console.log(transformedContent);
  const sanitizedContent = DOMPurify.sanitize(transformedContent, {
		ADD_TAGS: ["iframe"],
		ADD_ATTR: ["allow", "allowfullscreen", "frameborder", "src"],
	});

	DOMPurify.addHook("afterSanitizeAttributes", function (node) {
		if ("target" in node) {
			node.setAttribute("target", "_blank");
			node.setAttribute("rel", "noopener noreferrer");
		}
	});

	return (
		<Layout>
			<header className="flex justify-center">
				<Navbar />
			</header>

			{post && post.slug === slug ? (
				<div className="pt-24 px-4">
					<div className="bg-gray-100/50 max-w-4xl px-4 mx-auto rounded-2xl">
						<div className="h-72 md:h-96">
							<img
								className="h-full w-full object-cover"
								src={post.thumbnail}
								alt="Banner principal del blog"
							/>
							<div
								className="bg-gray-200 mix-blend-multiply"
								aria-hidden="true"
							/>
						</div>
					</div>
					<div className="mx-auto max-w-4xl px-4 py-8">
						<h1 className="text-4xl font-bold tracking-tight text-black sm:text-5xl lg:text-6xl">
							{post.title}
						</h1>
						<div className="min-w-0 flex-1 p-4">
							<div className="">
								{post.categories.map((category, index) => (
									<span
										key={index}
										className="hover:text-red-500 mx-1 font-medium text-gray-800 text-sm"
									>
										<Link to={`/category/${category.slug}`}>
											{category.name}
										</Link>
									</span>
								))}
								<span className="text-gray-300">&middot;</span>
								<span className="mt-2 ml-2 mr-1 font-medium text-gray-800 text-sm capitalize">
									{moment(post.published).format("MMM D, YYYY")}
								</span>{" "}
								<span className="text-gray-300">&middot;</span>
								<span className="mt-2 mx-2 font-medium text-gray-800 text-sm">
									{post.time_read} min de lectura
								</span>
								<p className="mt-4 text-lg font-regular text-gray-800 leading-8">
									{post.description}
								</p>
							</div>
						</div>
					</div>

					<div className="relative overflow-hidden bg-white py-4">
						<div className="relative px-4 sm:px-6 lg:px-8">
							<div className="prose prose-lg max-w-4xl prose-indigo mx-auto text-gray-500">
								<div
									dangerouslySetInnerHTML={{
										__html: sanitizedContent,
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			) : (
				<>Loading</>
			)}
			<Footer />
		</Layout>
	);
}

const mapStateToProps = (state) => ({
	post: state.blog.post,
});

export default connect(mapStateToProps, {
	get_blog,
})(PostDetail);
