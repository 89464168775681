import Modal from "../modals/modal"
import DireccionGeneralImg from "../../assets/img/icons/direccion-general.png"
import AreaProgramasImg from "../../assets/img/icons/proyectos.png"
import AreaIntervencionImg from "../../assets/img/icons/intervencion-psicosocial.png"
import AreaJuridicaImg from "../../assets/img/icons/juridico.png"
import AreaTalentoImg from "../../assets/img/icons/rrhh.png"
import AreaCalidadImg from "../../assets/img/icons/calidad.png"
import AreaComunicacionImg from "../../assets/img/icons/comunicaciones.png"
import AreaInsercionLaboralImg from "../../assets/img/icons/insercion-laboral.png"


const details = [
  {
    id: 0,
    button: "Dirección General",
    title: "Dirección General",
    content: <>
			<img src={DireccionGeneralImg} alt="Dirección General" className="w-32 h-32 mb-6 mx-auto" />
			<p>La dirección general de la Fundación ARDUVI es la encargada de coordinar y supervisar desde la corresponsabilidad todas las actividades y operaciones que se ejecutan por las direcciones y áreas de la fundación.</p>
			<ul className="list-disc">
				<li className="ml-8">Coordina el cumplimiento de la visión, misión y plan estratégico de la fundación desde sus distintas líneas de intervención para el cumplimiento de sus objetivos desde la eficiencia y eficacia.</li>
				<li className="ml-8">Supervisa la implementación del Modelo ARDUVI "Inclusión con Amor" en su desarrollo a través de los distintos programas y proyectos. </li>
				<li className="ml-8">Gestiona los recursos financieros y humanos necesarios para garantizar la operación, sostenibilidad y el aumento del impacto en la cobertura de la fundación. </li>
				<li className="ml-8">Representa a la fundación legal y jurídicamente ante las instancias correspondientes y los stakeholders externos como donantes, gobiernos y comunidades.</li>
				<li className="ml-8">Asegura el cumplimiento de las normativas legales y éticas nacionales vigente, así como de los principios, valores y líneas estratégicas de la organización.</li>
				<li className="ml-8">Reporta a la junta directiva sobre el desempeño, logros de la fundación y cumplimiento del plan estratégico de la organización.</li>
			</ul>
		</>,
    class: "pt-3",
    children: [
      {
        id: 1,
        button: "Junta Directiva",
        title: "Junta Directiva",
        content: <>
					<p>
						La junta directiva de la Fundación ARDUVI es el órgano de dirección y gobierno de la organización, encargado de establecer las políticas, estrategias y directrices para el cumplimiento de la misión y visión de la fundación. Está conformada por un grupo de personas con experiencia y conocimientos en distintas áreas, que aportan su tiempo, talento y recursos para el desarrollo de la fundación. La junta directiva se encarga de velar por el cumplimiento de los objetivos y metas de la fundación, así como de la sostenibilidad y el impacto de la organización en la población de interés.
					</p>
				</>,
        class: "border-4 right-8 border-white bottom-0",
      },
      {
        id: 2,
        button: "Área de programas y proyectos",
        title: "Área de programas y proyectos",
        content: <>
					<img src={AreaProgramasImg} alt="Área de programas y proyectos" className="w-32 h-32 mb-6 mx-auto" />
					<p>
						La dirección de programas, proyectos y planeación estratégica de la Fundación ARDUVI es la dependencia encargada de la planeación, estructuración, ejecución y seguimiento, desde el modelo de atención e intervención psicosocial e inserción socio laboral de los proyectos sociales que implementa la organización en sus distintos frentes y fases.
					</p>
					<p>
						También, es la encargada de desarrollar y garantizar la implementación de las técnicas y herramientas necesarias para la ejecución de los proyectos, desde un enfoque de acción participativa, satisfacción de los participantes y contrapartes de la fundación, basados en la efectividad, eficiencia y eficacia logrando cumplir con los resultados previstos, dentro de unas condiciones dadas de alcance, tiempo y presupuesto asignado.
					</p>
				</>,
        class: "-top-40 right-8",
      },
      {
        id: 3,
        button: "Área de intervención y atención psicosocial",
        title: "Área de intervención y atención psicosocial",
        content: <>
					<img src={AreaIntervencionImg} alt="Área de intervención y atención psicosocial" className="w-32 h-32 mb-6 mx-auto" />
					<p>
						El área psicosocial y de intervención de psicología clínica de la Fundación ARDUVI es la encargada de realizar acciones de intervención y acompañamiento a la población en condición de discapacidad, migrantes y víctimas de violencias. Dichas acciones se desarrollan desde el modelo propio de la fundación para la atención psicosocial e inserción sociolaboral basados en el enfoque ecosistémico y en comunidad, desde la gestoría de casos para la identificación, evaluación, coordinación de recursos y psicología clínica, activando servicios necesarios para satisfacer las necesidades individuales y familiares que afectan al sistema en su conjunto.
					</p>
				</>,
        class: "right-56 top-0",
      },
      {
        id: 4,
        button: "Área Jurídica, de Talento Humano y Gestión de la calidad",
        title: "Área Jurídica, de Talento Humano y Gestión de la calidad",
        content: <>
					<p>
						El área jurídica, de talentos humanos y gestión de calidad de la Fundación ARDUVI, desarrolla tres grandes procesos orientados a garantizar el cumplimiento del propósito de la organización:
					</p>
					<div className="flex flex-col items-center md:flex-row md:items-start gap-3 my-6">
						<img src={AreaJuridicaImg} alt="Área Jurídica" className="w-20 h-20" />
						<p>
							<b>Área Jurídica:</b> Se encarga de elaborar y proyectar todos los instrumentos jurídicos que permitan dar la viabilidad y el soporte legal de la Fundación para la firma de acuerdos, convenios, memorandos de entendimiento y contratos requeridos. También, propende por brindar asesoría jurídica adecuada en el cumplimiento de la misionalidad de la fundación.
						</p>
					</div>
					<div className="flex flex-col items-center md:flex-row md:items-start gap-3 mb-6">
						<img src={AreaTalentoImg} alt="Área de Talentos Humanos" className="w-20 h-20" />
						<p>
							<b>Área de Talentos Humanos:</b> Es el área encargada de la gestión del talento de la fundación a través del análisis de los puestos de trabajo, el reclutamiento/contratación y la selección de personal, la formación y el desarrollo humano desde el liderazgo transformacional, la compensación y los beneficios, la gestión del rendimiento, las relaciones humanas y laborales del equipo humano de la fundación con relación al cumplimiento del objeto misional. Para la Fundación ARDUVI cuando el talento humano crece y desarrolla sus habilidades, la organización experimenta automáticamente un crecimiento y una expansión.
						</p>
					</div>
					<div className="flex flex-col items-center md:flex-row md:items-start gap-3">
						<img src={AreaCalidadImg} alt="Área de gestión de la calidad" className="w-20 h-20" />
						<p>
							<b>Área de gestión de la calidad:</b> Es el área encargada de asegurar que los bienes, productos o servicios que ofrece la fundación a sus participantes, familias y contrapartes cumplan con los estándares de calidad establecidos. Esto implica la implementación de procesos, controles y sistemas para monitorear y mejorar la calidad de los bienes, productos o servicios garantizando su satisfacción. El área de gestión de calidad tiene a su cargo la elaboración de políticas de calidad, la realización de auditorías internas, la gestión de reclamos de participantes, familias y contrapartes y la capacitación del personal en temas relacionados con la calidad. Su objetivo principal es asegurar que la organización cumpla con los requisitos de calidad y continúe mejorando sus procesos y productos.
						</p>
					</div>
				</>,
        class: "left-36 -bottom-32",
      },
      {
        id: 5,
        button: "Área de comunicación y marketing digital",
        title: "Área de comunicación y marketing digital",
        content: <>
					<img src={AreaComunicacionImg} alt="Área de comunicación y marketing digital" className="w-32 h-32 mb-6 mx-auto" />
					<p>
						El área de comunicaciones y marketing digital de la Fundación se ocupa de elaborar y difundir información relevante sobre las actividades, objetivos y logros de la organización.  Esto incluye la creación de estrategias de comunicación, abordando las relaciones públicas, el manejo de redes sociales, la elaboración de material promocional y la interacción con medios de comunicación para fortalecer su imagen y visibilidad. Al mismo tiempo, comprende la coordinación de eventos y campañas para aumentar el conocimiento público y el apoyo a la misión institucional.
					</p>
				</>,
        class: "right-36 -bottom-32",
      },
      {
        id: 6,
        button: "Área de inserción socio-laboral",
        title: "Área de inserción socio-laboral",
        content: <>
					<img src={AreaInsercionLaboralImg} alt="Área de inserción socio-laboral" className="w-32 h-32 mb-6 mx-auto" />
					<p>
						El área de inserción sociolaboral de la Fundación ARDUVI es la encargada de establecer estrategias de promoción, formación, facilitación y acompañamiento a las poblaciones en situación de discapacidad, vulnerabilidad, migrantes y víctimas de violencias como estrategia de apoyo e inserción al mundo laboral, de la generación de proyectos productivos o de integración socioeconómica.
					</p>
					<p>
						Desde esta área se promueve la inclusión social, la autonomía y el desarrollo integral de las y los participantes en colaboración con otras organizaciones y entidades del sector público, privado y la cooperación internacional a fin de garantizar el acceso a servicios y recursos que puedan beneficiar a la población de interés, desde programas de capacitación, inscripción en agencias públicas de empleo, proyectos orientados a fortalecer medios de vida y la búsqueda activa de oportunidades en el mercado laboral.
					</p>
				</>,
        class: "left-56 top-0",
      },
    ]
  },
]

function Organization() {
  return (
    <div className="bg-no-repeat bg-cover bg-fixed">
      <h2 className="text-3xl text-center font-bold tracking-tight text-yellow-button sm:text-4xl py-8">Nuestro Organigrama</h2>
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:gap-x-8 md:py-44 lg:px-8">
        <div className="flex justify-center relative">
          <div className="p-4 bg-blue-500 rounded-full text-white w-48 h-48 relative flex justify-center scale-[0.65] md:scale-100">
            <div className="absolute -z-10 bg-transparent w-96 h-96 rounded-full border-[14px] border-blue-500/50 -top-[100px]"></div>
            {details.map((detail, index) => (
              <div key={index}>
                <div className="pt-3">
                  <Modal button={detail.button} title={detail.title} content={detail.content} />
                </div>
                {detail.children.map((child) => (
                  <div className={`${child.class} p-4 bg-blue-500 rounded-full text-white w-32 h-32 absolute text-center flex justify-center items-center`} key={child.title}>
                    <Modal button={child.button} title={child.title} content={child.content} />
                  </div>
                ))}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Organization;
