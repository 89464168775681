import Img1 from "../../assets/img/icons/amor.png";
import Img2 from "../../assets/img/icons/asertivo.png";
import Img3 from "../../assets/img/icons/carpeta-confidencial.png";
import Img4 from "../../assets/img/icons/justa.png";
import Img5 from "../../assets/img/icons/la-lealtad-del-cliente.png";
import Img6 from "../../assets/img/icons/lealtad.png";
import Img7 from "../../assets/img/icons/observacion.png";
import Img8 from "../../assets/img/icons/promover.png";
import Img9 from "../../assets/img/icons/servicios-sociales.png";
import Img10 from "../../assets/img/icons/pareja.png";

const features = [
  { 
    id: 1,
    image: Img4,
    description: 'La neutralidad, imparcialidad e independencia como derroteros de nuestro actuar en comunidad y al interior de la organización.'
  },
  { 
    id: 2,
    image: Img8,
    description: 'Orientados a promover la equidad e Igualdad rompiendo techos de cristal y cambiando imaginarios.'
  },
  { 
    id: 3,
    image: Img2,
    description: 'Promovemos la comunicación asertiva.'
  },
  { 
    id: 4,
    image: Img3,
    description: 'Confidencialidad: Atesorar con respeto los procesos de las y los participantes.' 
},
  { 
    id: 5,
    image: Img9,
    description: 'Humanización: Trato amable y familiar con los y las participantes, colaboradores y contrapartes.' 
},
  { 
    id: 6,
    image: Img1,
    description: 'Inclusión Social como búsqueda activa para nuestros participantes y la sociedad.' 
},
  { 
    id: 7,
    image: Img5,
    description: 'Lealtad con nuestros participantes y contrapartes.' 
},
  { 
    id: 8,
    image: Img6,
    description: 'Integridad en la prestación y garantía de nuestros bienes y servicios.' 
},
  { 
    id: 9,
    image: Img10,
    description: 'Responsabilidad social: Propendemos por el bienestar de la población y las comunidades.' 
},
  { 
    id: 10,
    image: Img7,
    description: 'Innovación social como derrotero en el desarrollo y ejecución de programas y proyectos.' 
},
]

export default function Features() {
  return (
    <div className="bg-section-pattern bg-no-repeat bg-fixed bg-cover">
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:gap-x-8 lg:py-24 lg:px-8">
        <div>
          <h2 className="mt-2 text-3xl font-bold tracking-tight text-yellow-button text-center mb-8">Nuestros principios</h2>
          <p className="mt-4 text-lg text-white text-center mb-14">
          La Fundación asume los principios que defienden las consideraciones psicosociales, de inserción laboral y familiares de la población.
          </p>
        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-x-6 gap-y-14">
            {features.map((feature) => (
              <div key={feature.description} className="relative flex flex-col items-center">
                <div className="bg-gray-100 p-3 rounded-full shadow-2xl absolute -top-10">
                  <img src={feature.image} alt="ícono descriptivo" className="w-14" />
                </div>
                <div className="bg-gray-100 rounded-xl h-full w-full">
                  <p className="text-base text-gray-600 pt-10 px-4 pb-3">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}