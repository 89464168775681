import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';

function ModalRSE({
  button,
  title,
  content,
	img,
  bg,
  text
}) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <g onClick={() => setOpenModal(true)} className='text-xs font-bold cursor-pointer'>{button}</g>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header className={`${bg} p-3`}><span className={`${text} font-bold`}>{title}</span></Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              {content}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={`${bg} p-3`}>
          <button className='py-1 px-2 rounded-lg hover:bg-gray-100 bg-white' onClick={() => setOpenModal(false)}>
            Cerrar
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalRSE;
