import { useState } from "react";
import DotLoader from "react-spinners/DotLoader";
import { NavLink } from "react-router-dom";


export default function CTA() {
	const [loading, setLoading] = useState(true);

    return (
      <div className="bg-gray-50">
        <div className="container mx-auto py-12 px-4 sm:px-6 lg:flex lg:items-center lg:justify-between lg:py-16 lg:px-8">
          <h2 className="text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            <span className="block">¿Te gustaría colaborar?</span>
            <span className="block text-yellow-button">Realiza una donación.</span>
          </h2>
          <div className="mt-8 flex lg:mt-0 lg:flex-shrink-0">
            <div className="inline-flex rounded-md shadow">
              <NavLink to="/contacto" className="inline-flex ml-6 items-center rounded-md border border-transparent bg-yellow-button px-6 py-1.5 text-base font-medium text-white shadow-sm hover:bg-blue-button transition duration-300 ease-in-out focus:outline-none focus:ring-2 focus:ring-yellow-500 focus:ring-offset-2">
                Donaciones
                <DotLoader
                  className="ml-3 -mr-1 h-5 w-5"
                  loading={loading}
                  size={20}
                  color="#f2f2f2"
                />
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    )
  }
