import AboutImg from '../../assets/img/about-img.jpg'

const stats = [
    { label: 'Fundada', value: '2021' },
    { label: 'Unidades productivas', value: '2' },
    { label: 'Participantes', value: '100+' },
    { label: 'Procesos sociales en paralelo', value: '7' },
  ]

  export default function TestStats() {
    return (
      <div className="relative bg-white py-16 sm:py-24">
        <div className="container mx-auto lg:grid lg:grid-cols-2 lg:items-start lg:gap-24 lg:px-8">
          <div className="relative sm:py-16 lg:py-0">
            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
              <div className="absolute inset-y-0 right-1/2 w-full rounded-r-3xl bg-gray-50 lg:right-72" />
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
              {/* Testimonial card*/}
              <div className="relative overflow-hidden rounded-2xl py-56 shadow-xl">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src={AboutImg}
                  alt="Personas trabajando"
                />
              </div>
            </div>
          </div>

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2 className="text-3xl font-bold tracking-tight text-yellow-button sm:text-4xl">
                ¿Quiénes somos?
              </h2>
              <div className="mt-6 space-y-6 text-gray-500">
                <p className="text-lg">
								La Fundación ARDUVI “Inclusión con Amor”, es una entidad sin ánimo de lucro fundada  en Cúcuta, territorio fronterizo con Venezuela en el 2021, que tiene por objetivo contribuir a la integración social de la población con capacidades especiales (PCD), vulnerables, migrantes, víctimas del conflicto y otras violencias, por medio del restablecimiento de sus derechos humanos a través del acompañamiento, intervención psicosocial personal y familiar, inclusión formativa, comunitaria y la promoción de capacidades individuales y colectivas, orientadas a sus proyectos de vida, favoreciendo su inclusión socio-laboral de forma holística y generando oportunidades a través de alianzas de valor agregado.
                </p>
              </div>
            </div>

            {/* Stats section */}
            <div className="mt-10">
              <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                {stats.map((stat) => (
                  <div key={stat.label} className="border-t-2 border-gray-100 pt-6">
                    <dt className="text-base font-medium text-gray-500">{stat.label}</dt>
                    <dd className="text-3xl font-bold tracking-tight text-gray-900">{stat.value}</dd>
                  </div>
                ))}
              </dl>
            </div>
          </div>
        </div>
      </div>
    )
  }
