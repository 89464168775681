import { useEffect } from "react";
import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";

function Privacy() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout>
			<header className="flex justify-center">
				<Navbar />
			</header>
			<div data-scroll-section className="pt-28">
				<div className="container mx-auto max-w-5xl">
					<h1 className="font-bold text-xl">
						Política de Privacidad y Tratamiento de Datos
					</h1>
          <ol className="space-y-4 text-gray-700 list-decimal list-inside dark:text-gray-400">
            <li>
              <span className="font-bold text-lg">Introducción</span>
              <p>La <strong>Fundación Arduvi</strong>, Entidad Sin Ánimo de Lucro nacida el 10 de octubre de 2021, se compromete a proteger la privacidad de los usuarios de su sitio web. Esta política de protección de datos regula la recolección, almacenamiento, uso, circulación y supresión de los datos personales que realiza la <strong>Fundación Arduvi</strong>, identificada con Nit. 901.532.229-0 en calidad de responsable, de acuerdo con las disposiciones contenidas en la Ley estatutaria 1581 de 2012, la Ley 1266 de 2008, el Decreto 1377 de 2013, el Decreto 1074 de 2015, y las demás normas que los complementen o modifiquen.</p>
            </li>
            <li>
              <span className="font-bold text-lg">Responsable de tratamiento de datos</span>
              <p>La <strong>Fundación Arduvi</strong> se encuentra ubicada en la Avenida 9E No. 11 - 51 - La Riviera en la ciudad de Cúcuta, Colombia, teléfono +57 (320)240-5434, dirección de correo electrónico <span className="text-blue-500 underline">info@fundacionarduvi.org</span>.</p>
            </li>
            <li>
              <span className="font-bold text-lg">Marco Legal</span>
              <p>La Política sigue los lineamientos en la Constitución Política, artículo 15, Ley Estatutaria 1581 de 2012, reglamentada por el Decreto 1377 de 2013, Decreto 886 de 2014, la Ley 1266 de 2008, las sentencias C-1011 de 2008 y la C-748 de 2011 y demás normas y decretos concordantes.</p>
            </li>
            <li>
              <span className="font-bold text-lg">Definiciones</span>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Acceso autorizado:</span> Autorización concedida a un usuario para el uso de determinados recursos. En dispositivos automatizados es el resultado de una autentificación correcta, generalmente mediante el ingreso de usuario y contraseña.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Aliados:</span>Personas, ya sean naturales o jurídicas, que celebren con la Fundación contratos o convenios que impliquen Transmisiones y/o Transferencias de Datos Personales.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Autorización:</span> consentimiento previo, expreso e informado del Titular para llevar a cabo el Tratamiento de Datos Personales.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Autenticación:</span>Procedimiento de verificación de la identidad de un usuario.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Aviso de privacidad:</span> Comunicación verbal o escrita generada por el responsable, dirigida al Titular para el tratamiento de sus datos personales, mediante la cual se le informa acerca de la existencia de las políticas de tratamiento de información que le serán aplicables, la forma de acceder a las mismas y las finalidades del tratamiento que se pretende dar a los datos personales.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Base de datos:</span> conjunto organizado de Datos Personales que sea objeto de Tratamiento.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Copia de respaldo:</span> Copia de los datos de una base de datos en un soporte que permita su recuperación.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Dato personal:</span> cualquier información vinculada o que pueda asociarse a una o varias personas naturales determinadas o determinables.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Dato personal privado:</span> Aquel que por su naturaleza íntima o reservada solo es relevante para el titular.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Dato público:</span> es el Dato calificado como tal según los mandatos de la ley o de la Constitución Política y todos aquellos que no sean semiprivados o privados. Son públicos, entre otros, los Datos contenidos en documentos públicos, sentencias judiciales debidamente ejecutoriadas que no estén sometidos a reserva y los relativos al estado civil de las personas.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Dato sensible:</span> son aquellos que afectan la intimidad del Titular o cuyo uso indebido puede generar discriminación.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Dato semiprivado:</span> es semiprivado el Dato que no tiene naturaleza íntima, reservada, ni pública y cuyo conocimiento o divulgación puede interesar no sólo a su Titular sino a cierto sector o grupo de personas o a la sociedad en general, como el dato financiero y crediticio de actividad comercial o de servicios.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Encargado del tratamiento:</span> persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, realice el Tratamiento de Datos Personales por cuenta del Responsable del Tratamiento.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Información financiera, comercial, crediticia y de servicios:</span> aquella referida al nacimiento, ejecución y extinción de obligaciones dinerarias, independientemente de la naturaleza del contrato que les dé origen protegida por la Ley 1266 de 2008.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Oficial de Protección de Datos:</span> persona natural, empleado de la Fundación, que es el encargado de velar por el cumplimiento de la Política.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Responsable del Tratamiento:</span> persona natural o jurídica, pública o privada, que por sí misma o en asocio con otros, decida sobre la Base de Datos y/o el Tratamiento de los Datos.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Titular:</span> persona natural cuyos Datos Personales sean objeto de Tratamiento.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Transferencia:</span> La Transferencia de datos tiene lugar cuando el Responsable y/o Encargado del Tratamiento de Datos Personales, ubicado en Colombia, envía la información o los Datos Personales a un receptor, que a su vez es Responsable del Tratamiento y se encuentra dentro o fuera del país.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Transmisión:</span> Tratamiento de Datos Personales que implica la comunicación de los mismos dentro o fuera del territorio de la República de Colombia cuando tenga por objeto la realización de un Tratamiento por el Encargado por cuenta del Responsable.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Tratamiento:</span> Cualquier operación o conjunto de operaciones sobre Datos Personales, tales como la recolección, almacenamiento, uso, circulación o supresión.</p>
              <p className="pl-5 mt-2 space-y-1"><span className="font-bold">Usuario:</span> Sujeto autorizado para acceder a los datos o recursos, o proceso que accede a los datos o recursos sin identificación de un sujeto.</p>
            </li>
            <li>
              <span className="font-bold text-lg">Finalidad del Tratamiento de Datos</span>
              <p>La <strong>Fundación Arduvi</strong> debe garantizar que el tratamiento de los datos Personales bajo su responsabilidad sea llevado a cabo, según el perfil, exclusivamente, con los siguientes fines:</p>
							<ol className="pl-5 mt-2 space-y-1 list-inside list-[upper-roman]">
								<li>
									<span className="font-bold">Candidatos & Empleados</span>
									<p>
										Los Datos Personales de candidatos y empleados tratados por la Fundación Arduvi incluyen, pero no se limitan a:
									</p>
									<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
										<li>Datos generales de identificación, de familiares, beneficiarios o terceros como parte de la hoja de vida y carpeta del empleado.</li>
										<li>Información de contacto.</li>
										<li>Datos sensibles tales como datos relacionados con la salud del empleado, pruebas médicas de ingreso, de accidentes laborales, de valoración de salud en general, información de incapacidades médicas, y otras relacionadas, estatura, peso, complexión, datos biométricos como huellas y fotografías.</li>
										<li>Información profesional del trabajador que incluye antecedentes académicos y profesionales, trayectoria, historia laboral de la persona, experiencia laboral, cargo, fechas de ingreso y retiro, anotaciones, llamados de atención, etc., nivel educativo, capacitación y/o historial académico de la persona etc.</li>
										<li>Datos socioeconómicos (estrato, información tributaria, actividad económica, patrimonio, datos generales relacionados con afiliación y aportes al sistema integral de seguridad social.</li>
										<li>Demás información personal que pueda ser requerida u obtenida por el área de Talento Humano para la correcta ejecución de la relación laboral.</li>
										<li>Los Datos Personales de candidatos y empleados serán tratados por la Fundación para realizar las siguientes finalidades:</li>
										<li>Revisión y confirmación de la información contenida en las hojas de vida de los candidatos.</li>
										<li>Verificación de referencias laborales, Listas restrictivas y antecedentes penales.</li>
										<li>Realizar un primer contacto con los candidatos, con el propósito de comunicar el interés de la Fundación en el perfil profesional del candidato.</li>
										<li>Suscribir el contrato laboral conforme con los parámetros establecidos en la legislación laboral colombiana.</li>
										<li>Realizar el pago de la nómina.</li>
										<li>Dar cumplimiento a las obligaciones laborales de la Fundación en su calidad de empleador, establecidas en la legislación laboral, el contrato de trabajo, el Reglamento Interno de Trabajo, tales como: afiliación al Sistema de Seguridad Social Integral y pago de aportes, afiliación a la Caja de Compensación y pago de aportes, pago a la DIAN de las sumas retenidas por disposición legal, emitir certificados de ingresos y retenciones y certificados laborales, y suministrar información a una entidad o autoridad nacional que requiera tener acceso a los Datos Personales, conforme con las normas aplicables.</li>
										<li>Contratar seguros de vida y/o gastos médicos, o para el otorgamiento de cualquier otra prestación que derive de la relación laboral con la Fundación.</li>
										<li>Notificar a familiares en caso de emergencias durante el horario de trabajo o con ocasión del desarrollo del mismo.</li>
										<li>Mantener la seguridad y salud de los trabajadores en el lugar de trabajo, de conformidad con las normas aplicables al Sistema de Gestión de Seguridad y Salud en el Trabajo (“SG-SST”) y conservar los documentos indicados en el artículo 2.2.4.6.13. del Decreto 1072 de 2015.</li>
										<li>Evaluar el desempeño laboral del trabajador.</li>
										<li>Recolectar información y evidencia con el fin de llevar a cabo procedimientos disciplinarios laborales.</li>
										<li>Almacenar los datos personales en las bases de datos de la Fundación.</li>
										<li>Enviar información sobre productos y servicios ofrecidos por la Fundación a sus trabajadores.</li>
										<li>Realizar eventos de la Fundación con sus trabajadores y las familias de éstos.</li>
										<li>Revisión y verificación de la información sobre el desarrollo académico de los Trabajadores de la FSD.</li>
										<li>Recolectar información para fines estadísticos, Históricos y de medición de cumplimento de los objetos de la unidad de Talento Humano requeridas sobre los Trabajadores.</li>
										<li>La transferencia de los Datos Personales en el marco de la definición, estructuración y ejecución de transacciones estratégicas, tales como la venta de activos en caso de que la Fundación o partes de su negocio sean vendidas, fusionadas o adquiridas por terceros.</li>
										<li>Compartir, transmitir y/o transferir información relacionada con los Datos Personales con los Clientes de la Fundación y/o terceros para participar en procesos de licitación públicos y/o privados y/o atender auditorías de cumplimiento laboral y/o fiscales y/o propias de un proyecto/servicio dentro y fuera del territorio colombiano en proyectos/servicios con alcance a nivel nacional y/o internacional.</li>
										<li>Compartir, transmitir y/o transferir información relacionada con todos los Datos Personales, incluyendo los de contenido sensible, en cumplimiento de las obligaciones legales, contractuales, pre y post contractuales y/o comerciales.</li>
										<li>Transmisión de los Datos Personales para que sean tratados por terceros, en calidad de Encargados, que se encuentren ubicados en Colombia o por fuera del país, para las finalidades anteriormente señaladas.</li>
										<li>Y en general, para el cumplimiento de obligaciones legales y en especial, de las obligaciones que le asisten a la Fundación en su condición de empleador de sus trabajadores.</li>
										<p>Los Datos Personales de los empleados serán objeto de Tratamiento, aun después de haberse finalizado el contrato con la Fundación, con el fin de mantener información histórica y/o estadística respecto del cumplimiento de ciertas obligaciones, en caso de ser aplicable, tales como:</p>
										<li>Emitir certificaciones laborales.</li>
										<li>Pago de aportes al Sistema de Seguridad Social Integral.</li>
										<li>Pago de salarios, prestaciones sociales y demás derechos legales y extralegales.</li>
										<li>Cumplimiento de normas de seguridad y salud en el trabajo.</li>
										<li>Reportes de enfermedades y accidentes de trabajo.</li>
										<li>Cualquier otro necesario para que la Fundación demuestre que cumplió con las obligaciones laborales, legales y/o contractuales a su cargo.</li>
									</ul>
								</li>
								<li>
									<span className="font-bold">Proveedores</span>
									<p>Los Datos Personales de proveedores tratados por la Fundación incluyen, pero no se limitan a:</p>
									<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
										<li>Información de contacto.</li>
										<li>Información de identificación.</li>
										<li>Aquella información de carácter sensible, entendida como información que puede afectar su intimidad o cuyo uso indebido puede generar algún tipo de discriminación.</li>
										<p>Los datos personales de proveedores serán tratados por la Fundación para realizar las siguientes finalidades:</p>
										<li>Almacenar los datos personales de los proveedores en las bases de datos de la Fundación.</li>
										<li>Realizar órdenes de compra de productos o servicios, en relación con las necesidades de la Fundación.</li>
										<li>Realizar pagos de las órdenes de compra realizadas por la Fundación a los proveedores.</li>
										<li>Solicitar cotizaciones de productos y/o servicios en cumplimiento de su proceso de compras.</li>
										<li>Generar estadísticas de compras.</li>
										<li>Verificar y confirmar la identidad y la información suministrada.</li>
										<li>Realizar las búsquedas pertinentes en listas restrictivas, vinculantes y no vinculantes de SARLAFT / SAGRILAFT, entre otras.</li>
										<li>Realizar la gestión administrativa relacionada con la negociación, formalización, ejecución de contratos de prestación de bienes y servicios, órdenes de compra y similares.</li>
										<li>Registrar como (proveedor) en los sistemas de información internos de la Fundación.</li>
										<li>Procesar pagos.</li>
										<li>Expedir o solicitar facturas, o información relacionada con éstas, certificados de retención en la fuente, paz y salvos y cualquier otro documento necesario que se deba suscribir en la relación comercial con el proveedor.</li>
										<li>Contactar por cualquier medio para efectos de la prestación de los servicios que requiera.</li>
										<li>Conocer y tratar los datos personales de los empleados y contratistas que emplee para la prestación de dichos servicios.</li>
										<li>Evaluar el cumplimiento de la prestación de los servicios contratados.</li>
										<li>Realizar análisis estadísticos y reportes de mercadeo.</li>
										<li>Transferir esta información a distintas áreas de la Fundación y a sus entidades vinculadas para evaluar el cumplimiento de la prestación de los servicios o el suministro de los bienes contratados; y la idoneidad del proveedor; o cuando ello sea necesario para el desarrollo de sus operaciones.</li>
										<li>Exigir el cumplimiento de los servicios contratados.</li>
										<li>Autorizar el ingreso de contratistas y empleados del Proveedor, a las instalaciones de la Fundación.</li>
										<li>Registrar los datos personales en los sistemas de información de la Fundación y en sus bases de datos comerciales y operativas.</li>
										<li>Establecer un canal de comunicación permanente que permita entregar información sobre promociones, actividades, eventos, noticias e información de tipo comercial y de mercadeo de los servicios prestados por la Fundación o terceros aliados.</li>
										<li>Compartir información a nivel nacional o internacional con proveedores, terceros aliados, o con quienes prestan colaboración para el adecuado funcionamiento de la Fundación.</li>
										<li>Implementar medidas de seguridad industrial adecuadas para el ingreso a las instalaciones.</li>
										<li>Gestionar la prestación médica de emergencias, de ser requerido.</li>
										<li>Captar a través de cámaras de video vigilancia imágenes y sonidos que serán almacenados por la Fundación para la seguridad de sus visitantes y colaboradores.</li>
										<li>Cualquier otra actividad de naturaleza similar a las anteriormente descritas que sean necesarias para desarrollar el objeto social de la Fundación.</li>
										<li>Transferencia de los Datos Personales de los proveedores y contratistas en el marco de la definición, estructuración y ejecución de transacciones estratégicas, tales como la venta de activos en caso de que la Fundación o partes de su negocio sean vendidas, fusionadas o adquiridas por terceros.</li>
										<li>Transmisión de los Datos Personales de los proveedores y contratistas para que sean tratados por terceros, en calidad de Encargados, que se encuentren ubicados en Colombia o por fuera del país, para las finalidades anteriormente señaladas.</li>
										<li>Las demás finalidades necesarias y que se presten en el entorno de la relación contractual a efectos de cumplir con el objeto y las obligaciones derivadas de la misma.</li>
									</ul>
								</li>
								<li>
									<span className="font-bold">Personas naturales que hacen parte de los programas desarrollados por la Fundación</span>
									<p>Los Datos Personales tratados por la Fundación incluyen, pero no se limitan a:</p>
									<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
										<li>Datos generales de identificación, de familiares, beneficiarios o terceros como parte de la ejecución del beneficio otorgado y para ejecutar los programas desarrollados por la Fundación.</li>
										<li>Información de contacto.</li>
										<li>Datos sensibles tales como datos relacionados al origen racial o étnico, orientación política, convicciones religiosas o filosóficas, organizaciones sociales, a la salud, a la vida sexual y datos biométricos tales como, pero sin limitarse a huella, imagen, voz, videos, grabaciones, entre otros.</li>
										<li>Información profesional.</li>
										<li>Datos socioeconómicos.</li>
										<li>Demás información personal que pueda ser requerida u obtenida para la ejecución del programa desarrollado por la Fundación.</li>
										<p>Los Datos Personales de las personas que hacen parte de programas desarrollados e implementados por la Fundación serán tratados para realizar las siguientes finalidades:</p>
										<li>Identificación de las personas que hacen parte de los programas desarrollados por la Fundación.</li>
										<li>Implementación de los programas desarrollados por la Fundación.</li>
										<li>Realizar informes de gestión y de resultados internos de la Fundación.</li>
										<li>Realizar estudios académicos y de investigación, con el fin de tomar decisiones internas de la Fundación y de programas desarrollados con sus aliados.</li>
										<li>Verificación de antecedentes judiciales.</li>
										<li>Realizar estudio y verificación de historial crediticio.</li>
										<li>Realizar desembolso de créditos.</li>
										<li>Realizar seguimiento del crédito a los beneficiarios.</li>
										<li>Elaboración de estadísticas que permitan gestionar nuevos productos de crédito hechos a la medida.</li>
										<li>Proporcionar resultados de las estadísticas realizadas por la Fundación a otras entidades pertenecientes al gremio al cual pertenece la Fundación.</li>
										<li>Brindar asesoría en relación con carreras técnicas.</li>
										<li>Referenciación a oportunidades laborales y de creación de negocios.</li>
										<li>Recepción de reportes de situaciones y de situaciones para ser tramitadas ante el Ministerio de Vivienda, Ciudad y Territorio.</li>
										<li>Invitación a eventos y talleres organizados por la Fundación y por sus Aliados.</li>
										<li>Las demás finalidades necesarias y que se presten en el entorno del desarrollo del programa a efectos de cumplir con el objeto y las obligaciones derivadas del mismo.</li>
									</ul>
								</li>
								<li>
									<span className="font-bold">Usuarios de la Unidad de Financiación y Desarrollo Empresarial: otorgamiento de créditos</span>
									<p>Los Datos Personales de clientes tratados por la Fundación incluyen, pero no se limitan a:</p>
									<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
										<li>Datos generales de identificación.</li>
										<li>Información de contacto.</li>
										<li>Datos socioeconómicos.</li>
										<li>Demás información personal que pueda ser requerida para la correcta ejecución de la relación contractual.</li>
										<li>Información financiera, comercial y crediticia.</li>
										<p>Los Datos Personales de los clientes de la Fundación serán tratados para realizar las siguientes finalidades:</p>
										<li>Solicitud de información personal a efectos de celebrar el contrato de crédito celebrado.</li>
										<li>Enviar información sobre los productos y servicios de la Fundación.</li>
										<li>El desarrollo de su objeto social y de la relación contractual vinculante lo que supone el ejercicio de sus derechos y deberes dentro de los que están, sin limitarse a ellos, la atención de solicitudes, la generación de estados de cuentas, la realización de actividades de cobranza propia o a través de terceros, entre otros.</li>
										<li>La administración de los créditos otorgados por la Fundación al titular o su codeudor.</li>
										<li>La estructuración de ofertas comerciales y la remisión de información comercial sobre servicios a través de los canales o medios que la Fundación establezca para tal fin.</li>
										<li>La adopción de medidas tendientes a la prevención de actividades ilícitas.</li>
										<li>Transferencia de los Datos Personales de los Clientes en el marco de la definición, estructuración y ejecución de transacciones estratégicas, tales como la venta de activos en caso de que la Fundación o partes de su negocio sean vendidas, fusionadas o adquiridas por terceros.</li>
										<li>Transmisión de los Datos Personales del Cliente para que sean tratados por terceros, en calidad de Encargados, que se encuentren ubicados en Colombia o por fuera del país, para las finalidades anteriormente señaladas.</li>
										<li>Las demás finalidades necesarias y que se presten en el entorno de la relación contractual a efectos de cumplir con el objeto y las obligaciones derivadas de la misma.</li>
										<li>Como elemento de análisis para establecer, mantener y terminar una relación contractual.</li>
										<li>Como elemento de análisis para hacer estudios de mercado o investigaciones comerciales o estadísticas.</li>
										<li>Para adelantar cualquier trámite ante una autoridad pública o una persona privada, respecto del cual dicha información resulte pertinente.</li>
										<li>Suministrar a sus clientes y aliados comerciales en Colombia los análisis, estudios, informes, productos o servicios que se deriven del uso legítimo de la información recolectada.</li>
										<li>Actualizar la información financiera, comercial, crediticia y de contacto de los clientes de manera periódica.</li>
										<li>Desarrollar o utilizar herramientas que permitan conocer el comportamiento financiero y crediticio.</li>
										<li>Consultar y reportar a entidades de consulta de bases de datos u Operadores de Información, entre ellos centrales de riesgo, todo lo referente a la información financiera, comercial y crediticia.</li>
									</ul>
								</li>
								<li>
									<span className="font-bold">Usuarios página web</span>
									<p>Los Datos Personales de los usuarios de la página web, tratados por la Fundación incluyen, pero no se limitan a:</p>
									<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
										<li>Datos generales de identificación.</li>
										<li>Información de contacto.</li>
										<p>La Fundación respeta la privacidad de todos los usuarios que visitan su web. En general, la información o Datos Personales de los usuarios o clientes recopilados a través de los diferentes formatos de la página se utilizan para responder a las consultas formuladas y contactos futuros de ser necesario en el seguimiento a las consultas. En particular las finalidades del Tratamiento de los Datos Personales recolectados a través de la página web son las siguientes:</p>
										<li>Mejorar la experiencia de navegación en la página web de la Fundación, así como realizar mantenimiento y soporte de la página web.</li>
										<li>Para responder a las consultas o solicitudes formuladas a la Fundación.</li>
										<p>Adicionalmente, la Fundación recolecta datos personales de los usuarios de la página web a través de cookies. Una cookie son pequeños archivos de información que se descargan en el ordenador del usuario al acceder a determinadas páginas web para almacenar y recuperar información sobre la navegación que se efectúa desde dicho equipo. A través de las cookies, las páginas web recuerdan información sobre la visita de los usuarios, lo que permite que se proporcione una mejor y más segura experiencia de navegación en el mismo.</p>
										<p>La página web de la Fundación utiliza “cookies” como tecnología de seguimiento en línea. Al activar las cookies o mantener habilitada esa función, el usuario autoriza a la Fundación a que recopile y almacene automáticamente información que incluye, pero no se limita a: la dirección IP o la identificación del dispositivo asignada al dispositivo final, la identificación de la plataforma, computador, dispositivo móvil, o cualquier otro dispositivo o mecanismo a través del cual accede a la página web, contenido texto e imágenes, así como archivos de datos proporcionados para descargar, las actividades de los usuarios en la página web, el tipo de browser de navegación, así como la fecha y hora de ingreso y uso, cookies, huellas digitales, web logs, web becons, web crawlers, entre otros. No obstante, el usuario o visitante puede optar por no autorizar el uso de cookies a la Fundación directamente en la página web.</p>
									</ul>
								</li>
							</ol>
            </li>
            <li>
              <span className="font-bold text-lg">Tratamiento de Datos Sensibles</span>
              <p>La <strong>Fundación Arduvi</strong> podrá realizar el tratamiento de datos sensibles. Para estos efectos, la Fundación dará aplicación a las disposiciones legales sobre el Tratamiento de Datos sensibles establecidas en las Ley 1581 de 2012, y demás normas complementarias, incluyendo las siguientes:</p>
							<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
								<li>Obtener autorización explícita del titular para el tratamiento, informando el carácter facultativo de la autorización y los datos que se consideran sensibles. Esta autorización será implementada en toda recolección de datos sensibles, salvo en los siguientes casos en los que no se requiere autorización por disposición legal.</li>
								<li>El tratamiento sea necesario para salvaguardar el interés vital del titular y este se encuentre física o jurídicamente incapacitado. En estos eventos, los representantes legales deberán otorgar su autorización.</li>
								<li>El tratamiento sea efectuado en el curso de las actividades legítimas y con las debidas garantías por parte de la fundación, ONG, asociación o cualquier otro organismo sin ánimo de lucro, cuya finalidad sea política, filosófica, religiosa o sindical, siempre que se refieran exclusivamente a sus miembros o a las personas que mantengan contactos regulares por razón de su finalidad. En estos eventos, los datos no se podrán suministrar a terceros sin la autorización del titular.</li>
								<li>El tratamiento se refiera a datos que sean necesarios para el reconocimiento, ejercicio o defensa de un derecho en un proceso judicial.</li>
								<li>El Tratamiento tenga una finalidad histórica, estadística o científica. En este evento deberán adoptarse las medidas conducentes a la supresión de la identidad de los Titulares.</li>
							</ul>
							<p>En el evento en que la Fundación realice el Tratamiento de Datos Sensibles de empleados o personas naturales que hacen parte de los programas desarrollados por la Fundación, observará estrictamente las limitaciones y obligaciones establecidas por la Ley 1581 de 2012, sus decretos reglamentarios y demás normas concordantes.</p>
							<ol className="pl-5 mt-2 space-y-1 list-inside list-[upper-roman]">
								<li>
									<span className="font-bold">Tratamiento de datos sensibles de trabajadores</span>
									<p>La Fundación podrá realizar tratamiento de datos sensibles de sus trabajadores, tales como: salud del empleado, pruebas médicas de ingreso, de accidentes laborales, de valoración de salud en general, información de incapacidades médicas, y otras relacionadas, estatura, peso, complexión, datos biométricos como huellas y fotografías. Estos datos sensibles serán tratados por la Fundación para realizar las siguientes finalidades:</p>
									<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
										<li>Verificar si la persona cumple con los requisitos físicos necesarios para desempeñar el cargo para el cual está aplicando o fue contratado.</li>
										<li>Contar con la información necesaria para atender cualquier emergencia médica que se presente durante el desarrollo de sus labores con la Fundación.</li>
										<li>Cumplir con las normas de seguridad y salud en el trabajo e implementar el SG-STT, y cualquier otro programa, sistema y/o plan que busque proteger la salud del trabajador y las personas en el lugar de trabajo.</li>
										<li>Identificar al personal que acceda a las instalaciones de la Fundación o que desarrolle alguna actividad laboral con la Fundación.</li>
										<li>Dar cumplimiento a las obligaciones legales que se deriven de la relación laboral, tales como, realizar todos los trámites necesarios para la inscripción de beneficiarios ante el Sistema de Seguridad Social, o cualquier otra actividad derivada de la legislación aplicable.</li>
									</ul>
								</li>
								<li>
									<span className="font-bold">Tratamiento de datos sensibles de personas naturales que hacen parte de programas desarrollados por la Fundación</span>
									<p>La Fundación podrá realizar tratamiento de datos sensibles de sus trabajadores y de personas naturales con las cuales realice labores y actividades implementadas y desarrolladas por la Fundación, tales como: estado de salud, género, origen racial, estado civil, lugar de vivienda, estrato socioeconómico, origen de recursos de vivienda, características de viviendas, ingresos, gastos de vivienda, gastos familiares, nivel de educación, huellas dactilares, fotografías, videos, entre otros. Para estos efectos, la Fundación dará aplicación a las disposiciones legales sobre tratamiento de datos sensibles.</p>
									<p>Los datos sensibles de los trabajadores y de las personas naturales con las cuales la Fundación realice labores y actividades implementadas y desarrolladas por ésta, serán tratados para las siguientes finalidades:</p>
									<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
										<li>Verificación de la titularidad de los datos personales.</li>
										<li>Identificación y análisis de focos de intervención.</li>
										<li>Realizar el diseño de proyectos de intervención interna y con terceros.</li>
										<li>Realizar informes de gestión y de resultados internos.</li>
										<li>Realizar diagnósticos de caracterización socioeconómica del territorio.</li>
										<li>Realizar estudios académicos y de investigación.</li>
										<li>Afiliación a sistemas de salud.</li>
										<li>Identificación de poblaciones objeto de intervención por parte de la Fundación, así como de grupos vulnerables para realizar afiliaciones a salud.</li>
										<li>Verificación de antecedentes judiciales.</li>
										<li>Realizar diagnósticos y procesos de medición de impacto.</li>
										<li>Realizar proyectos de investigación para fines académicos.</li>
										<li>Actualización de datos proporcionados por las personas naturales que hacen parte de los diferentes programas desarrollados por la Fundación.</li>
									</ul>
								</li>
								<li>
									<span className="font-bold">Tratamiento de datos de niños, niñas y adolescentes</span>
									<p>El tratamiento de datos personales de niños, niñas y adolescentes está prohibido, excepto cuando se trate de datos de naturaleza pública, y cuando dicho tratamiento cumpla con los siguientes requisitos:</p>
									<ol className="pl-5 mt-2 space-y-1 list-inside list-[upper-roman]">
										<li>Que respondan y respeten el interés superior de los niños, niñas y adolescentes.</li>
										<li>Que se asegure el respeto de sus derechos fundamentales.</li>
									</ol>
								</li>
								<li>
									<span className="font-bold">Tratamiento de hijos de empleados</span>
									<p>La Fundación podrá tratar información de niños, niñas y adolescentes de conformidad con la normativa aplicable y conforme con las siguientes finalidades:</p>
									<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
										<li>Identificación de los hijos de los trabajadores de la Fundación para efectos de dar cumplimiento a las obligaciones legales que se deriven de la relación laboral, tales como, realizar todos los trámites necesarios para la inscripción de beneficiarios ante las autoridades y ante el Sistema de Seguridad Social en Salud.</li>
										<li>Comunicar a los empleados las actividades de bienestar que la Fundación ha organizado para sus familias.</li>
										<li>Realizar actividades de bienestar que la Fundación organiza para las familias de los empleados.</li>
									</ul>
								</li>
								<li>
									<span className="font-bold">Tratamiento de niños, niñas y adolescentes pertenecientes a programas desarrollados por la Fundación</span>
									<p>La Fundación podrá tratar información de niños, niñas y adolescentes pertenecientes a los programas desarrollados e implementados por la Fundación. La Fundación observará estrictamente las limitaciones y obligaciones establecidas en la Ley 1581 de 2012, sus decretos reglamentarios y demás nomas concordantes. Por lo tanto, en caso de realizar Tratamiento de Datos Personales de niños, niñas y/o adolescentes, la Fundación se asegurará de lo siguiente:</p>
									<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
										<li>Que el Tratamiento responda y respete el interés superior de los niños, niñas y adolescentes.</li>
										<li>Que en el Tratamiento se asegure el respeto de los derechos fundamentales de los niños, niñas y adolescentes.</li>
										<li>Valorar la opinión del menor cuando este cuente con la madurez, autonomía y capacidad para entender el asunto.</li>
										<p>El Tratamiento de niños, niñas y adolescentes se realizará conforme con las siguientes finalidades:</p>
										<li>Gestión de cupos escolares por parte de la Fundación junto con las Secretarías de Educación.</li>
										<li>Gestión de cupos en grupos ecológicos, de danza, teatro, música y percusión.</li>
										<li>Control de asistencia a eventos organizados por la Fundación.</li>
										<li>Desarrollar programas de atención y cuidados de la primera infancia.</li>
										<li>Realizar proyectos de investigación para fines académicos.</li>
									</ul>
								</li>
							</ol>
            </li>
            <li>
              <span className="font-bold text-lg">Derechos de los Titulares</span>
							<p>Los titulares de los datos personales registrados en las bases de datos de la <strong>Fundación Arduvi</strong>, tienen los siguientes derechos:</p>
              <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                <li>Acceder, conocer, actualizar, suprimir y rectificar sus datos personales frente a la Universidad Libre, en su condición de responsable del Tratamiento de datos personales.</li>
                <li>Solicitar prueba de la existencia de la autorización otorgada a la Universidad Libre, a menos de que medie excepción legal.</li>
                <li>Recibir información por parte de la Universidad, previa solicitud, respecto del uso que ha dado a sus datos personales. </li>
                <li>Presentar ante la Superintendencia de Industria y Comercio (SIC) quejas por infracciones a lo dispuesto en la normatividad vigente. </li>
                <li>Modificar o revocar la autorización y/o solicitar la supresión de los datos personales, cuando en el tratamiento haya vulneración a los principios, derechos y garantías constitucionales y legales vigentes. Este derecho procede, cuando no genere incumplimiento por parte de la Universidad con otras obligaciones legales o contractuales relativas a la permanencia del dato.</li>
              </ul>
							<ol className="pl-5 mt-2 space-y-1 list-inside list-[upper-roman]">
								<li>
									<span className="font-bold">Personas autorizadas para ejercer los derechos de los titulares</span>
									<p>Los datos personales objeto de tratamiento por parte de la Fundación solo podrán suministrarse a las siguientes personas:</p>
									<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
										<li>A los titulares, quienes deberá acreditar su identidad en forma suficiente, sus causahabientes o sus representantes legales.</li>
										<li>A las entidades públicas o administrativas en ejercicio de sus funciones legales o por orden judicial.</li>
										<li>A los terceros autorizados por el titular o la ley.</li>
									</ul>
								</li>
								<li>
									<span className="font-bold">Ejecución de Derechos</span>
									<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
										<li>
											<span className="font-bol">Consultas</span>
											<p>Los titulares o sus causahabientes podrán consultar la información personal del titular que repose en las bases de datos de la Fundación.</p>
											<p>Las consultas podrán realizarse de la siguiente manera:</p>
											<ol className="pl-5 mt-2 space-y-1 list-inside list-[upper-roman]">
												<li>
													<span className="font-bold">Escrita</span>
													<p>Las consultas que se hagan por medio escrito deben ser presentadas en las oficinas de la Fundación Santo Domingo ubicada en la Avenida 9E No. 11 - 51 Barrio La Riviera en la ciudad de San José de Cúcuta, Colombia, y en ellas se deberán aportar, por lo menos, los siguientes datos:</p>
													<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
														<li>Nombre e identificación del titular.</li>
														<li>Resumen de los hechos que alega.</li>
														<li>Dirección de envío de correspondencia.</li>
														<li>Copia simple de la cedula de ciudadanía.</li>
													</ul>
												</li>
												<li>
													<span className="font-bold">Electrónica</span>
													<p>Las solicitudes pueden hacerse a través del correo: <span className="text-blue-500 underline">info@fundacionarduvi.org</span>.</p>
													<p>Las consultas deberán contener, por lo menos, la siguiente información:</p>
													<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
														<li>Nombre e identificación del titular.</li>
														<li>Breve resumen de los hechos que alega.</li>
														<li>Dirección de envío de correspondencia.</li>
														<li>Copia simple de la cedula de ciudadanía.</li>
													</ul>
												</li>
												<li>
													<span className="font-bold">Verbal</span>
													<p>Estas solicitudes deben ser hechas en las dependencias de la Fundación. En estos casos, el funcionario competente tomará nota de la solicitud presentada y le entregará al solicitante una constancia de presentación de su consulta.</p>
													<p>En todos los casos anteriores, la consulta será atendida en un término máximo de diez (10) días hábiles contados a partir de la fecha de recibo de la misma. Cuando no fuere posible atender la consulta dentro de dicho término, se informará al interesado, expresando los motivos de la demora y señalando la fecha en que se atenderá su consulta, la cual en ningún caso podrá superar los cinco (5) días hábiles siguientes al vencimiento del primer término.</p>
												</li>
											</ol>
										</li>
										<li>
											<span className="font-bold">Reclamos</span>
											<p>El Titular o sus causahabientes que consideren que la información contenida en las bases de datos de la Fundación debe ser objeto de corrección, actualización o supresión, o cuando adviertan el presunto incumplimiento de cualquiera de los deberes contenidos en la ley, podrán presentar un reclamo antes la misma, el cual será tramitado bajo las siguientes reglas:</p>
											<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
												<li>El reclamo se formulará mediante solicitud dirigida la Fundación o al encargado del tratamiento, con la identificación del titular, la descripción de los hechos que dan lugar al reclamo, la dirección, y acompañando los documentos que se quiera hacer valer. Si el reclamo resulta incompleto, se requerirá al interesado dentro de los cinco (5) días siguientes a la recepción del reclamo para que subsane las fallas. Transcurridos dos (2) meses desde la fecha del requerimiento, sin que el solicitante presente la información requerida, se entenderá que ha desistido del reclamo. En caso de que quien reciba el reclamo no sea competente para resolverlo, dará traslado a quien corresponda en un término máximo de dos (2) días hábiles e informará de la situación al interesado.</li>
												<li>Una vez recibido el reclamo completo, se incluirá en la base de datos una leyenda que diga “reclamo en trámite” y el motivo del mismo, en un término no mayor a dos (2) días hábiles. Dicha leyenda deberá mantenerse hasta que el reclamo sea decidido.</li>
												<li>El término máximo para atender el reclamo será de quince (15) días hábiles contados a partir del día siguiente a la fecha de su recibo. Cuando no fuere posible atender el reclamo dentro de dicho término, se informará al interesado los motivos de la demora y la fecha en que se atenderá su reclamo, la cual en ningún caso podrá superar los ocho (8) días hábiles siguientes al vencimiento del primer término.</li>
											</ul>
										</li>
										<li>
											<span className="font-bold">Supresión de Datos</span>
											<p>El Titular tiene el derecho, en todo momento, a solicitar a la Fundación la supresión (eliminación) de sus Datos Personales, sin embargo, el derecho de supresión no es absoluto y el Responsable puede negar el ejercicio del mismo cuando:</p>
											<ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
												<li>El Titular tenga un deber legal o contractual de permanecer en la base de datos de la Fundación.</li>
												<li>La supresión de los Datos Personales obstaculice actuaciones judiciales o administrativas vinculadas a obligaciones fiscales, la investigación y persecución de delitos o la actualización de sanciones administrativas.</li>
												<li>Los Datos Personales sean necesarios para proteger los intereses jurídicamente tutelados del Titular, para realizar una acción en función del interés público, o para cumplir con una obligación legalmente adquirida por el Titular.</li>
											</ul>
										</li>
										<li>
											<span className="font-bold">Revocatoria de la Autorización.</span>
											<p>El Titular de los Datos Personales podrá revocar el consentimiento al Tratamiento de sus Datos Personales en cualquier momento, siempre y cuando no lo impida una disposición legal.</p>
										</li>
									</ul>
								</li>
							</ol>
            </li>
            <li>
              <span className="font-bold text-lg">Aliados</span>
							<p>Dentro del desarrollo del objeto de la Fundación, esta realiza una serie de convenios, contratos y alianzas con personas naturales y jurídicas, con el propósito de que estas se encuentren alineadas con la protección de los Datos Personales de acuerdo con el ordenamiento jurídico colombiano.</p>
							<p>En este sentido, la entrega de la información por parte de la Fundación a los Aliados se hará en cumplimiento de la presente Política, en particular conforme a las normas de transferencias y transmisiones según sea el caso.</p>
							<p>Para que los Aliados puedan acceder a información suministrada por la Fundación, éstos deberán cumplir al menos con los siguientes requisitos:</p>
              <ul className="pl-5 mt-2 space-y-1 list-disc list-inside">
                <li>Los Aliados deben tener unos sistemas de seguridad informática que permitan la protección de la información que pueda ser entregada a través de la red.</li>
								<li>Previo a la entrega de la información, se deberá suscribir un acuerdo de confidencialidad por parte del Aliado y la Fundación o, dentro del contrato que se firme entre la Fundación y el Aliado, se incluya una cláusula de confidencialidad de la información.</li>
								<li>La información que se entrega por parte de la Fundación a los Aliados solamente será de uso exclusivo de los mismos, por lo que está prohibido el traslado de la misma a terceras personas.</li>
								<li>Los Aliados que realicen gestiones de cobro de cartera a nombre de la Fundación deben garantizar la privacidad de la información, además del cumplimiento de la presente Política y la política de cobranza establecida.</li>
              </ul>
            </li>
						<li>
							<span className="font-bold text-lg">Vigencia</span>
							<p>La presente política de protección de datos personales está vigente desde la fecha de su publicación en la página web <span className="text-blue-500 underline">www.fundacionarduvi.org</span>. Si se presentan modificaciones sustanciales a esta Política, serán informadas a través de la página en un lugar visible, o mediante notificación enviada a cualquiera de los datos de contacto suministrados por el Titular de los datos personales.</p>
						</li>
          </ol>
				</div>
			</div>
			<Footer />
		</Layout>
	);
}
export default Privacy;
