export default function Business() {
	return (
		<div className="bg-gray-50">
			<div className="container mx-auto max-w-3xl py-12 lg:py-16">
				<h2 className="text-3xl text-center font-bold tracking-tight text-yellow-button sm:text-4xl mb-4">
					Cronograma del proceso
				</h2>
				<div className="relative overflow-x-auto">
					<table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400 border rounded-xl">
						<thead className="text-xs text-gray-700 uppercase bg-blue-200 dark:bg-gray-700 dark:text-gray-400">
							<tr>
								<th scope="col" className="px-6 py-3">
									Fecha
								</th>
								<th scope="col" className="px-6 py-3">
									Actividad
								</th>
							</tr>
						</thead>
						<tbody>
							<tr className="odd:bg-white even:bg-blue-200">
                <td className="px-6 py-4">Viernes 16 de agosto</td>
								<td className="px-6 py-4">Lanzamiento de la convocatoria</td>
							</tr>
							<tr className="odd:bg-white even:bg-blue-100">
                <td className="px-6 py-4">Viernes 23 de agosto</td>
								<td className="px-6 py-4">Cierre de la convocatoria voluntarios estudiantiles</td>
							</tr>
							<tr className="odd:bg-white even:bg-blue-100">
                <td className="px-6 py-4">Miércoles 28 de agosto</td>
								<td className="px-6 py-4">Presentación del Programa del Voluntariado: Conversatorio</td>
							</tr>
              <tr className="odd:bg-white even:bg-blue-100">
                <td className="px-6 py-4">Viernes 30 de agosto</td>
								<td className="px-6 py-4">Cierre de la convocatoria voluntarios sociales y comunitarios</td>
							</tr>
              <tr className="odd:bg-white even:bg-blue-100">
                <td className="px-6 py-4">
                  <div>Sábado 24 al miércoles 28 de agosto – Voluntarios estudiantiles</div>
                  <div>Sábado 31 de agosto al lunes 10 de septiembre – Voluntarios sociales y comunitarios</div>
                </td>
								<td className="px-6 py-4">Revisión de hojas de vida y preselección</td>
							</tr>
              <tr className="odd:bg-white even:bg-blue-100">
                <td className="px-6 py-4">Por definir</td>
								<td className="px-6 py-4">
                  <div>Selección y notificación</div>
                  <div>Entrevistas y pruebas técnicas</div>
                </td>
							</tr>
              <tr className="odd:bg-white even:bg-blue-100">
                <td className="px-6 py-4">Lunes 16 de septiembre</td>
								<td className="px-6 py-4">Inicio del proceso de voluntariado</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
		</div>
	);
}
