import Modal from "../modals/modal";
import Img1 from "../../assets/img/icons/salud-mental.png";
import Img2 from "../../assets/img/icons/nutricion.png";
import Img3 from "../../assets/img/icons/gestoria-casos.png";
import Img4 from "../../assets/img/icons/formacion-continua.png";
import Img5 from "../../assets/img/icons/servicios-corporativos.png";

const features = [
  { 
    id: 1,
    button: <>
      <div className="relative flex flex-col items-center">
        <div className="bg-gray-100 p-3 rounded-full shadow-2xl absolute -top-10">
          <img src={Img1} alt="ícono descriptivo" className="w-14" />
        </div>
        <div className="bg-gray-100 rounded-xl h-full w-full">
          <p className="text-base text-gray-600 pt-10 px-4 pb-3 text-center font-bold">Salud mental</p>
        </div>
      </div>
    </>,
    name: 'Salud mental',
    content: <>
      <p className="mb-4">
        <strong className="font-bold">Atención Psicosocial:</strong> Este servicio aborda tanto aspectos psicológicos como sociales, busca gestionar el impacto emocional derivado de eventos estresantes, crisis o desafíos de salud mental. Su objetivo primordial es fortalecer el bienestar psicosocial, proporcionando intervenciones que consideran tanto aspectos emocionales como contextuales, con la finalidad de mejorar la calidad de vida y fomentar la resiliencia.
      </p>
      <p>
      <strong className="font-bold">Servicio en Psicología Clínica:</strong> El servicio en Psicología Clínica implica evaluación, diagnóstico y tratamiento de diversos trastornos y problemas psicológicos. Los profesionales en este campo utilizan enfoques terapéuticos para ayudar a individuos a manejar sus dificultades emocionales, conductuales o cognitivas. 
      </p>
    </>,
  },
  { 
    id: 2,
    button: <>
      <div className="relative flex flex-col items-center">
        <div className="bg-gray-100 p-3 rounded-full shadow-2xl absolute -top-10">
          <img src={Img2} alt="ícono descriptivo" className="w-14" />
        </div>
        <div className="bg-gray-100 rounded-xl h-full w-full">
          <p className="text-base text-gray-600 pt-10 px-4 pb-3 text-center font-bold">Salud nutricional</p>
        </div>
      </div>
    </>,
    name: '⁠Salud nutricional',
    content: "El componente de  salud nutricional busca la promoción de una alimentación ​balanceada y saludable y las acciones para garantizar el derecho a la alimentación sana con equidad en el curso de la vida, mediante la reducción y prevenc​ión de la malnutrición, el control de los riesgos sanitarios  y la gestión intersectorial de la seguridad alimentaria y nutricional con perspectiva territorial.​"
  },
  { 
    id: 3,
    button: <>
      <div className="relative flex flex-col items-center">
        <div className="bg-gray-100 p-3 rounded-full shadow-2xl absolute -top-10">
          <img src={Img3} alt="ícono descriptivo" className="w-14" />
        </div>
        <div className="bg-gray-100 rounded-xl h-full w-full">
          <p className="text-base text-gray-600 pt-10 px-4 pb-3 text-center font-bold">Gestoría de casos</p>
        </div>
      </div>
    </>,
    name: 'Gestoría de casos para el restablecimiento de derechos',
    content: "Realizamos la coordinación del restablecimiento de derechos facilitando acciones para proteger  los derechos de individuos en situaciones vulnerables asegurando la atención integral y el apoyo necesario para mejorar la situación y bienestar de la persona involucrada. "
  },
  { 
    id: 4,
    button: <>
      <div className="relative flex flex-col items-center">
        <div className="bg-gray-100 p-3 rounded-full shadow-2xl absolute -top-10">
          <img src={Img4} alt="ícono descriptivo" className="w-14" />
        </div>
        <div className="bg-gray-100 rounded-xl h-full w-full">
          <p className="text-base text-gray-600 pt-10 px-4 pb-3 text-center font-bold">Formación contínua</p>
        </div>
      </div>
    </>,
    name: 'Formación contínua para el desarrollo humano',
    content: "Dentro de nuestra línea de acción de Liderazgo transformacional implementamos un modelo de formación interna y de réplica para organizaciones que requieran del proceso."
  },
  { 
    id: 5,
    button: <>
      <div className="relative flex flex-col items-center">
        <div className="bg-gray-100 p-3 rounded-full shadow-2xl absolute -top-10">
          <img src={Img5} alt="ícono descriptivo" className="w-14" />
        </div>
        <div className="bg-gray-100 rounded-xl h-full w-full">
          <p className="text-base text-gray-600 pt-10 px-4 pb-3 text-center font-bold">Servicios corporativos</p>
        </div>
      </div>
    </>,
    name: 'Servicios corporativos',
    content: "Están enfocados a la sostenibilidad de la Fundación, es el conjunto de las actividades destinadas a respaldar y gestionar nuestras operaciones para garantizar el cumplimiento de los objetivos, y facilitar un entorno propicio para el crecimiento y el éxito institucional."
  },
]

export default function Fundamentals() {
  return (
    <div className="bg-section-pattern bg-cover bg-no-repeat bg-fixed">
      <div className="container mx-auto py-16 px-4 sm:px-6 lg:gap-x-8 lg:py-24 lg:px-8">
        <div>
          <h2 className="mt-2 text-3xl sm:text-4xl font-bold tracking-tight text-yellow-button text-center mb-8">Servicios fundamentales</h2>
          <p className="mt-4 text-lg text-white text-center mb-14">
            La Fundación Arduvi ofrece cuatro líneas de intervención en salud que forman parte del marco del Modelo Arduvi – Inclusión con Amor
          </p>
        </div>
        <div className="mt-12 lg:col-span-2 lg:mt-0 container max-w-6xl mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-5 gap-x-6 gap-y-14">
            {features.map((feature, index) => (
              <Modal button={feature.button} title={feature.name} content={feature.content} key={index}/>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}