import Image1 from "../../assets/img/img-1.jpg";
import Image2 from "../../assets/img/img-2.jpg";

function Images(){
    return(
        <div className="w-full">
            <div className="grid grid-cols-2 gap-8">
                <img src={Image1} className="w-full h-96 object-cover" alt="Personal atendiendo clientes"/>
                <img src={Image2} className="w-full h-96 object-cover" alt="Participación de la Fundación Arduvi"/>
            </div>
        </div>
    )
}
export default Images
