import { Typewriter } from 'react-simple-typewriter'
import HeroImg  from 'assets/img/hero-img-2.jpg'
import FacebookIcon from "../../assets/img/facebook-icon.png";
import XIcon from "../../assets/img/x-icon.png";
import InstagramIcon from "../../assets/img/instagram-icon.png";

const navigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
      { name: 'Contacto', href: '/contacto' },
  ],
  company: [
      { name: 'Casos', href: '/casos' },
      { name: 'Servicios', href: '/servicios' },
      { name: 'Nosotros', href: '/nosotros' },
      { name: 'Carreras', href: '/carreras' },
      { name: 'Blog', href: '/blog' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/Fundacion-Arduvi-103873258896429',
      icon: FacebookIcon,
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/fundacionarduvi/',
      icon: InstagramIcon,
    },
    {
      name: 'X',
      href: 'https://x.com/arduvifundacion?s=21&t=Ff1wK_WTR7wsA-xB_OgMsg',
      icon: XIcon,
    },
  ],
}
function Header(){
    return(
      <main>
        <div className="relative px-6 lg:px-8">
          <div className="container mx-auto xl:pt-20 xl:pb-64 lg:pt-40 lg:pb-48 pt-10 pb-8">
            <div className='flex flex-col lg:flex-row items-center'>
              <div>
                <h1 className="text-2xl font-semibold tracking-tight pb-3  sm:text-5xl">
                  Fundación ARDUVI
                </h1>
                <p className="text-3xl font-semibold tracking-tight pb-4  sm:text-7xl">
                Inclusión <span> </span>
                    <Typewriter
                          words={['con Amor.', 'para Todos.', 'para Ti.']}
                          loop={0}
                          cursor

                          cursorStyle='_'
                          typeSpeed={120}
                          deleteSpeed={50}
                          delaySpeed={1000}
                          // onLoopDone={handleDone}
                          // onType={handleType}
                      />
                </p>
                <p className="mt-6 text-xl w-full lg:w-4/5 leading-8 text-black ">
                Contribuyendo en la transformación socio-cultural de Cúcuta, Norte de Santander y el territorio nacional.
                </p>
                <div className="absolute bottom-10 left-0 flex space-x-6 xl:mx-20 mx-6">
                {navigation.social.map((item) => (
                  <a key={item.name} href={item.href} target='_blank' rel='noreferrer' className="text-gray-900 hover:text-gray-800">
                    <span className="sr-only">{item.name}</span>
                    <img src={item.icon} alt='social media icon' className="h-8 w-8" aria-hidden="true" />
                  </a>
                ))}
                </div>
              </div>
              <div className="w-10/12 my-12 lg:mt-0 lg:w-2/5">
                <img src={HeroImg} alt='Grupo de la Fundación Arduvi' className='w-full rounded-2xl shadow-2xl shadow-slate-400'/>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
}

export default Header
