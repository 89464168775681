import FacebookIcon from "../../assets/img/facebook-icon.png";
import XIcon from "../../assets/img/x-icon.png";
import InstagramIcon from "../../assets/img/instagram-icon.png";

const navigation = {
  solutions: [
    { name: 'Marketing', href: '#' },
    { name: 'Analytics', href: '#' },
    { name: 'Commerce', href: '#' },
    { name: 'Insights', href: '#' },
  ],
  support: [
      { name: 'Contacto', href: '/contacto' },
  ],
  company: [
      { name: 'Casos', href: '/casos' },
      { name: 'Servicios', href: '/servicios' },
      { name: 'Nosotros', href: '/nosotros' },
      { name: 'Carreras', href: '/carreras' },
      { name: 'Blog', href: '/blog' },
  ],
  legal: [
    { name: 'Claim', href: '#' },
    { name: 'Privacy', href: '#' },
    { name: 'Terms', href: '#' },
  ],
  social: [
    {
      name: 'Facebook',
      href: 'https://www.facebook.com/Fundacion-Arduvi-103873258896429',
      icon: FacebookIcon,
    },
    {
      name: 'Instagram',
      href: 'https://www.instagram.com/fundacionarduvi/',
      icon: InstagramIcon,
    },
    {
      name: 'X',
      href: 'https://x.com/arduvifundacion?s=21&t=Ff1wK_WTR7wsA-xB_OgMsg',
      icon: XIcon,
    },
  ],
}
function Header(){
    return(
      <main>
        <div className="relative px-6 lg:px-8">
          <div className="container mx-auto xl:pt-32 xl:pb-40 lg:pt-40 lg:pb-40 pt-24 pb-12 ">
            <div>
              <div>
                <h1 className="text-4xl font-semibold tracking-tight pb-8  sm:text-5xl">
                  Voluntariado
                </h1>
                <p className="mt-6 text-xl leading-10 text-black max-w-4xl">
                  La Fundación ARDUVI, basando su actuar en lo estipulado en la Ley 720 de 2001, reglamentada por el Decreto Nacional 4290 de 2005, a través de la cual se reconoce, promueve y regula la acción voluntaria en Colombia, define al voluntario o voluntaria como una persona natural, que libre y responsablemente, sin recibir remuneración de carácter laboral o como contratista, ofrece tiempo, labor y talento como parte del Programa de Voluntariado Social y Comunitario, desarrollando acciones específicas y acorde a su disciplina, profesión, arte u oficio, en pro de la atención de las personas y comunidades más vulnerables.
                </p>
              </div>
            </div>
          </div>
        </div>
      </main>
    )
}

export default Header
