import {connect} from 'react-redux'
import logo  from "assets/img/logo-full.png"
import FacebookIcon from "../../assets/img/facebook-icon.png";
import XIcon from "../../assets/img/x-icon.png";
import InstagramIcon from "../../assets/img/instagram-icon.png";

const navigation = {
    solutions: [
      { name: 'Inicio', href: '/' },
      { name: 'Nosotros', href: '/nosostros' },
      { name: 'Servicios', href: '/empresas' },
      // { name: 'Blog', href: '/blog' },
    ],
    support: [
        { name: 'Contacto', href: '/contacto' },
    ],
    company: [
        { name: 'Casos', href: '/casos' },
        { name: 'Servicios', href: '/servicios' },
        { name: 'Nosotros', href: '/nosotros' },
        // { name: 'Carreras', href: '/carreras' },
        // { name: 'Blog', href: '/blog' },
    ],
    legal: [
      { name: 'Privacidad', href: '/privacidad' },
      { name: 'Términos', href: '/terminos-y-condiciones' },
    ],
    social: [
      {
        name: 'Facebook',
        href: 'https://www.facebook.com/Fundacion-Arduvi-103873258896429',
        icon: FacebookIcon,
      },
      {
        name: 'Instagram',
        href: 'https://www.instagram.com/fundacionarduvi/',
        icon: InstagramIcon,
      },
      {
        name: 'X',
        href: 'https://x.com/arduvifundacion?s=21&t=Ff1wK_WTR7wsA-xB_OgMsg',
        icon: XIcon,
      },
    ],
  }

function Footer(){
    return(
        <footer className="bg-gray-50" aria-labelledby="footer-heading">
        <h2 id="footer-heading" className="sr-only">
          Footer
        </h2>
        <div className="container mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
          <div className="xl:grid xl:grid-cols-3 xl:gap-8">
            <div className="space-y-8 xl:col-span-1">
              <img
                src={logo}
                width={140}
                height={140}
                className=""
                alt="Fundación Arduvi"
              />
              <div className="flex space-x-6">
                {navigation.social.map((item) => (
                  <a key={item.name} href={item.href} className="text-gray-400 hover:text-gray-500">
                    <span className="sr-only">{item.name}</span>
                    <img src={item.icon} alt='social media icon' className="h-6 w-6" aria-hidden="true" />
                  </a>
                ))}
              </div>
            </div>
            <div className="mt-12 grid grid-cols-2 gap-8 xl:col-span-2 xl:mt-0">
              <div className="md:grid md:grid-cols-2 md:gap-8">
                <div>
                  <h3 className="text-base font-medium text-gray-900">Visítanos</h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.solutions.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="mt-12 md:mt-0">
                  <h3 className="text-base font-medium text-gray-900">Contáctanos</h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.support.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
              <div className="md:grid md:grid-cols-2 md:gap-8">
                {/* <div>
                  <h3 className="text-base font-medium text-gray-900">Fundación</h3>
                  <ul role="list" className="mt-4 space-y-4">
                    {navigation.company.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div> */}
                <div className="mt-12 md:mt-0">
                  <h3 className="text-base font-medium text-gray-900">Legal</h3>
                  <ul className="mt-4 space-y-4">
                    {navigation.legal.map((item) => (
                      <li key={item.name}>
                        <a href={item.href} className="text-base text-gray-500 hover:text-gray-900">
                          {item.name}
                        </a>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-12 border-t border-gray-200 pt-8">
            <p className="text-base text-gray-400 xl:text-center">&copy; 2024 Fundación Arduvi, inclusión con amor. Todos los derechos reservados.</p>
          </div>
        </div>
      </footer>
    )
}

const mapStateToProps=state=>({

})

export default connect(mapStateToProps, {

}) (Footer)
