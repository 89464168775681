import Video1 from "../../assets/video/video-1.mp4";
import Video2 from "../../assets/video/video-2.mp4";
import Poster1 from "../../assets/img/posters/poster-video-1.jpg";
import Poster2 from "../../assets/img/posters/poster-video-2.jpg";

const posts = [
    {
      title: 'Nuestra historia',
      category: { name: 'Video' },
      url: Video1,
      poster: Poster1,
    },
    {
      title: 'Historias de amor',
      category: { name: 'Video' },
      url: Video2,
      poster: Poster2,
    },
  ]

  export default function UseCases() {
    return (
      <div className="relative bg-gray-50 px-4 pt-16 pb-20 sm:px-6 lg:px-8 lg:pt-24 lg:pb-28">
        <div className="absolute inset-0">
          <div className="h-1/3 bg-white sm:h-2/3" />
        </div>
        <div className="relative container mx-auto">
          <div className="text-center">
            <h2 className="text-3xl font-bold tracking-tight text-yellow-button sm:text-4xl">Nuestras iniciativas</h2>
          </div>
          <div className="mx-auto mt-12 grid max-w-lg gap-5 lg:max-w-none lg:grid-cols-3">
            {posts.map((post) => (
              <div key={post.title} className="flex flex-col overflow-hidden rounded-2xl shadow-lg">
                <div className="flex-shrink-0">
                  <video className="h-64 w-full rounded-2xl" src={post.url} controls poster={post.poster}/>
                </div>
                <div className="flex flex-1 flex-col justify-between bg-white p-6">
                  <div className="flex-1">
                    <div className="text-sm font-medium text-yellow-button">
                      <div className="hover:underline">
                        {post.category.name}
                      </div>
                    </div>
                    <a href={post.href} className="mt-2 block">
                      <p className="text-xl font-semibold text-gray-900">{post.title}</p>
                    </a>
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    )
  }
