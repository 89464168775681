// import FacebookIcon from "../../assets/img/facebook-icon.png";
// import XIcon from "../../assets/img/x-icon.png";
// import InstagramIcon from "../../assets/img/instagram-icon.png";

// const navigation = {
//   solutions: [
//     { name: 'Marketing', href: '#' },
//     { name: 'Analytics', href: '#' },
//     { name: 'Commerce', href: '#' },
//     { name: 'Insights', href: '#' },
//   ],
//   support: [
//       { name: 'Contacto', href: '/contacto' },
//   ],
//   company: [
//       { name: 'Casos', href: '/casos' },
//       { name: 'Servicios', href: '/servicios' },
//       { name: 'Nosotros', href: '/nosotros' },
//       { name: 'Carreras', href: '/carreras' },
//       { name: 'Blog', href: '/blog' },
//   ],
//   legal: [
//     { name: 'Claim', href: '#' },
//     { name: 'Privacy', href: '#' },
//     { name: 'Terms', href: '#' },
//   ],
//   social: [
//     {
//       name: 'Facebook',
//       href: 'https://www.facebook.com/Fundacion-Arduvi-103873258896429',
//       icon: FacebookIcon,
//     },
//     {
//       name: 'Instagram',
//       href: 'https://www.instagram.com/fundacionarduvi/',
//       icon: InstagramIcon,
//     },
//     {
//       name: 'X',
//       href: 'https://x.com/arduvifundacion?s=21&t=Ff1wK_WTR7wsA-xB_OgMsg',
//       icon: XIcon,
//     },
//   ],
// }

function Header(){
  return(
    <main>
      <div className="relative px-6 lg:px-8">
        <div className="container mx-auto xl:pt-32 lg:pt-40 lg:pb-40 pt-24 pb-12 ">
          <div>
            <div>
              <h1 className="text-4xl font-semibold tracking-tight pb-8  sm:text-7xl">
                Historia de la Fundación Arduvi
              </h1>
              <p className="mt-6 text-xl leading-10 text-black max-w-5xl">
              En Colombia se estima que 2 millones 588 mil personas, tienen algún tipo de discapacidad y tan solo un 2.6% de estas personas son vinculadas laboralmente a una empresa. Esta realidad y una experiencia vivida en un semáforo conmovió tanto a Arvey Duque Villamizar y a su hija Dayana Duque Espinosa, a crear la Fundación Arduvi con el fin de contribuir a la transformación de las vidas de personas con condiciones especiales, migrantes, población vulnerable y en riesgo social, donde los límites se superen con el corazón y permiten una verdadera inclusión con amor. Con esa misma motivación, crearon Café Arduvi, el cual se ha convertido en el orgullo, no solo familiar sino de Cúcuta, porque es el primer Café en Latinoamérica en el cual más del 80% de sus trabajadores son personas con alguna condición especial y donde cuentan con una vida digna al igual que sus familias.
              </p>
            </div>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Header
