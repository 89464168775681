import {
	Route,
	Routes,
	useLocation,
} from "react-router-dom";
import Error404 from "containers/errors/Error404";
import Home from "containers/pages/Home";
import Cases from "containers/pages/Cases";

import About from "containers/pages/About";
import Blog from "containers/pages/Blog";
import Contact from "containers/pages/Contact";
import Donate from "containers/pages/Donate";
import Services from "containers/pages/Services";
import Privacy from "containers/pages/Privacy";
import Terms from "containers/pages/Terms";
import Volunteering from "containers/pages/Volunteering";
import VolunteeringRegister from "containers/pages/VolunteeringRegister";

import { AnimatePresence } from "framer-motion";
import Category from "containers/pages/Category";
import Search from "containers/pages/Search";
import PostDetail from "containers/pages/PostDetail";

function AnimatedRoutes() {
	const location = useLocation();

	return (
		<AnimatePresence>
			<Routes location={location} key={location.pathname}>
				{/* Error Display */}
				<Route path="*" element={<Error404 />} />

				{/* Home Display */}
				<Route path="/" element={<Home />} />
				<Route path="/casos" element={<Cases />} />
				<Route path="/servicios" element={<Services />} />
				<Route path="/nosotros" element={<About />} />
				<Route path="/blog" element={<Blog />} />
				<Route path="/blog/:slug" element={<PostDetail />} />
				<Route path="/search/:term" element={<Search />} />
				<Route path="/category/:slug" element={<Category />} />
				<Route path="/contacto" element={<Contact />} />
				<Route path="/donaciones" element={<Donate />} />
				<Route path="/servicios" element={<Services />} />
				<Route path="/privacidad" element={<Privacy />} />
				<Route path="/terminos-y-condiciones" element={<Terms />} />
				<Route path="/voluntariado" element={<Volunteering />} />
				{/* <Route path="/registro-voluntario" element={<VolunteeringRegister />} /> */}
			</Routes>
		</AnimatePresence>
	);
}
export default AnimatedRoutes;
