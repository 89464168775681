import Header from "components/volunteering/Header";
import Requirements from "components/volunteering/Requirements";
import Footer from "components/navigation/Footer";
import Navbar from "components/navigation/Navbar";
import Layout from "hocs/layouts/Layout";
import { useEffect } from "react";
import { Helmet } from "react-helmet-async";
import Types from "components/volunteering/Types";
import Stages from "components/volunteering/Stages";
import CTA from "components/volunteering/CTA";
import Schedule from "components/volunteering/Schedule";

function Volunteering() {
	useEffect(() => {
		window.scrollTo(0, 0);
	}, []);
	return (
		<Layout>
			<Helmet>
				<title>Fundación Arduvi | Voluntariado</title>
				<meta
					name="description"
					content="Organización sin ánimo de lucro."
				/>
				<meta
					name="keywords"
					content="Organización sin ánimo de lucro"
				/>
				<meta name="robots" content="all" />
				<link rel="canonical" href="https://fundacionarduvi.org/" />
				<meta name="author" content="Fundación Arduvi" />
				<meta name="publisher" content="Fundación Arduvi" />

				{/* Social Media Tags */}
				<meta property="og:title" content="Fundación Arduvi | Inclusión con Amor" />
				<meta
					property="og:description"
					content="Organización sin ánimo de lucro."
				/>
				<meta property="og:url" content="https://fundacionarduvi.org/" />
				<meta
					property="og:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>

				<meta name="twitter:title" content="Fundación Arduvi | Inclusión con Amor" />
				<meta
					name="twitter:description"
					content="Organización sin ánimo de lucro."
				/>
				<meta
					name="twitter:image"
					content="https://bafybeicwrhxloesdlojn3bxyjqnxgsagtd4sl53a7t4cn4vfe2abmybzua.ipfs.w3s.link/lightbnuilbg.jpg"
				/>
				<meta name="twitter:card" content="summary_large_image" />
			</Helmet>

			<header className="flex justify-center">
				<Navbar />
			</header>
			<div className="pt-28">
				<Header />
				<Types />
				<Stages />
				<Schedule />
				<Requirements />
				{/* <CTA /> */}
			</div>
			<Footer />
		</Layout>
	);
}
export default Volunteering;
