import { Button, Modal } from 'flowbite-react';
import { useState } from 'react';

function ModalComponent({
  button,
  title,
  content,
	img,
}) {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <span onClick={() => setOpenModal(true)} className='text-xs font-bold cursor-pointer'>{button}</span>
      <Modal show={openModal} onClose={() => setOpenModal(false)}>
        <Modal.Header>{title}</Modal.Header>
        <Modal.Body>
          <div className="space-y-6">
            <div className="text-base leading-relaxed text-gray-500 dark:text-gray-400">
              {content}
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button color="gray" onClick={() => setOpenModal(false)}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalComponent;
