import RequirementsImg from '../../assets/img/requirements.JPG';

  export default function Case1() {
    return (
      <div className="relative bg-white py-16 sm:py-24">
        <div className="container mx-auto lg:grid lg:grid-cols-2 lg:items-start lg:gap-24 lg:px-8">
          <div className="relative sm:py-16 lg:py-0">
            <div aria-hidden="true" className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen">
              <div className="absolute inset-y-0 right-1/2 w-full rounded-r-3xl bg-gray-50 lg:right-72" />
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect x={0} y={0} width={4} height={4} className="text-gray-200" fill="currentColor" />
                  </pattern>
                </defs>
                <rect width={404} height={392} fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)" />
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:max-w-none lg:px-0 lg:py-20">
              {/* Testimonial card*/}
              <div className="relative overflow-hidden rounded-2xl py-56 shadow-xl">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src={RequirementsImg}
                  alt="Personas reunidas"
                />
              </div>
            </div>
          </div>

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2 className="text-3xl font-bold tracking-tight text-yellow-button sm:text-4xl">
                Requisitos para ser voluntario
              </h2>
              <div className="mt-6 text-gray-500">
                <p className="text-lg mb-3">
                  Para postularse y ser elegido en alguno de los tres tipos de voluntariado, la Fundación ARDUVI solicita a la persona interesada:
                </p>
                <ul className='list-disc pl-5'>
                  <li>
                    Comprensión del objetivo y el carácter del voluntariado no remunerado.
                  </li>
                  <li>
                    En los casos de los menores de edad, contar con autorización por escrito de sus padres, tutor o cuidador principal y el aval de su institución educativa.
                  </li>
                  <li>
                    Realizar el proceso de registro en el enlace, así como el envío de los formatos diligenciados al correo institucional.
                  </li>
                  <li>
                    Esta convocatoria esta abierta a nacionales colombianos y a aquellos extranjeros que se encuentran con documentación regular en el territorio.
                  </li>
                  <li>
                    No contar con antecedentes, procesos penales activos o inhabilitaciones de ley.
                  </li>
                  <li>
                    No ser funcionario, servidor público o contar con contrato de exclusividad.
                  </li>
                </ul>
                <p className='mt-3'>
                  <span className='font-bold'>Nota:</span> Fundación ARDUVI promueve la participación en esta convocatoria de personas desplazadas, víctimas del conflicto armado, refugiadas y migrantes, así como de personas sobrevivientes de violencia basada en género.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
