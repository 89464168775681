import ModalRSE from "../modals/modalRSE"

const details = [
  {
    button: <>
      <g className="centro">
        <circle cx="520" cy="515" r="140" className="centro-circle"/>
        <path className="st4" d="M460.6,498.6v-46.8h6.3v46.8H460.6z M463.5,478.8v-6h12.3c1.8,0,3.2-0.6,4.2-1.7s1.5-2.6,1.5-4.5v-2.4
          c0-2-0.5-3.5-1.5-4.6s-2.4-1.7-4.2-1.7h-12.3v-6h11.9c2.6,0,4.9,0.5,6.8,1.5s3.4,2.4,4.4,4.2s1.5,3.9,1.5,6.4v2.7
          c0,2.5-0.5,4.7-1.6,6.5c-1,1.8-2.5,3.2-4.4,4.2s-4.1,1.5-6.8,1.5L463.5,478.8L463.5,478.8z M482.4,498.6l-8.6-21.2l6.1-1.5
          l9.7,22.7H482.4L482.4,498.6z"/>
        <path className="st4" d="M495.6,498.6V492h6.6v6.6H495.6z"/>
        <path className="st4" d="M520.5,499.1c-2.5,0-4.7-0.4-6.6-1.1s-3.5-1.9-4.8-3.4s-2.2-3.3-2.7-5.4l6.1-2.6c0.6,2.1,1.6,3.7,3,4.8
          c1.4,1.1,3.1,1.6,5.1,1.6c2.5,0,4.5-0.5,5.9-1.6s2-2.6,2-4.6v-2.1c0-1.5-0.4-2.7-1.2-3.6s-1.8-1.5-3.1-2.1c-1.3-0.5-2.7-1-4.2-1.4
          s-2.9-0.9-4.4-1.4s-2.8-1.2-4-2.1s-2.2-2.1-3-3.5c-0.8-1.5-1.1-3.4-1.1-5.8v-1.4c0-3.9,1.2-6.9,3.6-9.1c2.4-2.2,5.9-3.2,10.3-3.2
          c3.3,0,6,0.8,8.2,2.4s3.7,4,4.5,7l-5.9,2.7c-0.5-2-1.4-3.5-2.6-4.5c-1.2-1-2.6-1.6-4.2-1.6c-2.4,0-4.3,0.5-5.6,1.6s-2,2.6-2,4.5
          v1.5c0,1.6,0.4,2.8,1.3,3.7c0.8,0.9,2,1.5,3.4,2s2.9,1,4.4,1.5c1.4,0.4,2.8,0.9,4.2,1.4s2.7,1.2,3.8,2.1c1.2,0.9,2.1,2,2.8,3.5
          c0.7,1.4,1.1,3.3,1.1,5.5v2.2c0,3.9-1.2,7-3.7,9.1C528.6,498,525,499.1,520.5,499.1L520.5,499.1z"/>
        <path className="st4" d="M539.2,498.6V492h6.7v6.6H539.2z"/>
        <path className="st4" d="M553.5,498.6v-46.7h6.3v46.7H553.5z M556.7,457.9v-6h21.8v6H556.7z M556.7,478.4v-6H576v6H556.7z M556.7,498.6
          v-6h21.8v6H556.7z"/>
        <path className="st4" d="M425,577.6l14-46.7h5.6l14,46.7h-6.8l-10-37.4l-10,37.4H425L425,577.6z M432.4,567.3v-6h19.2v6H432.4z"/>
        <path className="st4" d="M464.2,577.6v-46.8h6.3v46.8H464.2z M467.1,557.8v-6h12.3c1.8,0,3.2-0.6,4.2-1.7s1.5-2.6,1.5-4.5v-2.4
          c0-2-0.5-3.5-1.5-4.6c-1-1.2-2.4-1.7-4.2-1.7h-12.3v-6H479c2.6,0,4.9,0.5,6.8,1.5s3.4,2.4,4.4,4.2s1.5,3.9,1.5,6.4v2.7
          c0,2.5-0.5,4.7-1.6,6.5c-1,1.8-2.5,3.2-4.4,4.2s-4.1,1.5-6.8,1.5L467.1,557.8L467.1,557.8z M486,577.6l-8.6-21.2l6.1-1.5l9.7,22.7
          H486L486,577.6z"/>
        <path className="st4" d="M500.5,577.6v-46.7h6.3v46.7H500.5L500.5,577.6z M504.7,577.6v-6h9.5c2.3,0,4.1-0.6,5.3-1.8s1.9-3,1.9-5.2V544
          c0-2.2-0.6-4-1.9-5.2s-3-1.8-5.3-1.8h-9.5v-6h9.4c4.4,0,7.8,1.2,10.2,3.5s3.6,5.6,3.6,9.8v20.2c0,2.8-0.5,5.2-1.6,7.2
          s-2.7,3.5-4.7,4.5c-2.1,1-4.5,1.6-7.5,1.6L504.7,577.6L504.7,577.6z"/>
        <path className="st4" d="M548,578c-4.3,0-7.7-1.2-10-3.7s-3.5-6-3.5-10.5v-33h6.3V564c0,2.5,0.6,4.4,1.9,5.7c1.2,1.3,3,2,5.3,2
          s4.1-0.7,5.3-2s1.9-3.2,1.9-5.7v-33.2h6.3v33c0,4.5-1.2,8-3.5,10.5S552.4,578,548,578L548,578z"/>
        <path className="st4" d="M578.5,577.6l-12.3-46.7h6.8l8.7,36.2l8.7-36.2h6.8l-12.3,46.7H578.5L578.5,577.6z"/>
        <path className="st4" d="M608.9,530.8v46.7h-6.3v-46.7H608.9L608.9,530.8z"/>
      </g>
    </>,
    title: "Resposabilidad Social Empresarial",
    content: <>
			<p className="mb-2">La Responsabilidad Social Empresarial (RSE) es un enfoque de trabajo desde la innovación social donde las empresas asumen la responsabilidad de sus impactos al interior de la vida de sus colaboradores, en la sociedad y en el medio ambiente.</p>
      <p className="mb-2">La RSE radica en que las empresas y organizaciones han evolucionado al punto de reconocer que es necesario retribuir a la sociedad con acciones que van más allá de brindar bienes y servicios de forma eficiente y eficaz.</p>
      <p className="mb-2">Desde la Fundación Arduvi, creemos en la responsabilidad social empresarial como eje fundamental del desarrollo organizacional. Nuestro modelo Arduvi -Inclusión con Amor- permite contribuir a las empresas y organizaciones en el mejoramiento y la transformación de la sociedad y grupos comunitarios en los que desarrollan sus ejercicios económicos, superando el cumplimiento de lo establecido en el marco normativo vigente.</p>
      <p className="mb-2">Por ello, se ha diseñado una apuesta estratégica de RSE para apoyar, acompañar, asesorar y vincular a las empresas que desean potenciar su nivel de reputación y reconocimiento social. Este ejercicio, desde nuestra línea estratégica del <strong>“Liderazgo Transformacional”</strong>, apunta a un posicionamiento de éste a nivel interior de las empresas y organizaciones, partiendo de la conciencia del talento humano, fortalecimiento de su bienestar individual y familiar, así como retribuyendo a la comunidad por todo lo que les ha brindado, permitiendo su crecimiento.</p>
      <p className="mb-2">Involucra, al mismo tiempo, el aumento del bienestar humano de las y los colaboradores y, el acompañamiento para la apertura a la vinculación de personas con capacidades especiales (PcD) y otras poblaciones vulnerables, a través de la implementación de cinco (05) grandes apuestas transformadoras.</p>
      <p>Es importante destacar que las apuestas estratégicas que a continuación se reseñan deben ser implementadas de manera integral y continua, con el objetivo de generar un cambio real y sostenible en la inclusión de personas con discapacidad y poblaciones vulnerables en la empresa y en la sociedad en general.</p>
		</>,
  },
  {
    button: <>
      <g className="P">
        <path className="st5" d="M406.8,619.1c-56.4,56-112.7,112-168.6,168.4c-6.5,6.6-9.7,4.9-14.9-0.8c-15.8-17.4-30.6-35.6-43.1-55.6
          c-4.2-6.8-8.1-9.8-17-8.6c-30.9,4-58.8-12.8-69.8-40.5c-11.4-28.7-2.4-59.4,23.8-78.2c6.1-4.4,7-8.1,5.5-14.8
          c-4.3-19.2-5.9-38.8-7.4-58.4c-0.6-8,1.7-10.2,9.6-10.1c40.1,0.4,80.2,0.2,120.3,0.2s80.2,0.2,120.3-0.2c7.5-0.1,10.4,1.7,11.1,9.5
          c2.5,28.4,13.2,53.8,31.4,75.7C412.9,611.6,411.5,614.5,406.8,619.1L406.8,619.1z"/>
        <g>
          <path className="st6" d="M124.2,709.1v-90.7h15.5v90.7H124.2z M131.5,673.9v-14.8h16c1.9,0,3.4-0.7,4.5-2s1.6-3.1,1.6-5.5V641
            c0-2.5-0.5-4.4-1.6-5.7s-2.6-2-4.5-2h-16v-14.8H148c4.5,0,8.4,0.8,11.5,2.4c3.2,1.6,5.6,3.9,7.3,6.9s2.5,6.7,2.5,11V654
            c0,4.2-0.8,7.8-2.5,10.8s-4.1,5.2-7.3,6.8s-7,2.3-11.5,2.3L131.5,673.9L131.5,673.9z"/>
          <path className="st7" d="M121.6,706.4v-90.7H137v90.7H121.6z M128.8,671.2v-14.8h16c1.9,0,3.4-0.7,4.5-2s1.6-3.1,1.6-5.5v-10.7
            c0-2.5-0.5-4.4-1.6-5.7s-2.6-2-4.5-2h-16v-14.8h16.5c4.5,0,8.4,0.8,11.5,2.4c3.2,1.6,5.6,3.9,7.3,6.9s2.5,6.7,2.5,11v15.3
            c0,4.2-0.8,7.8-2.5,10.8s-4.1,5.2-7.3,6.8s-7,2.3-11.5,2.3H128.8L128.8,671.2z"/>
        </g>
        <g>
          <path className="st7" d="M185.1,585.8v-24.7h4.2v24.7H185.1z M187.1,576.2v-4h4.4c0.5,0,0.9-0.2,1.2-0.5c0.3-0.4,0.4-0.8,0.4-1.5v-2.9
            c0-0.7-0.1-1.2-0.4-1.5c-0.3-0.4-0.7-0.5-1.2-0.5h-4.4v-4h4.5c1.2,0,2.3,0.2,3.1,0.6s1.5,1.1,2,1.9c0.5,0.8,0.7,1.8,0.7,3v4.2
            c0,1.1-0.2,2.1-0.7,2.9s-1.1,1.4-2,1.8c-0.9,0.4-1.9,0.6-3.1,0.6L187.1,576.2L187.1,576.2z"/>
          <path className="st7" d="M204.7,561.1v19.3c0,0.4,0.1,0.7,0.3,0.9s0.5,0.3,0.8,0.3h0.5v4.1h-1.5c-1.4,0-2.4-0.4-3.2-1.2
            s-1.1-2-1.1-3.5v-20L204.7,561.1L204.7,561.1z"/>
          <path className="st7" d="M212.1,586.1c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3s2.4-1.5,4.3-1.5h1.3l0.2,3.3h-1.3
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.2s0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.4-0.5s0.5-0.8,0.5-1.4l0.3,1.5
            c0,0.8-0.1,1.5-0.4,2.1s-0.6,1-1.1,1.3S212.8,586.1,212.1,586.1L212.1,586.1z M215.1,585.8v-12.6c0-0.5-0.1-0.8-0.4-1.1
            s-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.1h-4c0-1.7,0.5-3,1.4-3.9s2.2-1.4,3.9-1.4s3,0.5,4,1.4
            c0.9,0.9,1.4,2.2,1.4,3.9v12.6H215.1L215.1,585.8z"/>
          <path className="st7" d="M222.5,585.8v-17.6h4.2v17.6H222.5z M229.4,585.8v-12.4c0-0.5-0.1-1-0.3-1.3s-0.6-0.4-1-0.4s-0.8,0.1-1,0.4
            c-0.2,0.3-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-2,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2s0.9,1.9,0.9,3.3v13.5
            L229.4,585.8L229.4,585.8z"/>
          <path className="st7" d="M242.6,585.8l5.9-24.7h2.9l5.9,24.7h-4.2l-3.2-16.2l-3.2,16.2H242.6L242.6,585.8z M246.6,582v-4h6.7v3.9
            L246.6,582L246.6,582z"/>
          <path className="st7" d="M259.4,593v-24.8h4.2V593H259.4z M266.8,586.1c-0.8,0-1.5-0.3-2.1-0.8s-1-1.3-1.3-2.2l0.2-2.5
            c0,0.4,0,0.7,0.2,0.9s0.3,0.5,0.5,0.6s0.5,0.2,0.7,0.2c0.4,0,0.7-0.2,1-0.5s0.3-0.7,0.3-1.3v-7.1c0-0.5-0.1-0.9-0.3-1.2
            c-0.2-0.3-0.5-0.4-1-0.4c-0.3,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.5-0.2,0.9l-0.4-2.2c0.4-1,0.9-1.8,1.5-2.4
            s1.3-0.8,2.1-0.8c1.1,0,2,0.4,2.6,1.3s0.9,2,0.9,3.6v8.4c0,1.6-0.3,2.8-0.9,3.7S267.9,586,266.8,586.1L266.8,586.1z"/>
          <path className="st7" d="M277.1,586.1c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3s2.4-1.5,4.3-1.5h1.3l0.2,3.3h-1.3
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.2s0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.4-0.5s0.5-0.8,0.5-1.4l0.3,1.5
            c0,0.8-0.1,1.5-0.4,2.1s-0.6,1-1.1,1.3S277.8,586.1,277.1,586.1L277.1,586.1z M280.2,585.8v-12.6c0-0.5-0.1-0.8-0.4-1.1
            s-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.1h-4c0-1.7,0.5-3,1.4-3.9s2.2-1.4,3.9-1.4s3,0.5,4,1.4
            c0.9,0.9,1.4,2.2,1.4,3.9v12.6H280.2L280.2,585.8z"/>
          <path className="st7" d="M290.6,586.1c-1.1,0-2-0.4-2.5-1.3c-0.6-0.8-0.9-2-0.9-3.5v-8.6c0-1.5,0.3-2.7,0.9-3.5s1.5-1.2,2.5-1.2
            c0.8,0,1.5,0.3,2.1,0.8s1.2,1.3,1.5,2.3l-0.4,2.2c0-0.3,0-0.6-0.2-0.9c-0.1-0.2-0.3-0.4-0.5-0.5s-0.4-0.2-0.7-0.2
            c-0.4,0-0.8,0.1-1,0.4c-0.2,0.3-0.4,0.7-0.4,1.2v7.4c0,0.5,0.1,0.9,0.4,1.2s0.6,0.4,1,0.4c0.3,0,0.5-0.1,0.7-0.2s0.3-0.3,0.5-0.5
            c0.1-0.2,0.2-0.5,0.2-0.9l0.2,2.4c-0.3,1-0.8,1.7-1.4,2.2C292.2,585.8,291.4,586.1,290.6,586.1L290.6,586.1z M293.9,585.8v-24.7
            h4.2v24.7H293.9z"/>
          <path className="st7" d="M301.5,585.8v-17.6h4.2v17.6H301.5z M309.9,572.3h-1.1c-1,0-1.8,0.3-2.3,0.9c-0.5,0.6-0.8,1.4-0.8,2.4
            l-0.4-4.4c0.5-1,1.1-1.9,1.9-2.5s1.7-0.9,2.6-0.9L309.9,572.3L309.9,572.3z"/>
          <path className="st7" d="M312,565.3v-4.2h4.2v4.2H312z M312,585.8v-17.6h4.2v17.6H312z"/>
          <path className="st7" d="M319.2,585.8v-17.6h4.2v17.6H319.2z M326.2,585.8v-12.4c0-0.5-0.1-1-0.3-1.3c-0.2-0.3-0.6-0.4-1-0.4
            s-0.8,0.1-1,0.4c-0.2,0.3-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-2,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2s0.9,1.9,0.9,3.3
            v13.5L326.2,585.8L326.2,585.8z"/>
          <path className="st7" d="M338.6,586.1c-1.7,0-3-0.4-3.9-1.3s-1.4-2-1.4-3.5v-8.7c0-1.5,0.5-2.7,1.4-3.5s2.2-1.2,3.9-1.2s3,0.4,3.9,1.2
            s1.4,2,1.4,3.5v8.7c0,1.5-0.5,2.7-1.4,3.5S340.3,586.1,338.6,586.1L338.6,586.1z M338.6,582.3c0.4,0,0.6-0.1,0.8-0.3
            c0.2-0.2,0.3-0.5,0.3-1v-8c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.9v8
            c0,0.4,0.1,0.8,0.3,1S338.3,582.3,338.6,582.3z"/>
          <path className="st7" d="M216.8,618.4c-1.8,0-3.1-0.4-4.1-1.3c-1-0.8-1.4-2-1.4-3.6v-8.2c0-1.6,0.5-2.9,1.4-3.8s2.3-1.3,4.1-1.3
            s3.1,0.5,4.1,1.3c1,0.9,1.5,2.2,1.5,3.8v1.5h-4.1v-1.2c0-0.5-0.1-1-0.4-1.3s-0.6-0.5-1-0.5s-0.8,0.2-1,0.4s-0.4,0.7-0.4,1.2v7.5
            c0,0.5,0.1,0.8,0.4,1s0.6,0.4,1,0.4s0.8-0.1,1-0.4s0.4-0.6,0.4-1.1V612h4.2v1.4c0,1.5-0.5,2.8-1.5,3.6
            C219.9,617.9,218.6,618.4,216.8,618.4L216.8,618.4z"/>
          <path className="st7" d="M229.6,618.4c-1.7,0-3-0.4-3.9-1.3s-1.4-2-1.4-3.5v-8.7c0-1.5,0.5-2.7,1.4-3.5s2.2-1.2,3.9-1.2s3,0.4,3.9,1.2
            s1.4,2,1.4,3.5v8.7c0,1.5-0.5,2.7-1.4,3.5S231.3,618.4,229.6,618.4L229.6,618.4z M229.6,614.6c0.4,0,0.6-0.1,0.8-0.3
            s0.3-0.5,0.3-1v-8c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.9v8c0,0.4,0.1,0.8,0.3,1
            S229.3,614.6,229.6,614.6z"/>
          <path className="st7" d="M237.9,618.1v-17.6h4.2v17.6H237.9z M244.8,618.1v-12.4c0-0.5-0.1-1-0.3-1.3s-0.6-0.4-1-0.4s-0.8,0.1-1,0.4
            c-0.2,0.3-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-2,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2s0.9,1.9,0.9,3.3v13.5
            L244.8,618.1L244.8,618.1z"/>
          <path className="st7" d="M258,618.1l5.9-24.7h3l5.9,24.7h-4.2l-3.2-16.2l-3.2,16.2H258L258,618.1z M262,614.3v-3.9h6.7v3.9H262z"/>
          <path className="st7" d="M274.8,618.1v-17.6h4.3v17.6H274.8z M281.8,618.1v-12.4c0-0.5-0.1-1-0.4-1.3c-0.2-0.3-0.6-0.4-1.1-0.4
            s-0.8,0.1-1,0.4c-0.2,0.3-0.4,0.7-0.4,1.3l-0.4-2.2c0.4-1.1,0.9-1.9,1.6-2.4s1.5-0.8,2.3-0.8c1.1,0,2,0.3,2.6,1.1s0.9,1.7,0.9,3
            v13.8L281.8,618.1L281.8,618.1z M288.9,618.1v-12.4c0-0.5-0.1-1-0.4-1.3c-0.2-0.3-0.6-0.4-1-0.4s-0.8,0.1-1.1,0.4
            c-0.2,0.3-0.4,0.7-0.4,1.3l-0.3-2.3c0.4-1.1,0.9-1.9,1.6-2.4s1.5-0.8,2.3-0.8c1.1,0,2,0.4,2.6,1.2s0.9,1.9,0.9,3.3v13.5
            L288.9,618.1L288.9,618.1z"/>
          <path className="st7" d="M301.5,618.4c-1.7,0-3-0.4-3.9-1.3s-1.4-2-1.4-3.5v-8.7c0-1.5,0.5-2.7,1.4-3.5s2.2-1.2,3.9-1.2s3,0.4,3.9,1.2
            s1.4,2,1.4,3.5v8.7c0,1.5-0.5,2.7-1.4,3.5S303.1,618.4,301.5,618.4L301.5,618.4z M301.5,614.6c0.4,0,0.6-0.1,0.8-0.3
            c0.2-0.2,0.3-0.5,0.3-1v-8c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.9v8
            c0,0.4,0.1,0.8,0.3,1C300.8,614.5,301.1,614.6,301.5,614.6z"/>
          <path className="st7" d="M309.7,618.1v-17.6h4.2v17.6H309.7z M318.1,604.7H317c-1,0-1.8,0.3-2.3,0.9c-0.5,0.6-0.8,1.4-0.8,2.4
            l-0.4-4.4c0.5-1,1.1-1.9,1.9-2.5s1.7-0.9,2.6-0.9L318.1,604.7L318.1,604.7z"/>
        </g>
        <path d="M256.8,706.9c4.9,0.3,9,2,12.2,5.9c1.9,2.3,3.2,5.4,3.3,8c-5.3,0.7-10.5,1.3-15.9,2c0-1,0.1-2,0-3
          c-0.4-2.6-2.4-4.5-5.1-4.9c-3.4-0.6-6.8-1.1-10.4-1.8c3.3-3.9,7.3-5.9,12.3-6.2c0-1.9,0-3.8,0-5.6c0-2.4,0-4.8,0-7.2
          c0-1-0.2-1.1-1.2-0.9c-4.3,0.8-8.6,1.1-13,0c-5.2-1.3-8.6-4.6-10.9-9.2c-1.7-3.5-2.6-7.2-3-11c-0.1-1.1,0.3-1.9,1.3-2.1
          c5.4-1.3,10.7-1.7,16.1,0.1c4.8,1.6,7.7,5.1,9.7,9.5c1.2,2.7,2,5.6,2.4,8.5c0,0.3,0.1,0.6,0.2,0.8c0.1,0,0.2,0,0.3,0
          c0.1-0.5,0.2-0.9,0.3-1.4c0.8-4.4,2.1-8.6,4.8-12.3c2.9-3.9,6.9-5.7,11.6-6.3c3.8-0.5,7.6-0.1,11.4,0.8c1.2,0.3,1.7,0.9,1.6,2
          c-0.6,5.4-1.9,10.6-5.3,15c-2.8,3.7-6.6,5.4-11.1,6c-3.5,0.4-6.9,0.2-10.4-0.5c-1-0.2-1.2-0.1-1.2,0.8c0,4.1,0,8.3,0,12.4
          C256.7,706.6,256.8,706.7,256.8,706.9z"/>
        <path d="M213.9,729.2c1.3-7.7,2.5-15.3,3.8-23c3.5,0.4,6.8,1,9.7,2.9c1.1,0.7,2.1,1.5,3.1,2.4c3.5,3.2,7.5,5,12.2,5.6
          c2.6,0.4,5.2,0.9,7.8,1.3c1.6,0.3,2.5,1.6,2.2,3.3c-0.3,1.5-1.6,2.4-3.2,2.1c-5.4-0.9-10.7-1.8-16.1-2.7c-1.3-0.2-2.2,0.4-2.4,1.4
          c-0.2,1.1,0.4,2,1.8,2.2c5.5,0.9,11,1.9,16.4,2.7c0.8,0.1,1.6,0,2.4-0.1c2.1-0.3,4.1-0.8,6.2-1.1c6.3-0.8,12.7-1.6,19-2.4
          c2.5-0.3,4.2,0.6,4.6,2.8c0.5,2.3-0.8,4.4-3.2,4.9c-12.6,2.7-25.3,5.5-38,8.1c-4.8,1-9.3-0.3-13.2-3.3c-1.8-1.4-3.5-2.9-5.4-4.1
          C219.4,730.6,216.8,729.7,213.9,729.2z"/>
        <path d="M199.3,730.7c1.4-10.4,2.8-20.6,4.3-31c1.1,0.2,2.1,0.4,3.2,0.6c1.8,0.3,3.6,0.6,5.4,1c1.6,0.3,2.4,1.4,2.2,3.1
          c-1.4,8.3-2.8,16.7-4.2,25c-0.4,2.2-1.4,2.9-3.7,2.5C204.1,731.4,201.8,731.1,199.3,730.7z"/>
      </g>
    </>,
    title: 'Plan "Apadrino/Amadrino con Amor"',
    content: <>
			<p>A través de un ejercicio de donación mensual, trimestral, semestral o anual por un núcleo familiar, Como estrategia de transparencia y rendición de cuentas, previo permiso del núcleo(s) familiares, recibirá cartas de agradecimiento por los proceso que se han brindado gracias al apadrinamiento/amadrinamiento de los núcleos familiares.</p>
		</>,
    bg: "bg-[#B04535]",
    text: "text-white"
  },
  {
    button: <>
      <g className="H">
        <path className="st8" d="M406.3,424.1c-19.3,23.4-29.6,50.6-32.3,80.8c-79.1,0-158.2-0.1-237.2,0.4c-9.8,0-17.6-1-22-10.6
          c0-21,2.6-41.8,7.1-62.2c1.9-8.8,0.9-13.2-7.5-18.1c-28.9-16.8-39.9-50.9-27.5-81c12.6-30.5,45-47.1,77.6-38.2
          c8.8,2.4,12-0.3,16.1-6.7c11.4-17.6,24.5-33.9,38.3-49.6c5.3-6.1,8.4-5.7,14-0.1c57.2,57.5,114.6,114.8,172.2,172
          C409.7,415.2,411.2,418.2,406.3,424.1L406.3,424.1z"/>
        <g>
          <path className="st6" d="M126.4,406.8v-90.7h15.5v90.7H126.4z M133.7,369.4v-14.5h30.4v14.5H133.7z M154.1,406.8v-90.7h15.5v90.7
            H154.1z"/>
          <path className="st7" d="M123.7,404.1v-90.7h15.5v90.7C139.1,404.1,123.7,404.1,123.7,404.1z M131,366.6v-14.5h30.4v14.5H131z
            M151.4,404.1v-90.7h15.5v90.7L151.4,404.1L151.4,404.1z"/>
        </g>
        <g>
          <path className="st7" d="M175.8,479.8v-24.7h4.2v24.7H175.8z M177.8,469.6v-4h8.3v4H177.8z M183.3,479.8v-24.7h4.2v24.7H183.3z"/>
          <path className="st7" d="M194.5,480c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3s2.4-1.5,4.3-1.5h1.3l0.2,3.3h-1.3
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.1s0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.4-0.5s0.5-0.8,0.5-1.4l0.3,1.5
            c0,0.8-0.1,1.5-0.4,2.1c-0.3,0.6-0.6,1-1.1,1.3C195.8,479.9,195.2,480.1,194.5,480L194.5,480z M197.5,479.8v-12.6
            c0-0.5-0.1-0.8-0.4-1.1s-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.1h-4c0-1.7,0.5-3,1.4-3.9s2.2-1.4,3.9-1.4
            s3,0.5,4,1.4c0.9,0.9,1.4,2.2,1.4,3.9v12.6H197.5L197.5,479.8z"/>
          <path className="st7" d="M204.9,479.8v-17.6h4.2v17.6H204.9z M211.8,479.8v-12.4c0-0.5-0.1-1-0.3-1.3s-0.6-0.4-1-0.4s-0.8,0.1-1,0.4
            s-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-2,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2s0.9,1.9,0.9,3.3v13.5L211.8,479.8
            L211.8,479.8z"/>
          <path className="st7" d="M222.5,480.1c-1.1,0-1.9-0.4-2.6-1.3s-0.9-2-0.9-3.5v-8.6c0-1.5,0.3-2.7,0.9-3.5s1.5-1.2,2.6-1.2
            c0.8,0,1.5,0.3,2.1,0.8c0.6,0.6,1.2,1.3,1.6,2.4l-0.4,2.2c0-0.4-0.1-0.6-0.2-0.9c-0.1-0.2-0.3-0.4-0.4-0.5s-0.4-0.2-0.7-0.2
            c-0.4,0-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.2v7.4c0,0.5,0.1,0.9,0.4,1.1s0.6,0.4,1,0.4c0.3,0,0.5-0.1,0.7-0.2s0.3-0.3,0.4-0.5
            c0.1-0.2,0.2-0.5,0.2-0.9l0.2,2.4c-0.3,1-0.8,1.7-1.4,2.2C224,479.8,223.3,480.1,222.5,480.1L222.5,480.1z M225.8,479.8v-24.7h4.2
            v24.7H225.8z"/>
          <path className="st7" d="M239,466v-3.9h8v3.9H239z M245.1,479.9c-1.6,0-2.8-0.4-3.5-1.2c-0.7-0.8-1.1-2-1.1-3.5V457h4.2v17.5
            c0,0.4,0.1,0.7,0.3,0.9s0.4,0.3,0.8,0.3h1.2v4.1L245.1,479.9L245.1,479.9z"/>
          <path className="st7" d="M254.4,480c-1.7,0-3-0.4-3.9-1.3s-1.4-2-1.4-3.5v-8.6c0-1.5,0.5-2.7,1.4-3.5s2.2-1.2,3.9-1.2
            c1.7,0,3,0.4,3.9,1.2s1.4,2,1.4,3.5v8.7c0,1.5-0.5,2.7-1.4,3.5S256.1,480.1,254.4,480L254.4,480z M254.4,476.3
            c0.4,0,0.6-0.1,0.8-0.3s0.3-0.5,0.3-1v-8c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.9v8
            c0,0.4,0.1,0.8,0.3,1C253.8,476.2,254.1,476.3,254.4,476.3z"/>
          <path className="st7" d="M269.5,479.8v-24.7h4.2v24.7H269.5z M271.5,469.6v-4h8.3v4H271.5z M277,479.8v-24.7h4.2v24.7H277z"/>
          <path className="st7" d="M288.2,480c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3s2.4-1.5,4.3-1.5h1.3l0.2,3.3h-1.3
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.1s0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.4-0.5s0.5-0.8,0.5-1.4l0.3,1.5
            c0,0.8-0.1,1.5-0.4,2.1s-0.6,1-1.1,1.3C289.5,479.9,288.9,480.1,288.2,480L288.2,480z M291.2,479.8v-12.6c0-0.5-0.1-0.8-0.4-1.1
            s-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.1h-4c0-1.7,0.5-3,1.4-3.9s2.2-1.4,3.9-1.4s3,0.5,4,1.4
            c0.9,0.9,1.4,2.2,1.4,3.9v12.6H291.2L291.2,479.8z"/>
          <path className="st7" d="M298.6,479.8v-17.6h4.2v17.6H298.6z M305.5,479.8v-12.4c0-0.5-0.1-1-0.3-1.3c-0.2-0.3-0.6-0.4-1-0.4
            s-0.8,0.1-1,0.4s-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-2,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2c0.6,0.8,0.9,1.9,0.9,3.3
            v13.5L305.5,479.8L305.5,479.8z"/>
          <path className="st7" d="M316.1,480.1c-1.1,0-2-0.4-2.5-1.3c-0.6-0.8-0.9-2-0.9-3.5v-8.6c0-1.5,0.3-2.7,0.9-3.5s1.5-1.2,2.5-1.2
            c0.8,0,1.5,0.3,2.1,0.8s1.2,1.3,1.5,2.4l-0.4,2.2c0-0.4,0-0.6-0.2-0.9c-0.1-0.2-0.3-0.4-0.5-0.5s-0.4-0.2-0.7-0.2
            c-0.4,0-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.2v7.4c0,0.5,0.1,0.9,0.4,1.1s0.6,0.4,1,0.4c0.3,0,0.5-0.1,0.7-0.2s0.3-0.3,0.5-0.5
            c0.1-0.2,0.2-0.5,0.2-0.9l0.2,2.4c-0.3,1-0.8,1.7-1.4,2.2C317.7,479.8,317,480.1,316.1,480.1L316.1,480.1z M319.5,479.8v-24.7h4.2
            v24.7H319.5z"/>
        </g>
        <path d="M253.2,355c2.9,2,3.4,4.9,1.5,8.9c1.4,0.6,2.6,1.4,3.3,2.7c0.5,0.9,0.8,2,0.9,3c0.2,1.4,0.1,2.8,0,4.2
          c0,1.2-0.9,2.2-2.1,2.2c-4.6,0-9.2,0-13.8,0c-1.2,0-2.1-0.9-2.1-2c-0.1-1.6-0.1-3.2,0.1-4.8c0.2-2.4,1.6-4.1,3.8-5.1
          c0.1-0.1,0.3-0.1,0.4-0.2c-0.3-0.6-0.7-1.2-0.9-1.8c-0.7-2.3-0.3-4.4,1.4-6.2c0.7-0.8,1.5-0.9,2.1-0.3s0.5,1.3-0.2,2
          c-0.9,0.9-1.3,2-1,3.3c0.3,1.4,1.5,2.5,2.8,2.7c1.5,0.2,2.9-0.5,3.6-1.9c0.7-1.3,0.4-2.9-0.6-3.9c-0.1-0.1-0.1-0.1-0.2-0.2
          c-1-1-0.9-1.7,0.3-2.5C252.8,355,253,355,253.2,355z M256.4,373.5c0-1.2,0-2.3,0-3.4c0-2.3-1.5-3.9-3.8-4c-1.8-0.1-3.6-0.1-5.3,0
          c-1.8,0.1-3.3,1.1-3.6,2.8c-0.3,1.5-0.2,3-0.3,4.6c0.6,0,0.9,0,1.4,0c0-0.4,0-0.8,0-1.1c0-0.9,0-1.8,0-2.6c0.1-0.7,0.5-1.2,1.3-1.2
          c0.7,0,1.2,0.5,1.3,1.2c0,0.4,0,0.8,0,1.3c0,0.8,0,1.7,0,2.5c1.8,0,3.5,0,5.2,0c0-1.3,0-2.4,0-3.6c0-0.9,0.8-1.5,1.6-1.3
          c0.7,0.2,1,0.7,1,1.4c0,1.2,0,2.4,0,3.6C255.6,373.5,255.9,373.5,256.4,373.5z"/>
        <path d="M264.2,388.2c0.8-0.4,1.4-0.8,2.1-1.2c0.8-0.4,1.5-0.3,1.9,0.4c0.4,0.7,0.2,1.4-0.6,1.8c-0.7,0.4-1.4,0.8-2,1.2
          c0.4,1.3,0.8,2.5,1.2,3.7c0.3,1.1,0,1.8-0.8,2.1c-0.8,0.2-1.4-0.3-1.7-1.5c-0.9-3.8-2.9-6.8-6.1-8.9c-8-5.4-18.8-1.9-22.1,7.2
          c-3.6,9.8,4.2,20.3,14.5,19.7c6.6-0.4,12.1-4.9,13.6-11.4c0.1-0.2,0.1-0.4,0.2-0.7c0.3-0.7,0.9-1.1,1.5-0.9
          c0.7,0.2,1.1,0.8,0.9,1.6c-0.3,1.2-0.7,2.3-1.1,3.5c-0.1,0.2-0.2,0.4-0.3,0.7c0.7,0.4,1.4,0.8,2,1.2c0.9,0.5,1.1,1.2,0.7,1.9
          c-0.4,0.7-1.1,0.8-2,0.3c-0.6-0.4-1.3-0.7-1.9-1.1c0,0-0.1,0-0.2,0c-1.5,2.1-3.4,3.9-5.6,5.2c-2.3,1.3-4.7,2-7.3,2.2
          c0,0.9,0,1.7,0,2.6s-0.5,1.4-1.3,1.4c-0.7,0-1.3-0.5-1.3-1.4c0-0.8,0-1.6,0-2.5c-5.4-0.6-9.8-3-13-7.5c-0.7,0.4-1.4,0.8-2.1,1.2
          c-0.9,0.5-1.6,0.4-2-0.3c-0.4-0.7-0.1-1.4,0.7-1.9c0.6-0.4,1.3-0.8,2-1.2c-0.1-0.3-0.2-0.6-0.3-0.8c-1.8-4.5-1.8-9,0-13.4
          c0.3-0.6,0.2-1-0.4-1.2c-0.5-0.2-1-0.6-1.5-0.9c-0.7-0.4-0.8-1.2-0.5-1.8c0.4-0.6,1.1-0.8,1.8-0.4c0.8,0.4,1.5,0.8,2.3,1.3
          c3.2-4.4,7.5-6.9,13-7.5c0-0.8,0-1.6,0-2.4c0-1,0.5-1.6,1.3-1.6s1.2,0.6,1.3,1.6c0,0.7,0,1.4,0,2.1c0,0.1,0,0.1,0.1,0.3
          C256.7,381.2,261,383.7,264.2,388.2z"/>
        <path d="M254.7,395c3.1,1.3,4.4,3.7,4.3,6.9c0,1,0,1.9,0,2.9c0,0.7,0,1.4,0,2c0,1.3-0.9,2.2-2.2,2.2c-4.6,0-9.2,0-13.8,0
          c-1.2,0-2.1-0.9-2.2-2.2c0-2-0.1-4.1,0-6.1c0.1-2.8,1.7-4.7,4.3-5.8c-1.2-1.7-1.6-3.5-1-5.5c0.4-1.4,1.2-2.4,2.3-3.2
          c2.3-1.6,5.4-1.4,7.5,0.5C256.3,388.8,256.5,391.5,254.7,395z M252.5,406.5c0-0.4,0-0.7,0-1c0-1.5,0-3,0-4.5c0-0.9,0.5-1.4,1.3-1.4
          c0.7,0,1.3,0.5,1.3,1.4s0,1.9,0,2.8s0,1.8,0,2.7c0.5,0,0.9,0,1.3,0c0-2,0.1-4,0-6c-0.1-1.8-1.7-3.2-3.5-3.2c-2-0.1-4-0.1-5.9,0
          c-1.7,0-3.2,1.3-3.4,2.9c-0.2,2.1,0,4.2,0,6.3c0.4,0,0.7,0,1.2,0c0-0.3,0-0.6,0-0.9c0-1.5,0-2.9,0-4.4c0-1,0.5-1.5,1.3-1.5
          s1.2,0.5,1.2,1.5c0,1.5,0,3,0,4.5c0,0.3,0,0.6,0.1,0.9C249.1,406.5,250.8,406.5,252.5,406.5z M249.9,387.7c-1.9,0-3.4,1.6-3.4,3.5
          s1.6,3.5,3.5,3.5s3.5-1.6,3.5-3.5C253.4,389.2,251.8,387.7,249.9,387.7z"/>
        <path d="M273.9,380.9c-1.8-3.4-1.5-6.3,0.9-8.3c2.2-1.8,5.3-1.9,7.5-0.3c2.7,2.1,3,4.8,1.2,8.6c1.8,0.7,3.2,1.9,3.8,3.7
          c0.3,0.8,0.4,1.8,0.4,2.6c0.1,1.1,0,2.2,0,3.3c0,1.7-0.8,2.5-2.5,2.5c-2.7,0-5.3,0-8,0c-1.7,0-3.4,0-5.2,0c-1.6,0-2.5-0.9-2.5-2.5
          c0-1.3,0-2.6,0-3.9c0.2-2.7,1.5-4.5,4-5.6C273.7,381,273.8,381,273.9,380.9z M273.5,390.5c0-0.5,0-0.9,0-1.3c0-0.9,0-1.8,0.1-2.6
          c0.1-0.7,0.5-1.1,1.2-1.1s1.1,0.4,1.2,1c0.1,0.3,0.1,0.7,0.1,1c0,1,0,1.9,0,2.9c1.8,0,3.4,0,5.2,0c0-1.1,0-2.2,0-3.3
          c0-1.1,0.5-1.6,1.3-1.6s1.3,0.6,1.3,1.7s0,2.2,0,3.3c0.5,0,0.9,0,1.3,0c0-1.3,0-2.5,0-3.7c-0.1-2-1.6-3.6-3.6-3.6
          c-1.9-0.1-3.9-0.1-5.8,0c-1.5,0-3,1.1-3.3,2.6s-0.2,3.1-0.3,4.7C272.6,390.5,273,390.5,273.5,390.5z M278.6,373.7
          c-1.9,0-3.4,1.5-3.5,3.4c0,1.9,1.5,3.5,3.4,3.5s3.5-1.6,3.5-3.5C282.1,375.2,280.5,373.7,278.6,373.7z"/>
        <path d="M216.4,380.9c-1.9-3.8-1.5-6.5,1.2-8.6c2.2-1.7,5.3-1.6,7.5,0.2c2.4,2,2.7,4.9,0.9,8.4c1.4,0.6,2.6,1.4,3.3,2.7
          c0.4,0.8,0.8,1.8,0.9,2.7c0.2,1.6,0.1,3.2,0.1,4.7c0,1-0.9,1.8-1.9,2c-0.4,0-0.7,0-1.1,0c-4.1,0-8.3,0-12.4,0c-2,0-2.8-0.8-2.8-2.8
          c0-1,0-2.1,0-3.1c0-2.8,1.3-4.9,3.9-6.1C216.2,381,216.3,381,216.4,380.9z M227.7,390.5c0-1.3,0.1-2.5,0-3.7
          c-0.2-2.1-1.7-3.5-3.8-3.6c-1.8,0-3.7,0-5.5,0s-3.3,1.2-3.5,3c-0.2,1.4,0,2.9,0,4.3c0.4,0,0.8,0,1.2,0c0-1.1,0-2.2,0-3.3
          c0-1.1,0.5-1.6,1.3-1.6s1.3,0.6,1.3,1.6c0,1.1,0,2.2,0,3.3c1.8,0,3.5,0,5.2,0c0-1.3,0-2.4,0-3.6c0-1,1-1.7,1.8-1.2
          c0.4,0.3,0.7,0.9,0.7,1.4c0.1,1.1,0,2.2,0,3.4C226.9,390.5,227.3,390.5,227.7,390.5z M224.7,377.1c0-1.9-1.6-3.4-3.5-3.4
          s-3.4,1.6-3.5,3.4c0,1.9,1.6,3.5,3.5,3.5C223.2,380.5,224.7,378.9,224.7,377.1z"/>
        <path d="M273.9,410.9c-2.4-3.5-1-6.9,0.9-8.5c2.3-1.9,5.5-1.8,7.8,0.2c2,1.7,2.9,5.1,0.7,8.2c0.2,0.1,0.3,0.2,0.5,0.3
          c2.4,1.1,3.7,3,3.8,5.7c0.1,1.4,0,2.8,0,4.2c0,1.2-0.9,2.1-2.2,2.1c-4.6,0-9.2,0-13.7,0c-1.1,0-2.1-0.8-2.2-1.9
          c-0.1-1.9-0.1-3.8,0.2-5.7C270.1,413.2,271.7,411.8,273.9,410.9z M285,420.5c0-0.1,0.1-0.2,0.1-0.3c0-1.2,0-2.3,0-3.5
          c-0.1-1.8-1.4-3.4-3.3-3.5c-2.1-0.1-4.3-0.1-6.4,0c-1.5,0.1-2.6,1-3,2.4c-0.3,1.6-0.3,3.2-0.4,4.8c0.6,0,1,0,1.4,0
          c0-1.2,0-2.3,0-3.4c0-1,0.5-1.6,1.3-1.6s1.3,0.6,1.3,1.6c0,0.5,0,1,0,1.4c0,0.6,0,1.3,0,1.9c1.8,0,3.4,0,5.1,0c0-1.1,0-2.2,0-3.3
          c0-0.3,0-0.8,0.2-1c0.4-0.3,0.9-0.7,1.3-0.7c0.7,0,1.1,0.5,1.1,1.2c0,1.2,0,2.5,0,3.7C284.3,420.5,284.7,420.5,285,420.5z
          M282.1,407.1c0-1.9-1.5-3.5-3.4-3.5s-3.5,1.6-3.5,3.5s1.6,3.4,3.5,3.4S282.1,409,282.1,407.1z"/>
        <path d="M245.1,429.7c-1.9-3.7-1.5-6.5,1.1-8.5c2.1-1.7,5.3-1.7,7.5,0c2.5,2.1,2.9,4.8,1,8.5c1.4,0.6,2.6,1.4,3.3,2.7
          c0.5,0.9,0.8,2,0.9,3c0.2,1.4,0.1,2.8,0,4.2c0,1.2-0.9,2.2-2.1,2.2c-4.6,0-9.2,0-13.8,0c-1.2,0-2.1-0.9-2.1-2
          c-0.1-1.6-0.1-3.2,0-4.7c0.2-2.4,1.6-4.2,3.8-5.2C244.9,429.8,245,429.7,245.1,429.7z M244.8,439.3c0-0.4,0-0.7,0-1
          c0-0.9,0-1.8,0-2.7c0.1-0.7,0.5-1.2,1.3-1.2c0.7,0,1.2,0.5,1.3,1.2c0,0.4,0,0.8,0,1.2c0,0.9,0,1.7,0,2.6c1.8,0,3.5,0,5.2,0
          c0-1.2,0-2.4,0-3.6c0-0.9,0.7-1.5,1.6-1.3c0.7,0.2,1,0.7,1,1.4c0,1.2,0,2.4,0,3.5c0.5,0,0.9,0,1.3,0c0-1.1,0-2.2,0-3.2
          c0-2.4-1.5-4-3.9-4.1c-1.7-0.1-3.5-0.1-5.2,0c-1.9,0.1-3.5,1.2-3.7,2.8c-0.2,1.5-0.2,3-0.2,4.5
          C243.9,439.3,244.2,439.3,244.8,439.3z M250,429.3c1.9,0,3.4-1.6,3.4-3.5s-1.6-3.5-3.5-3.5s-3.5,1.6-3.5,3.5
          C246.5,427.8,248.1,429.3,250,429.3z"/>
        <path d="M216.5,410.8c-1.9-3.2-1.6-6.2,0.7-8.2c2.2-1.9,5.4-2.1,7.6-0.4c2.7,2.1,3.1,4.9,1.3,8.7c0.1,0.1,0.3,0.2,0.4,0.2
          c2.5,1.2,3.7,3.1,3.8,5.8c0.1,1.3,0,2.6,0,3.9c0,1.4-0.9,2.3-2.3,2.3c-4.5,0-9,0-13.5,0c-1.4,0-2.3-0.9-2.3-2.3c0-1.2,0-2.4,0-3.6
          c0.1-2.8,1.3-4.8,3.9-6C216.2,411,216.3,410.9,216.5,410.8z M223.8,420.5c0-1.3-0.1-2.4,0-3.6c0-0.5,0.3-1.1,0.7-1.3
          c0.8-0.5,1.8,0.1,1.8,1.2c0,1.2,0,2.4,0,3.5c0.5,0,0.9,0,1.3,0c0-1.3,0.1-2.5,0-3.7c-0.1-2-1.5-3.4-3.5-3.6c-2.1-0.1-4.1-0.1-6.2,0
          c-1.6,0.1-2.8,1.1-3.1,2.7c-0.3,1.5-0.2,3-0.3,4.5c0.6,0,0.9,0,1.4,0c0-1.1,0-2.2,0-3.2c0-1.1,0.5-1.7,1.3-1.8
          c0.8,0,1.3,0.6,1.3,1.7c0,0.4,0,0.8,0,1.3c0,0.7,0,1.3,0,2c1.5,0,2.9,0,4.3,0C223.2,420.5,223.4,420.5,223.8,420.5z M221.2,410.5
          c1.9,0,3.5-1.5,3.5-3.4s-1.6-3.5-3.5-3.5s-3.4,1.6-3.5,3.5C217.8,408.9,219.3,410.5,221.2,410.5z"/>
      </g>
    </>,
    title: "Estrategia Hand to Hand (Mano a Mano)",
    content: <>
			<p>Por cada núcleo familiar que nos ayudes a garantizar su proceso de atención psicosocial, intervención en psicología clínica e inserción sociolaboral de nuestro proyecto <strong>inclusión con amor desde los territorios</strong>, la Fundación Arduvi a través de nuestra estrategia <strong>Hand to Hand</strong> brindará atención psicosocial integral a un (01) núcleo familiar de tus colaboradores. Este acompañamiento integral en salud mental es brindado por un periodo de tres meses, tiempo que dura el proceso de atención psicosocial e intervención en un núcleo familiar dentro de la estrategia de los territorios. Las atenciones en psicología clínica serán entre tres (03) a cinco (05) consultas y está dirigido a los miembros del núcleo familiar base que las requiera.</p>
		</>,
    bg: "bg-[#E6A03D]",
    text: "text-white"
  },
  {
    button: <>
      <g className="I">
        <path className="st9" d="M513,360.2c0,7.4-0.9,11.1-10,12.1c-28.6,3.2-54.6,13.5-76.4,32.8c-4.9,4.3-7.5,3.1-11.5-0.9
          c-58-58.7-115.9-117.3-174.2-175.6c-5.5-5.6-3-8.4,1.2-12.2c17.7-16.1,36.1-31.4,56.4-44.2c5.9-3.7,7.7-6.8,5.4-14.3
          c-10.8-34.3,4.8-68.4,36.4-81.9s67-0.5,83.4,31.6c4.3,8.4,9.2,8.4,16.4,6.8c20.5-4.3,41.3-6.2,62.1-7.6c8.8-0.6,10.8,2.7,10.7,10.8
          c-0.3,40.6-0.2,81.2-0.2,121.9S512.7,320,513,360.2L513,360.2z"/>
        <g>
          <path className="st6" d="M363.2,91.8v90.7h-15.5V91.8H363.2z"/>
          <path className="st7" d="M360.5,89v90.7H345V89C345.1,89,360.5,89,360.5,89z"/>
        </g>
        <path d="M464.8,329.7c-0.3,1.8-0.7,3.7-1,5.5c-1.6,8.8-3.1,17.6-4.7,26.4c-0.2,1.4-1.1,2.1-2.5,2c-10.3-0.1-20.6-0.3-30.8-0.3
          c-2.2,0-4.2-0.5-6.3-1.2c-1.8-0.6-3.6-1.2-5.4-1.7c-0.4-0.1-0.6-0.3-0.6-0.8c0-10,0-20.1,0-30.1c0-0.3,0.3-0.7,0.5-1
          c2.7-2.2,5.2-4.6,7.2-7.4c0.3-0.4,0.5-0.9,0.7-1.4c1.8-6.7,3.5-13.3,5.3-20c0.4-1.4,1.5-1.9,2.7-1.2c2.5,1.5,4.5,3.4,4.9,6.5
          c0.1,1,0.1,2.1-0.1,3c-0.8,4-1.8,7.9-2.7,11.9c-0.4,1.7,0.4,2.8,2.2,2.9c8.1,0.1,16.3,0.2,24.4,0.3c3.4,0,5.2,1.5,6.1,4.8l0.1,0.1
          C464.8,328.6,464.8,329.1,464.8,329.7z"/>
        <path d="M388.2,363.7c0-13.4,0-26.8,0-40.2c7,0,14.1,0,21.1,0c0,13.4,0,26.8,0,40.2C402.3,363.7,395.3,363.7,388.2,363.7z"/>
        <g>
          <path className="st7" d="M298.5,224.2l-4.5-24.7h4.5l2.4,17l2.4-17h4.5l-4.5,24.7H298.5z"/>
          <path className="st7" d="M309,203.7v-4.2h4.2v4.2H309z M309,224.2v-17.6h4.2v17.6H309z"/>
          <path className="st7" d="M316.2,224.2v-17.6h4.2v17.6H316.2z M323.1,224.2v-12.4c0-0.6-0.1-1-0.3-1.3c-0.2-0.3-0.6-0.4-1-0.4
            s-0.8,0.1-1,0.4c-0.2,0.3-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-1.9,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2
            c0.6,0.8,0.9,1.9,0.9,3.3v13.5H323.1z"/>
          <path className="st7" d="M335.8,224.5c-1.8,0-3.1-0.4-4.1-1.3c-1-0.8-1.5-2-1.5-3.6v-8.2c0-1.6,0.5-2.9,1.5-3.8s2.3-1.3,4.1-1.3
            c1.8,0,3.1,0.4,4.1,1.3c1,0.9,1.5,2.2,1.5,3.8v1.5h-4.1v-1.2c0-0.6-0.1-1-0.4-1.3c-0.2-0.3-0.6-0.5-1-0.5c-0.4,0-0.7,0.1-1,0.4
            c-0.2,0.3-0.4,0.7-0.4,1.2v7.5c0,0.5,0.1,0.8,0.4,1.1c0.2,0.2,0.6,0.4,1,0.4c0.4,0,0.8-0.1,1-0.4c0.2-0.3,0.3-0.6,0.3-1.1v-1.1
            l4.2,0.1v1.4c0,1.6-0.5,2.8-1.5,3.6C339,224,337.6,224.5,335.8,224.5z"/>
          <path className="st7" d="M347.5,206.6v13.1c0,0.5,0.1,0.8,0.3,1s0.5,0.3,1,0.3c0.4,0,0.7-0.1,0.9-0.4s0.3-0.6,0.3-1l0.2,1.7
            c-0.3,1-0.7,1.8-1.2,2.3c-0.5,0.5-1.2,0.8-1.9,0.8c-1.3,0-2.2-0.4-2.8-1.2c-0.6-0.8-0.9-2-0.9-3.6v-13.1H347.5z M350,224.2v-17.6
            h4.2v17.6H350z"/>
          <path className="st7" d="M361.7,199.5v19.3c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.8,0.3h0.5v4.1h-1.5c-1.4,0-2.4-0.4-3.2-1.2
            c-0.8-0.8-1.1-2-1.1-3.4v-20H361.7z"/>
          <path className="st7" d="M369.2,224.5c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3c1-1,2.4-1.5,4.3-1.5h1.3l0.2,3.3h-1.3
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.1c0.2,0.3,0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.5
            c0.3-0.3,0.5-0.8,0.5-1.4l0.3,1.5c0,0.8-0.1,1.5-0.4,2.1c-0.3,0.6-0.6,1-1.1,1.3C370.4,224.3,369.9,224.5,369.2,224.5z
            M372.2,224.2v-12.6c0-0.5-0.1-0.8-0.4-1.1c-0.2-0.2-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.3-0.3,0.6-0.3,1.1h-4
            c0-1.7,0.5-3,1.4-3.9c0.9-0.9,2.2-1.4,3.9-1.4c1.7,0,3,0.5,4,1.4c0.9,0.9,1.4,2.2,1.4,3.9v12.6H372.2z"/>
          <path className="st7" d="M390.2,199.5v24.7H386v-24.7H390.2z"/>
          <path className="st7" d="M393.1,224.2v-17.6h4.2v17.6H393.1z M400,224.2v-12.4c0-0.6-0.1-1-0.3-1.3c-0.2-0.3-0.6-0.4-1-0.4
            s-0.8,0.1-1,0.4c-0.2,0.3-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-1.9,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2
            c0.6,0.8,0.9,1.9,0.9,3.3v13.5H400z"/>
          <path className="st7" d="M412.7,224.5c-1.8,0-3.1-0.4-4.1-1.3c-1-0.8-1.5-2-1.5-3.6v-8.2c0-1.6,0.5-2.9,1.5-3.8s2.3-1.3,4.1-1.3
            c1.8,0,3.1,0.4,4.1,1.3c1,0.9,1.5,2.2,1.5,3.8v1.5h-4.1v-1.2c0-0.6-0.1-1-0.4-1.3c-0.2-0.3-0.6-0.5-1-0.5c-0.4,0-0.7,0.1-1,0.4
            c-0.2,0.3-0.4,0.7-0.4,1.2v7.5c0,0.5,0.1,0.8,0.4,1.1c0.2,0.2,0.6,0.4,1,0.4c0.4,0,0.8-0.1,1-0.4c0.2-0.3,0.3-0.6,0.3-1.1v-1.1
            l4.2,0.1v1.4c0,1.6-0.5,2.8-1.5,3.6C415.9,224,414.5,224.5,412.7,224.5z"/>
          <path className="st7" d="M424.9,199.5v19.3c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.8,0.3h0.5v4.1H425c-1.4,0-2.4-0.4-3.2-1.2
            c-0.8-0.8-1.1-2-1.1-3.4v-20H424.9z"/>
          <path className="st7" d="M432.7,206.6v13.1c0,0.5,0.1,0.8,0.3,1s0.5,0.3,1,0.3c0.4,0,0.7-0.1,0.9-0.4s0.3-0.6,0.3-1l0.2,1.7
            c-0.3,1-0.7,1.8-1.2,2.3c-0.5,0.5-1.2,0.8-1.9,0.8c-1.3,0-2.2-0.4-2.8-1.2c-0.6-0.8-0.9-2-0.9-3.6v-13.1H432.7z M435.2,224.2
            v-17.6h4.2v17.6H435.2z"/>
          <path className="st7" d="M447.3,224.5c-1.6,0-3-0.5-4-1.5c-1-1-1.5-2.3-1.5-3.9v-0.3l3.9,0v0.3c0,0.5,0.1,0.9,0.4,1.2
            c0.3,0.3,0.7,0.5,1.2,0.5s0.9-0.1,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.1c0-0.5-0.1-0.8-0.4-1.1s-0.7-0.5-1.1-0.7
            c-0.5-0.2-1-0.4-1.5-0.6c-0.5-0.2-0.9-0.4-1.4-0.7c-0.5-0.3-0.9-0.6-1.2-1c-0.4-0.4-0.7-0.9-0.9-1.5s-0.3-1.3-0.3-2.1
            c0-1.6,0.5-2.8,1.4-3.8c1-1,2.2-1.4,3.7-1.4c1.5,0,2.8,0.5,3.8,1.4s1.4,2.2,1.4,3.7v0.3l-3.9,0.1v-0.4c0-0.4-0.1-0.7-0.3-1
            c-0.2-0.3-0.5-0.4-0.9-0.4c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.3-0.3,0.6-0.3,1.1c0,0.5,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.5,1,0.7
            c0.4,0.2,0.9,0.4,1.3,0.5c0.5,0.2,1,0.4,1.5,0.6c0.5,0.2,0.9,0.5,1.3,0.9c0.4,0.4,0.7,0.9,1,1.5c0.2,0.6,0.4,1.4,0.4,2.3
            c0,1.6-0.5,2.9-1.5,3.9C450.3,224,449,224.5,447.3,224.5z"/>
          <path className="st7" d="M454.9,203.7v-4.2h4.2v4.2H454.9z M454.9,224.2v-17.6h4.2v17.6H454.9z"/>
          <path className="st7" d="M467,224.5c-1.7,0-3-0.4-3.9-1.3s-1.4-2-1.4-3.5V211c0-1.5,0.5-2.7,1.4-3.5s2.2-1.2,3.9-1.2
            c1.7,0,3,0.4,3.9,1.2c0.9,0.8,1.4,2,1.4,3.5v8.7c0,1.5-0.5,2.7-1.4,3.5C470,224,468.7,224.5,467,224.5z M467.3,204.1l-2.8-2.2
            l4.2-6l3.2,2.5L467.3,204.1z M467,220.7c0.4,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.6,0.3-1v-8c0-0.4-0.1-0.7-0.3-0.9
            c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.9v8c0,0.4,0.1,0.8,0.3,1
            C466.4,220.6,466.7,220.7,467,220.7z"/>
          <path className="st7" d="M475.3,224.2v-17.6h4.2v17.6H475.3z M482.2,224.2v-12.4c0-0.6-0.1-1-0.3-1.3c-0.2-0.3-0.6-0.4-1-0.4
            s-0.8,0.1-1,0.4c-0.2,0.3-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-1.9,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2
            c0.6,0.8,0.9,1.9,0.9,3.3v13.5H482.2z"/>
          <path className="st7" d="M489.8,213.3h9.1v4h-9.1V213.3z"/>
          <path className="st7" d="M281.4,236v-4.2h4.2v4.2H281.4z M281.4,256.5v-17.6h4.2v17.6H281.4z"/>
          <path className="st7" d="M288.6,256.5v-17.6h4.2v17.6H288.6z M295.6,256.5v-12.4c0-0.6-0.1-1-0.3-1.3c-0.2-0.3-0.6-0.4-1-0.4
            s-0.8,0.1-1,0.4c-0.2,0.3-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-1.9,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2
            c0.6,0.8,0.9,1.9,0.9,3.3v13.5H295.6z"/>
          <path className="st7" d="M307.8,256.8c-1.6,0-3-0.5-4-1.5c-1-1-1.5-2.3-1.5-3.9v-0.3l3.9,0v0.3c0,0.5,0.1,0.9,0.4,1.2
            c0.3,0.3,0.7,0.5,1.2,0.5s0.9-0.1,1.1-0.4c0.3-0.3,0.4-0.7,0.4-1.1c0-0.5-0.1-0.8-0.4-1.1s-0.7-0.5-1.1-0.7
            c-0.5-0.2-1-0.4-1.5-0.6c-0.5-0.2-0.9-0.4-1.4-0.7c-0.5-0.3-0.9-0.6-1.2-1c-0.4-0.4-0.7-0.9-0.9-1.5s-0.3-1.3-0.3-2.1
            c0-1.6,0.5-2.8,1.4-3.8c1-1,2.2-1.4,3.7-1.4c1.5,0,2.8,0.5,3.8,1.4s1.4,2.2,1.4,3.7v0.3l-3.9,0.1v-0.4c0-0.4-0.1-0.7-0.3-1
            c-0.2-0.3-0.5-0.4-0.9-0.4c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.3-0.3,0.6-0.3,1.1c0,0.5,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.5,1,0.7
            c0.4,0.2,0.9,0.4,1.3,0.5c0.5,0.2,1,0.4,1.5,0.6c0.5,0.2,0.9,0.5,1.3,0.9c0.4,0.4,0.7,0.9,1,1.5c0.2,0.6,0.4,1.4,0.4,2.3
            c0,1.6-0.5,2.9-1.5,3.9C310.8,256.3,309.4,256.8,307.8,256.8z"/>
          <path className="st7" d="M320.9,256.8c-1.8,0-3.1-0.5-4.1-1.4c-1-0.9-1.5-2.3-1.5-4v-7c0-1.8,0.5-3.2,1.4-4.2c1-1,2.3-1.5,4-1.5
            c1.8,0,3.1,0.5,4.1,1.5c1,1,1.4,2.4,1.4,4.1v5h-7.6v-3.1h3.5V244c0-0.5-0.1-0.9-0.4-1.2c-0.3-0.3-0.6-0.4-1.1-0.4
            c-0.4,0-0.7,0.2-1,0.5c-0.2,0.3-0.4,0.7-0.4,1.3v7.4c0,0.5,0.1,0.9,0.4,1.1c0.3,0.3,0.6,0.4,1.1,0.4c0.4,0,0.8-0.1,1-0.3
            c0.2-0.2,0.4-0.5,0.4-0.9v-0.6l4.1,0v0.5c0,1.6-0.5,2.9-1.4,3.8S322.6,256.8,320.9,256.8z"/>
          <path className="st7" d="M329.3,256.5v-17.6h4.2v17.6H329.3z M337.7,243.1c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0
            c-1,0-1.8,0.3-2.3,0.9c-0.6,0.6-0.8,1.4-0.8,2.4l-0.4-4.4c0.5-1,1.1-1.9,1.9-2.5c0.8-0.6,1.7-0.9,2.6-0.9V243.1z"/>
          <path className="st7" d="M345,256.8c-1.8,0-3.1-0.4-4.1-1.3c-1-0.8-1.5-2-1.5-3.6v-8.2c0-1.6,0.5-2.9,1.5-3.8s2.3-1.3,4.1-1.3
            c1.8,0,3.1,0.4,4.1,1.3c1,0.9,1.5,2.2,1.5,3.8v1.5h-4.1v-1.2c0-0.6-0.1-1-0.4-1.3c-0.2-0.3-0.6-0.5-1-0.5c-0.4,0-0.7,0.1-1,0.4
            c-0.2,0.3-0.4,0.7-0.4,1.2v7.5c0,0.5,0.1,0.8,0.4,1.1c0.2,0.2,0.6,0.4,1,0.4c0.4,0,0.8-0.1,1-0.4c0.2-0.3,0.3-0.6,0.3-1.1v-1.1
            l4.2,0.1v1.4c0,1.6-0.5,2.8-1.5,3.6C348.1,256.3,346.7,256.8,345,256.8z"/>
          <path className="st7" d="M352.6,236v-4.2h4.2v4.2H352.6z M352.6,256.5v-17.6h4.2v17.6H352.6z"/>
          <path className="st7" d="M364.7,256.8c-1.7,0-3-0.4-3.9-1.3s-1.4-2-1.4-3.5v-8.7c0-1.5,0.5-2.7,1.4-3.5s2.2-1.2,3.9-1.2
            c1.7,0,3,0.4,3.9,1.2c0.9,0.8,1.4,2,1.4,3.5v8.7c0,1.5-0.5,2.7-1.4,3.5C367.7,256.4,366.4,256.8,364.7,256.8z M365,236.4l-2.8-2.2
            l4.2-6l3.2,2.5L365,236.4z M364.7,253c0.4,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.6,0.3-1v-8c0-0.4-0.1-0.7-0.3-0.9
            c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3c-0.2,0.2-0.3,0.5-0.3,0.9v8c0,0.4,0.1,0.8,0.3,1
            C364.1,252.9,364.3,253,364.7,253z"/>
          <path className="st7" d="M373,256.5v-17.6h4.2v17.6H373z M379.9,256.5v-12.4c0-0.6-0.1-1-0.3-1.3c-0.2-0.3-0.6-0.4-1-0.4
            s-0.8,0.1-1,0.4c-0.2,0.3-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-1.9,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2
            c0.6,0.8,0.9,1.9,0.9,3.3v13.5H379.9z"/>
          <path className="st7" d="M399.6,256.8c-1.8,0-3.1-0.4-4.1-1.3c-1-0.8-1.5-2-1.5-3.6v-8.2c0-1.6,0.5-2.9,1.5-3.8s2.3-1.3,4.1-1.3
            c1.8,0,3.1,0.4,4.1,1.3c1,0.9,1.5,2.2,1.5,3.8v1.5H401v-1.2c0-0.6-0.1-1-0.4-1.3c-0.2-0.3-0.6-0.5-1-0.5c-0.4,0-0.7,0.1-1,0.4
            c-0.2,0.3-0.4,0.7-0.4,1.2v7.5c0,0.5,0.1,0.8,0.4,1.1c0.2,0.2,0.6,0.4,1,0.4c0.4,0,0.8-0.1,1-0.4c0.2-0.3,0.3-0.6,0.3-1.1v-1.1
            l4.2,0.1v1.4c0,1.6-0.5,2.8-1.5,3.6C402.8,256.3,401.4,256.8,399.6,256.8z"/>
          <path className="st7" d="M412.4,256.8c-1.7,0-3-0.4-3.9-1.3s-1.4-2-1.4-3.5v-8.7c0-1.5,0.5-2.7,1.4-3.5s2.2-1.2,3.9-1.2
            c1.7,0,3,0.4,3.9,1.2c0.9,0.8,1.4,2,1.4,3.5v8.7c0,1.5-0.5,2.7-1.4,3.5C415.4,256.4,414.1,256.8,412.4,256.8z M412.4,253
            c0.4,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.6,0.3-1v-8c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3
            c-0.2,0.2-0.3,0.5-0.3,0.9v8c0,0.4,0.1,0.8,0.3,1C411.8,252.9,412.1,253,412.4,253z"/>
          <path className="st7" d="M420.7,256.5v-17.6h4.2v17.6H420.7z M427.6,256.5v-12.4c0-0.6-0.1-1-0.3-1.3c-0.2-0.3-0.6-0.4-1-0.4
            s-0.8,0.1-1,0.4c-0.2,0.3-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-1.9,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2
            c0.6,0.8,0.9,1.9,0.9,3.3v13.5H427.6z"/>
          <path className="st7" d="M446,256.8c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3c1-1,2.4-1.5,4.3-1.5h1.3l0.2,3.3H448
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.1c0.2,0.3,0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.5
            c0.3-0.3,0.5-0.8,0.5-1.4l0.3,1.5c0,0.8-0.1,1.5-0.4,2.1c-0.3,0.6-0.6,1-1.1,1.3C447.3,256.6,446.7,256.8,446,256.8z M449,256.5
            v-12.6c0-0.5-0.1-0.8-0.4-1.1c-0.2-0.2-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4c-0.2,0.3-0.3,0.6-0.3,1.1h-4c0-1.7,0.5-3,1.4-3.9
            c0.9-0.9,2.2-1.4,3.9-1.4c1.7,0,3,0.5,4,1.4c0.9,0.9,1.4,2.2,1.4,3.9v12.6H449z"/>
          <path className="st7" d="M456.4,256.5v-17.6h4.3v17.6H456.4z M463.5,256.5v-12.4c0-0.6-0.1-1-0.4-1.3c-0.2-0.3-0.6-0.4-1.1-0.4
            c-0.5,0-0.8,0.1-1,0.4c-0.2,0.3-0.4,0.7-0.4,1.3l-0.4-2.2c0.4-1.1,0.9-1.9,1.6-2.4c0.7-0.6,1.5-0.8,2.3-0.8c1.1,0,2,0.4,2.6,1.1
            s0.9,1.7,0.9,3v13.8H463.5z M470.5,256.5v-12.4c0-0.6-0.1-1-0.3-1.3c-0.2-0.3-0.6-0.4-1-0.4c-0.5,0-0.8,0.1-1.1,0.4
            c-0.2,0.3-0.4,0.7-0.4,1.3l-0.3-2.3c0.4-1.1,0.9-1.9,1.6-2.4c0.7-0.5,1.5-0.8,2.3-0.8c1.1,0,2,0.4,2.6,1.2
            c0.6,0.8,0.9,1.9,0.9,3.3v13.5H470.5z"/>
          <path className="st7" d="M483.1,256.8c-1.7,0-3-0.4-3.9-1.3s-1.4-2-1.4-3.5v-8.7c0-1.5,0.5-2.7,1.4-3.5s2.2-1.2,3.9-1.2
            c1.7,0,3,0.4,3.9,1.2c0.9,0.8,1.4,2,1.4,3.5v8.7c0,1.5-0.5,2.7-1.4,3.5C486,256.4,484.7,256.8,483.1,256.8z M483.1,253
            c0.4,0,0.6-0.1,0.8-0.3c0.2-0.2,0.3-0.6,0.3-1v-8c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-0.8-0.3c-0.3,0-0.6,0.1-0.8,0.3
            c-0.2,0.2-0.3,0.5-0.3,0.9v8c0,0.4,0.1,0.8,0.3,1C482.4,252.9,482.7,253,483.1,253z"/>
          <path className="st7" d="M491.3,256.5v-17.6h4.2v17.6H491.3z M499.8,243.1c-0.1,0-0.3,0-0.5,0c-0.2,0-0.4,0-0.6,0
            c-1,0-1.8,0.3-2.3,0.9c-0.6,0.6-0.8,1.4-0.8,2.4l-0.4-4.4c0.5-1,1.1-1.9,1.9-2.5c0.8-0.6,1.7-0.9,2.6-0.9V243.1z"/>
	      </g>
      </g>
    </>,
    title: "Vincula Inclusión-Inserción con amor",
    content: <>
			<p className="mb-2">El modelo propio de la Fundación Arduvi – Inclusión con Amor – propende brindar un proceso integral de fortalecimiento de capacidades humanas a personas y sus núcleos familiares con capacidades especiales (PcD), población migrante, población víctimas dentro del marco del conflicto armado, población en extrema vulnerabilidad. El modelo, no solo busca garantizar procesos de atención psicosocial, salud integral, intervención en psicología clínica, además, gestionar una formación en habilidades duras (técnicas), en Powers Skills (habilidades blandas) y apoyo para la gestión de oportunidades de inserción al mundo laboral.</p>
      <p className="mb-2">Nuestros participantes beneficiarios del modelo Arduvi están completamente capacitados técnicamente, así como con habilidades sociales y laborales.</p>
      <p className="mb-2">Esta estrategia de responsabilidad social empresarial, aparte de transformar socioculturalmente el imaginario de las personas y desmitificar falsas creencias, contribuye a mitigar las barreras que enfrentan las personas de mayor riesgo social en su ingreso al mercado laboral, generando consigo beneficios tributarios como costo fiscal a las empresas que incluyan dentro de sus nóminas laborales a personas con capacidades especiales (PcD), mujeres sobrevivientes de violencias, personas mayores, jóvenes, entre otros.</p>
      <p>Finalmente, nuestra estrategia de “inclusión/inserción con amor”, no solo forma, capacita y prepara a las y los participantes beneficiarios sino se le realiza un acompañamiento jurídico.</p>
		</>,
    bg: "bg-[#F3C245]",
    text: "text-white"
  },
  {
    button: <>
      <g className="S">
        <path className="st10" d="M792,230.7c-56.6,56.4-112.8,113.2-168.9,170.1c-5.7,5.8-9.3,6.3-15.6,1c-21.2-17.7-46-27.8-73.4-30.3
          c-8.9-0.8-10.2-4.6-10.2-12.2c0.2-40.2,0.1-80.4,0.1-120.6s0.6-77.9-0.3-116.7c-0.3-13.2,4.1-16,16-14.6c19.9,2.3,40,3.6,59.5,8
          c6.4,1.5,9.2,0.8,12.5-5.9c16.4-33.2,50.6-46.5,83.8-33.6c32.1,12.5,48,47,37.1,82.5c-2.3,7.5-0.6,10.6,5.3,14.4
          c19,12.4,36.7,26.5,53.4,41.9C797.5,220.3,798.9,223.9,792,230.7L792,230.7z"/>
        <g>
          <path className="st6" d="M673.6,184.8c-4.8,0-8.9-0.9-12.3-2.6c-3.4-1.8-6-4.3-7.8-7.6s-2.7-7.4-2.7-12.1v-4.9l15.4-0.1v4.9
            c0,2.3,0.6,4.1,1.9,5.4s3.1,2,5.4,2s4-0.6,5.2-1.9c1.2-1.3,1.8-3.1,1.8-5.3v-7.9c0-1.9-0.5-3.4-1.7-4.7c-1.1-1.3-2.6-2.4-4.4-3.4
            c-1.8-1-3.8-2.1-5.9-3.1c-2-1-4.1-2.2-6.2-3.5c-2.1-1.3-4-2.8-5.7-4.6s-3.1-4-4.2-6.6c-1.1-2.6-1.6-5.8-1.6-9.5v-4.7
            c0-7,2-12.4,5.9-16.3s9.5-5.8,16.6-5.8s12.4,2,16.3,5.9c3.9,3.9,5.9,9.4,5.9,16.5v5.1l-15.4,0.2V115c0-2.3-0.6-4.2-1.8-5.5
            s-2.8-2-5-2s-4,0.6-5.2,1.8c-1.2,1.2-1.8,2.8-1.8,4.9v6.1c0,2.1,0.6,3.9,1.9,5.2s2.9,2.5,4.9,3.6c2,1,4.2,2.1,6.4,3.3
            c2,1,3.9,2.1,5.8,3.2s3.7,2.6,5.3,4.2s2.9,3.7,3.9,6.2c1,2.4,1.5,5.5,1.5,9.1v7.6c0,7.1-2,12.6-5.9,16.5
            C686.2,182.8,680.6,184.8,673.6,184.8L673.6,184.8z"/>
          <path className="st7" d="M670.9,182c-4.8,0-8.9-0.9-12.3-2.6c-3.4-1.8-6-4.3-7.8-7.6s-2.7-7.4-2.7-12.1v-4.9l15.4-0.1v4.9
            c0,2.3,0.6,4.1,1.9,5.4s3.1,2,5.4,2s4-0.6,5.2-1.9c1.2-1.3,1.8-3.1,1.8-5.3v-7.9c0-1.9-0.5-3.4-1.7-4.7c-1.1-1.3-2.6-2.4-4.4-3.4
            c-1.8-1-3.8-2.1-5.9-3.1c-2-1-4.1-2.2-6.2-3.5c-2.1-1.3-4-2.8-5.7-4.6s-3.1-4-4.2-6.6c-1.1-2.6-1.6-5.8-1.6-9.5v-4.7
            c0-7,2-12.4,5.9-16.3s9.5-5.8,16.6-5.8s12.4,2,16.3,5.9c3.9,3.9,5.9,9.4,5.9,16.5v5.1l-15.4,0.2v-5.2c0-2.3-0.6-4.2-1.8-5.5
            s-2.8-2-5-2s-4,0.6-5.2,1.8c-1.2,1.2-1.8,2.8-1.8,4.9v6.1c0,2.1,0.6,3.9,1.9,5.2s2.9,2.5,4.9,3.6s4.2,2.1,6.4,3.3
            c2,1,3.9,2.1,5.8,3.2s3.7,2.6,5.3,4.2s2.9,3.7,3.9,6.2c1,2.4,1.5,5.5,1.5,9.1v7.6c0,7.1-2,12.6-5.9,16.5
            C683.5,180.1,678,182.1,670.9,182L670.9,182z"/>
        </g>
        <path d="M551.8,351.9c-0.8-0.1-1.6-0.2-2.3-0.3c-4.7-0.8-8.7-3.1-11.8-6.7c-2.9-3.3-4.6-7.1-5-11.4c-0.5-5.3,0.9-10.2,4.1-14.5
          c3-4,7-6.5,11.9-7.5c6.1-1.3,11.7,0,16.7,3.7c4,3,6.5,7.1,7.6,12c0.2,0.9,0.3,1.8,0.4,2.6c0,0.1,0,0.3,0.1,0.4c0,0.8,0,1.6,0,2.4
          c0,0.3-0.1,0.6-0.1,0.9c-0.5,4.4-2.2,8.3-5.1,11.6c-3.1,3.5-7,5.7-11.7,6.5c-0.8,0.1-1.5,0.2-2.3,0.3
          C553.4,351.9,552.6,351.9,551.8,351.9z M552.9,349.7c10,0,18.2-8.1,18.2-18.2s-8.1-18.2-18.2-18.2c-10.1,0-18.2,8.2-18.2,18.2
          S542.9,349.6,552.9,349.7z"/>
        <path d="M542.3,337c0.7-0.4,1.3-0.8,1.9-1.2c2.2,3,5.1,4.8,8.8,4.8c3.8,0,6.7-1.7,8.8-4.8c0.6,0.4,1.2,0.8,1.9,1.2
          c-2.6,3.7-6.2,5.8-10.7,5.8C548.4,342.8,544.9,340.7,542.3,337z"/>
        <path d="M544.1,326.9c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2s-1,2.2-2.2,2.2S544.1,328.1,544.1,326.9z"/>
        <path d="M562.2,326.9c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2C561.2,324.7,562.2,325.7,562.2,326.9z"/>
        <path d="M595.4,351.9c-0.8-0.1-1.6-0.2-2.3-0.3c-4.7-0.8-8.7-3.1-11.8-6.7c-2.9-3.3-4.6-7.1-5-11.4c-0.5-5.3,0.9-10.2,4.1-14.5
          c3-4,7-6.5,11.9-7.5c6.1-1.3,11.7,0,16.7,3.7c4,3,6.5,7.1,7.6,12c0.2,0.9,0.3,1.8,0.4,2.6c0,0.1,0,0.3,0.1,0.4c0,0.8,0,1.6,0,2.4
          c0,0.3-0.1,0.6-0.1,0.9c-0.5,4.4-2.2,8.3-5.1,11.6c-3.1,3.5-7,5.7-11.7,6.5c-0.8,0.1-1.5,0.2-2.3,0.3
          C597,351.9,596.2,351.9,595.4,351.9z M596.6,349.7c10,0,18.2-8.1,18.2-18.2s-8.1-18.2-18.2-18.2c-10.1,0-18.2,8.2-18.2,18.2
          C578.4,341.5,586.5,349.6,596.6,349.7z"/>
        <path d="M585.9,337c0.7-0.4,1.3-0.8,1.9-1.2c2.2,3,5.1,4.8,8.8,4.8c3.8,0,6.7-1.7,8.8-4.8c0.6,0.4,1.2,0.8,1.9,1.2
          c-2.6,3.7-6.2,5.8-10.7,5.8C592,342.8,588.5,340.7,585.9,337z"/>
        <path d="M587.7,326.9c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2s-1,2.2-2.2,2.2S587.7,328.1,587.7,326.9z"/>
        <path d="M605.8,326.9c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2C604.8,324.7,605.8,325.7,605.8,326.9z"/>
        <path d="M639.2,351.9c-0.8-0.1-1.6-0.2-2.3-0.3c-4.7-0.8-8.7-3.1-11.8-6.7c-2.9-3.3-4.6-7.1-5-11.4c-0.5-5.3,0.9-10.2,4.1-14.5
          c3-4,7-6.5,11.9-7.5c6.1-1.3,11.7,0,16.7,3.7c4,3,6.5,7.1,7.6,12c0.2,0.9,0.3,1.8,0.4,2.6c0,0.1,0,0.3,0.1,0.4c0,0.8,0,1.6,0,2.4
          c0,0.3-0.1,0.6-0.1,0.9c-0.5,4.4-2.2,8.3-5.1,11.6c-3.1,3.5-7,5.7-11.7,6.5c-0.8,0.1-1.5,0.2-2.3,0.3
          C640.8,351.9,640,351.9,639.2,351.9z M640.3,349.7c10,0,18.2-8.1,18.2-18.2s-8.1-18.2-18.2-18.2c-10.1,0-18.2,8.2-18.2,18.2
          S630.3,349.6,640.3,349.7z"/>
        <path d="M629.7,337c0.7-0.4,1.3-0.8,1.9-1.2c2.2,3,5.1,4.8,8.8,4.8c3.8,0,6.7-1.7,8.8-4.8c0.6,0.4,1.2,0.8,1.9,1.2
          c-2.6,3.7-6.2,5.8-10.7,5.8C635.8,342.8,632.3,340.7,629.7,337z"/>
        <path d="M631.5,326.9c0-1.2,1-2.2,2.2-2.2s2.2,1,2.2,2.2s-1,2.2-2.2,2.2S631.5,328.1,631.5,326.9z"/>
        <path d="M649.5,326.9c0,1.2-1,2.2-2.2,2.2s-2.2-1-2.2-2.2s1-2.2,2.2-2.2C648.6,324.7,649.5,325.7,649.5,326.9z"/>
        <g>
          <path className="st7" d="M539.6,223.9v-24.7h4.1v24.7H539.6z M541.5,213.4v-4h4.5c0.5,0,1-0.1,1.3-0.4s0.5-0.7,0.5-1.2V205
            c0-0.6-0.2-1-0.5-1.3s-0.7-0.5-1.3-0.5h-4.5v-4h4.2c1.3,0,2.5,0.2,3.4,0.6c0.9,0.4,1.7,1.1,2.2,1.9c0.5,0.8,0.8,1.9,0.8,3v2.9
            c0,1.2-0.2,2.3-0.8,3.1s-1.2,1.5-2.2,2s-2.1,0.7-3.4,0.7H541.5L541.5,213.4z M548.2,223.9l-3.3-11.2l3.8-0.8l3.9,12H548.2
            L548.2,223.9z"/>
          <path className="st7" d="M560,224.1c-1.8,0-3.1-0.5-4.1-1.4s-1.5-2.3-1.5-4v-7c0-1.8,0.5-3.2,1.4-4.2c1-1,2.3-1.5,4-1.5
            s3.1,0.5,4.1,1.5s1.4,2.4,1.4,4.1v5h-7.5v-3.1h3.5v-2.1c0-0.5-0.1-0.9-0.4-1.2c-0.2-0.3-0.6-0.4-1.1-0.4c-0.4,0-0.8,0.2-1,0.5
            s-0.4,0.8-0.4,1.3v7.4c0,0.5,0.1,0.9,0.4,1.1s0.6,0.4,1.1,0.4s0.8-0.1,1-0.3s0.4-0.5,0.4-0.9v-0.6h4.1v0.5c0,1.6-0.5,2.9-1.4,3.8
            S561.7,224.1,560,224.1L560,224.1z"/>
          <path className="st7" d="M571.5,224.2c-1.1,0-2-0.4-2.6-1.3s-0.9-2-0.9-3.5v-8.6c0-1.5,0.3-2.7,0.9-3.5s1.5-1.2,2.6-1.2
            c0.8,0,1.5,0.3,2.1,0.8c0.7,0.6,1.2,1.3,1.5,2.4l-0.4,2.2c0-0.4,0-0.6-0.2-0.9s-0.3-0.4-0.5-0.5s-0.4-0.2-0.7-0.2
            c-0.4,0-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.2v7.4c0,0.5,0.1,0.9,0.4,1.1c0.2,0.3,0.6,0.4,1,0.4c0.3,0,0.5-0.1,0.7-0.2s0.3-0.3,0.5-0.5
            c0.1-0.2,0.2-0.5,0.2-0.9l0.2,2.4c-0.3,0.9-0.8,1.7-1.4,2.2C573.1,223.9,572.4,224.2,571.5,224.2L571.5,224.2z M573.5,231
            c-1.7,0-3-0.4-4-1.3c-0.9-0.9-1.4-2.1-1.4-3.7v-0.9h4.2v0.9c0,0.4,0.1,0.7,0.3,0.9s0.5,0.3,1,0.3c0.4,0,0.7-0.1,0.9-0.3
            s0.3-0.5,0.3-0.9v-19.7h4.2V226c0,1.6-0.5,2.9-1.5,3.8C576.6,230.6,575.3,231,573.5,231L573.5,231z"/>
          <path className="st7" d="M586.2,224.1c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3s2.4-1.5,4.3-1.5h1.3l0.2,3.3h-1.3
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.1c0.2,0.3,0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.5s0.5-0.8,0.5-1.4
            l0.3,1.5c0,0.8-0.2,1.6-0.4,2.1c-0.3,0.6-0.6,1-1.1,1.3S586.9,224.2,586.2,224.1L586.2,224.1z M589.2,223.9v-12.6
            c0-0.5-0.1-0.8-0.4-1.1s-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.1h-4c0-1.7,0.5-3,1.4-3.9s2.2-1.4,3.9-1.4
            s3,0.5,4,1.4c0.9,0.9,1.4,2.2,1.4,3.9v12.6H589.2L589.2,223.9z"/>
          <path className="st7" d="M600.8,199.2v19.3c0,0.4,0.1,0.7,0.3,0.9s0.5,0.3,0.8,0.3h0.5v4.1h-1.5c-1.4,0-2.4-0.4-3.2-1.2
            s-1.1-2-1.1-3.4v-20L600.8,199.2L600.8,199.2z"/>
          <path className="st7" d="M608.2,224.1c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3s2.4-1.5,4.3-1.5h1.3l0.2,3.3h-1.3
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.1c0.2,0.3,0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.5s0.5-0.8,0.5-1.4
            l0.3,1.5c0,0.8-0.2,1.6-0.4,2.1c-0.3,0.6-0.6,1-1.1,1.3S608.9,224.2,608.2,224.1L608.2,224.1z M611.3,223.9v-12.6
            c0-0.5-0.1-0.8-0.4-1.1s-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.1h-4c0-1.7,0.5-3,1.4-3.9s2.2-1.4,3.9-1.4
            s3,0.5,4,1.4c0.9,0.9,1.4,2.2,1.4,3.9v12.6H611.3L611.3,223.9z"/>
          <path className="st7" d="M631.3,224.1c-1.3,0-2.4-0.2-3.3-0.7c-0.9-0.5-1.6-1.2-2.1-2.1s-0.7-2-0.7-3.3v-1.3h4.2v1.3
            c0,0.6,0.2,1.1,0.5,1.5s0.8,0.5,1.5,0.5s1.1-0.2,1.4-0.5c0.3-0.4,0.5-0.8,0.5-1.4V216c0-0.5-0.2-0.9-0.5-1.3s-0.7-0.7-1.2-0.9
            c-0.5-0.3-1-0.6-1.6-0.8c-0.5-0.3-1.1-0.6-1.7-0.9c-0.6-0.4-1.1-0.8-1.6-1.3s-0.8-1.1-1.1-1.8s-0.4-1.6-0.4-2.6V205
            c0-1.9,0.5-3.4,1.6-4.4c1.1-1.1,2.6-1.6,4.5-1.6s3.4,0.5,4.4,1.6c1.1,1.1,1.6,2.6,1.6,4.5v1.4l-4.2,0.1v-1.4
            c0-0.6-0.2-1.1-0.5-1.5s-0.8-0.5-1.3-0.5c-0.6,0-1.1,0.2-1.4,0.5c-0.3,0.3-0.5,0.8-0.5,1.3v1.6c0,0.6,0.2,1.1,0.5,1.4
            s0.8,0.7,1.3,1s1.1,0.6,1.7,0.9c0.5,0.3,1.1,0.6,1.6,0.9c0.5,0.3,1,0.7,1.4,1.1c0.4,0.4,0.8,1,1,1.7c0.3,0.7,0.4,1.5,0.4,2.5v2.1
            c0,1.9-0.5,3.4-1.6,4.5S633.2,224.2,631.3,224.1L631.3,224.1z"/>
          <path className="st7" d="M645.5,224.1c-1.7,0-3-0.4-3.9-1.3s-1.4-2-1.4-3.5v-8.6c0-1.5,0.5-2.7,1.4-3.5s2.2-1.2,3.9-1.2s3,0.4,3.9,1.2
            s1.4,2,1.4,3.5v8.7c0,1.5-0.5,2.7-1.4,3.5S647.2,224.2,645.5,224.1L645.5,224.1z M645.5,220.4c0.4,0,0.6-0.1,0.8-0.3
            s0.3-0.6,0.3-1v-8c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.9v8c0,0.4,0.1,0.8,0.3,1
            C644.9,220.2,645.1,220.4,645.5,220.4z"/>
          <path className="st7" d="M653.8,223.9v-17.6h4.2v17.6H653.8z M660.7,223.9v-12.4c0-0.6-0.1-1-0.3-1.3s-0.6-0.4-1-0.4s-0.8,0.1-1,0.4
            c-0.2,0.3-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-1.9,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2s0.9,1.9,0.9,3.3V224h-4.2
            V223.9z"/>
          <path className="st7" d="M668.2,223.9v-17.6h4.2v17.6H668.2z M676.7,210.4h-1.1c-1,0-1.8,0.3-2.3,0.9c-0.5,0.6-0.8,1.4-0.8,2.4
            l-0.4-4.4c0.5-1,1.2-1.9,1.9-2.5c0.8-0.6,1.7-0.9,2.6-0.9L676.7,210.4L676.7,210.4z"/>
          <path className="st7" d="M678.8,203.4v-4.2h4.2v4.2H678.8z M678.8,223.9v-17.6h4.2v17.6H678.8z"/>
          <path className="st7" d="M690.6,224.1c-1.6,0-3-0.5-4-1.5s-1.5-2.3-1.5-3.9v-0.3h3.9v0.3c0,0.5,0.2,0.9,0.5,1.2s0.7,0.5,1.2,0.5
            s0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1s-0.7-0.5-1.1-0.7c-0.5-0.2-1-0.4-1.5-0.6c-0.5-0.2-0.9-0.4-1.4-0.7
            s-0.9-0.6-1.2-1c-0.4-0.4-0.7-0.9-0.9-1.4s-0.3-1.3-0.3-2.1c0-1.6,0.5-2.8,1.4-3.8s2.2-1.4,3.7-1.4s2.8,0.5,3.8,1.4
            s1.4,2.2,1.4,3.7v0.3l-3.9,0.1v-0.4c0-0.4-0.1-0.7-0.3-1s-0.5-0.4-0.9-0.4s-0.7,0.1-0.9,0.4c-0.2,0.3-0.3,0.6-0.3,1.1
            s0.1,0.8,0.4,1.1s0.6,0.5,1,0.7s0.9,0.4,1.3,0.5c0.5,0.2,1,0.4,1.5,0.6c0.5,0.2,0.9,0.5,1.3,0.9c0.4,0.4,0.7,0.9,1,1.5
            c0.2,0.6,0.4,1.4,0.4,2.3c0,1.6-0.5,2.9-1.5,3.9S692.3,224.2,690.6,224.1L690.6,224.1z"/>
          <path className="st7" d="M702.3,224.1c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3s2.4-1.5,4.3-1.5h1.3l0.2,3.3h-1.3
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.1c0.2,0.3,0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.5s0.5-0.8,0.5-1.4
            l0.3,1.5c0,0.8-0.2,1.6-0.4,2.1c-0.3,0.6-0.6,1-1.1,1.3S703,224.2,702.3,224.1L702.3,224.1z M705.3,223.9v-12.6
            c0-0.5-0.1-0.8-0.4-1.1s-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.1h-4c0-1.7,0.5-3,1.4-3.9s2.2-1.4,3.9-1.4
            s3,0.5,4,1.4c0.9,0.9,1.4,2.2,1.4,3.9v12.6H705.3L705.3,223.9z"/>
          <path className="st7" d="M717.3,224.1c-1.6,0-3-0.5-4-1.5s-1.5-2.3-1.5-3.9v-0.3h3.9v0.3c0,0.5,0.2,0.9,0.5,1.2s0.7,0.5,1.2,0.5
            s0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1s-0.7-0.5-1.1-0.7c-0.5-0.2-1-0.4-1.5-0.6c-0.5-0.2-0.9-0.4-1.4-0.7
            s-0.9-0.6-1.2-1c-0.4-0.4-0.7-0.9-0.9-1.4s-0.3-1.3-0.3-2.1c0-1.6,0.5-2.8,1.4-3.8s2.2-1.4,3.7-1.4s2.8,0.5,3.8,1.4
            s1.4,2.2,1.4,3.7v0.3l-3.9,0.1v-0.4c0-0.4-0.1-0.7-0.3-1s-0.5-0.4-0.9-0.4s-0.7,0.1-0.9,0.4c-0.2,0.3-0.3,0.6-0.3,1.1
            s0.1,0.8,0.4,1.1s0.6,0.5,1,0.7s0.9,0.4,1.3,0.5c0.5,0.2,1,0.4,1.5,0.6c0.5,0.2,0.9,0.5,1.3,0.9c0.4,0.4,0.7,0.9,1,1.5
            c0.2,0.6,0.4,1.4,0.4,2.3c0,1.6-0.5,2.9-1.5,3.9S719,224.2,717.3,224.1L717.3,224.1z"/>
          <path className="st7" d="M545,256.5c-1.8,0-3.1-0.5-4.1-1.4s-1.5-2.3-1.5-4v-7c0-1.8,0.5-3.2,1.4-4.2c1-1,2.3-1.5,4-1.5
            s3.1,0.5,4.1,1.5s1.4,2.4,1.4,4.1v5h-7.5v-3.1h3.5v-2.1c0-0.5-0.1-0.9-0.4-1.2c-0.2-0.3-0.6-0.4-1.1-0.4c-0.4,0-0.8,0.2-1,0.5
            s-0.4,0.8-0.4,1.3v7.4c0,0.5,0.1,0.9,0.4,1.1s0.6,0.4,1.1,0.4s0.8-0.1,1-0.3s0.4-0.5,0.4-0.9v-0.6h4.1v0.5c0,1.6-0.5,2.9-1.4,3.8
            S546.7,256.5,545,256.5L545,256.5z"/>
          <path className="st7" d="M553.4,256.2v-17.6h4.2v17.6H553.4z M560.3,256.2v-12.4c0-0.6-0.1-1-0.3-1.3s-0.6-0.4-1-0.4s-0.8,0.1-1,0.4
            c-0.2,0.3-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-1.9,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2s0.9,1.9,0.9,3.3v13.5h-4.2
            V256.2z"/>
          <path className="st7" d="M574.7,256.2v-24.7h4.2v24.7H574.7z M576.6,235.6v-4h9.1v4H576.6z M576.6,246.2v-4h8.2v4H576.6z"/>
          <path className="st7" d="M592.9,256.5c-1.8,0-3.1-0.5-4.1-1.4s-1.5-2.3-1.5-4v-7c0-1.8,0.5-3.2,1.4-4.2c1-1,2.3-1.5,4-1.5
            s3.1,0.5,4.1,1.5s1.4,2.4,1.4,4.1v5h-7.5v-3.1h3.5v-2.1c0-0.5-0.1-0.9-0.4-1.2c-0.2-0.3-0.6-0.4-1.1-0.4c-0.4,0-0.8,0.2-1,0.5
            s-0.4,0.8-0.4,1.3v7.4c0,0.5,0.1,0.9,0.4,1.1s0.6,0.4,1.1,0.4s0.8-0.1,1-0.3s0.4-0.5,0.4-0.9v-0.6h4.1v0.5c0,1.6-0.5,2.9-1.4,3.8
            S594.6,256.5,592.9,256.5L592.9,256.5z"/>
          <path className="st7" d="M606.5,256.5c-1.8,0-3.1-0.4-4.1-1.3c-1-0.9-1.5-2-1.5-3.6v-8.2c0-1.6,0.5-2.9,1.5-3.8s2.3-1.3,4.1-1.3
            s3.1,0.4,4.1,1.4c1,0.9,1.5,2.2,1.5,3.8v1.5H608v-1.1c0-0.6-0.1-1-0.4-1.3s-0.6-0.5-1-0.5s-0.8,0.1-1,0.4
            c-0.2,0.3-0.4,0.7-0.4,1.2v7.5c0,0.4,0.1,0.8,0.4,1.1s0.6,0.4,1,0.4s0.8-0.1,1-0.4c0.2-0.3,0.3-0.6,0.3-1.1v-1.1l4.2,0.1v1.4
            c0,1.6-0.5,2.8-1.5,3.6C609.6,256,608.2,256.4,606.5,256.5L606.5,256.5z"/>
          <path className="st7" d="M614.4,256.2v-24.7h4.2v24.7H614.4z M621.3,256.2v-12.3c0-0.6-0.1-1-0.3-1.3s-0.6-0.4-1-0.4s-0.8,0.1-1,0.4
            c-0.2,0.3-0.3,0.7-0.3,1.2l-0.3-2.2c0.3-1.1,0.8-1.9,1.5-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.1c0.6,0.8,0.9,1.9,0.9,3.3v13.5
            L621.3,256.2L621.3,256.2z"/>
          <path className="st7" d="M632.7,256.5c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3s2.4-1.5,4.3-1.5h1.3l0.2,3.3h-1.3
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.1c0.2,0.3,0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.5s0.5-0.8,0.5-1.4
            l0.3,1.5c0,0.8-0.2,1.6-0.4,2.1c-0.3,0.6-0.6,1-1.1,1.3C634,256.1,633.4,256.5,632.7,256.5L632.7,256.5z M635.7,256.2v-12.6
            c0-0.5-0.1-0.8-0.4-1.1s-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.1h-4c0-1.7,0.5-3,1.4-3.9s2.2-1.4,3.9-1.4
            s3,0.5,4,1.4c0.9,0.9,1.4,2.2,1.4,3.9v12.6H635.7L635.7,256.2z"/>
          <path className="st7" d="M647.7,256.5c-1.6,0-3-0.5-4-1.5s-1.5-2.3-1.5-3.9v-0.3h3.9v0.3c0,0.5,0.2,0.9,0.5,1.2s0.7,0.5,1.2,0.5
            s0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1s-0.7-0.5-1.1-0.7c-0.5-0.2-1-0.4-1.5-0.6c-0.5-0.2-0.9-0.4-1.4-0.7
            s-0.9-0.6-1.2-1c-0.4-0.4-0.7-0.9-0.9-1.4s-0.3-1.3-0.3-2.1c0-1.6,0.5-2.8,1.4-3.8s2.2-1.4,3.7-1.4s2.8,0.5,3.8,1.4
            s1.4,2.2,1.4,3.7v0.3L649,244v-0.4c0-0.4-0.1-0.7-0.3-1s-0.5-0.4-0.9-0.4s-0.7,0.1-0.9,0.4c-0.2,0.3-0.3,0.6-0.3,1.1
            s0.1,0.8,0.4,1.1s0.6,0.5,1,0.7s0.9,0.4,1.3,0.5c0.5,0.2,1,0.4,1.5,0.6c0.5,0.2,0.9,0.5,1.3,0.9c0.4,0.4,0.7,0.9,1,1.5
            c0.2,0.6,0.4,1.4,0.4,2.3c0,1.6-0.5,2.9-1.5,3.9C650.7,256,649.3,256.5,647.7,256.5L647.7,256.5z"/>
          <path className="st7" d="M539.6,288.5v-24.7h4.2v24.7H539.6z M541.5,268v-4.1h9.1v4.1H541.5z M541.5,278.3v-4.1h8.2v4.1H541.5z
            M541.5,288.5v-4.1h9.1v4.1H541.5z"/>
          <path className="st7" d="M557.6,288.8c-1.6,0-3-0.5-4-1.5s-1.5-2.3-1.5-3.9v-0.3h3.9v0.3c0,0.5,0.2,0.9,0.5,1.2s0.7,0.5,1.2,0.5
            s0.9-0.1,1.2-0.4s0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1s-0.7-0.5-1.1-0.7c-0.5-0.2-1-0.4-1.5-0.6s-0.9-0.4-1.4-0.7
            c-0.5-0.3-0.9-0.6-1.2-1c-0.4-0.4-0.7-0.9-0.9-1.5s-0.3-1.3-0.3-2.1c0-1.6,0.5-2.8,1.4-3.8s2.2-1.4,3.7-1.4s2.8,0.5,3.8,1.4
            s1.4,2.2,1.4,3.7v0.3h-3.9v-0.4c0-0.4-0.1-0.7-0.3-1s-0.5-0.4-0.9-0.4s-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1c0,0.5,0.1,0.8,0.4,1.1
            s0.6,0.5,1,0.7s0.9,0.4,1.3,0.5c0.5,0.2,1,0.4,1.5,0.6s0.9,0.5,1.3,0.9c0.4,0.4,0.7,0.9,1,1.5c0.2,0.6,0.4,1.4,0.4,2.3
            c0,1.6-0.5,2.9-1.5,3.9S559.2,288.8,557.6,288.8L557.6,288.8z"/>
          <path className="st7" d="M565.5,295.7v-24.8h4.2v24.8H565.5z M572.9,288.8c-0.8,0-1.5-0.3-2.1-0.8s-1-1.3-1.3-2.2l0.2-2.5
            c0,0.4,0,0.7,0.2,0.9s0.3,0.5,0.5,0.6s0.5,0.2,0.7,0.2c0.4,0,0.7-0.2,1-0.5c0.2-0.3,0.3-0.7,0.3-1.3v-7.1c0-0.5-0.1-0.9-0.3-1.2
            c-0.2-0.3-0.5-0.4-1-0.4c-0.3,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.5-0.2,0.9l-0.4-2.2c0.4-1,0.9-1.8,1.5-2.4
            s1.3-0.9,2.1-0.9c1.1,0,2,0.4,2.6,1.3s0.9,2,0.9,3.6v8.4c0,1.6-0.3,2.8-0.9,3.7S574,288.8,572.9,288.8L572.9,288.8z"/>
          <path className="st7" d="M584.6,288.8c-1.8,0-3.1-0.5-4.1-1.4s-1.5-2.3-1.5-4v-7c0-1.8,0.5-3.2,1.4-4.2c1-1,2.3-1.5,4-1.5
            s3.1,0.5,4.1,1.5s1.4,2.4,1.4,4.1v5h-7.5v-3.1h3.5V276c0-0.5-0.1-0.9-0.4-1.2s-0.6-0.4-1.1-0.4c-0.4,0-0.8,0.2-1,0.5
            s-0.4,0.8-0.4,1.3v7.4c0,0.5,0.1,0.9,0.4,1.1s0.6,0.4,1.1,0.4s0.8-0.1,1-0.3c0.2-0.2,0.4-0.5,0.4-0.9v-0.6h4.1v0.5
            c0,1.6-0.5,2.9-1.4,3.8S586.4,288.8,584.6,288.8L584.6,288.8z"/>
          <path className="st7" d="M598.2,288.8c-1.8,0-3.1-0.4-4.1-1.3s-1.5-2-1.5-3.6v-8.2c0-1.6,0.5-2.9,1.5-3.8s2.3-1.3,4.1-1.3
            s3.1,0.5,4.1,1.4s1.5,2.2,1.5,3.8v1.5h-4.1v-1.1c0-0.5-0.1-1-0.4-1.3s-0.6-0.5-1-0.5s-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.2v7.5
            c0,0.5,0.1,0.8,0.4,1s0.6,0.4,1,0.4s0.8-0.1,1-0.4s0.3-0.6,0.3-1.1v-1.1h4.2v1.4c0,1.5-0.5,2.8-1.5,3.6
            C601.4,288.4,600,288.8,598.2,288.8L598.2,288.8z"/>
          <path className="st7" d="M605.8,268v-4.2h4.2v4.2H605.8z M605.8,288.5v-17.6h4.2v17.6H605.8L605.8,288.5z"/>
          <path className="st7" d="M616.8,288.8c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3s2.4-1.5,4.3-1.5h1.3l0.2,3.3h-1.3
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.1c0.2,0.3,0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.5s0.5-0.8,0.5-1.4
            l0.3,1.5c0,0.8-0.2,1.5-0.4,2.1c-0.3,0.6-0.6,1-1.1,1.3C618.1,288.6,617.5,288.8,616.8,288.8L616.8,288.8z M619.9,288.5v-12.6
            c0-0.5-0.1-0.8-0.4-1.1s-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.1h-4c0-1.7,0.5-3,1.4-3.9s2.2-1.4,3.9-1.4
            s3,0.5,4,1.4c0.9,0.9,1.4,2.2,1.4,3.9v12.6H619.9L619.9,288.5z"/>
          <path className="st7" d="M631.4,263.9v19.3c0,0.4,0.1,0.7,0.3,0.9c0.2,0.2,0.5,0.3,0.8,0.3h0.5v4.1h-1.5c-1.4,0-2.4-0.4-3.2-1.2
            c-0.8-0.8-1.1-2-1.1-3.5v-20L631.4,263.9L631.4,263.9z"/>
          <path className="st7" d="M640.4,288.8c-1.8,0-3.1-0.5-4.1-1.4s-1.5-2.3-1.5-4v-7c0-1.8,0.5-3.2,1.4-4.2c1-1,2.3-1.5,4-1.5
            s3.1,0.5,4.1,1.5s1.4,2.4,1.4,4.1v5h-7.5v-3.1h3.5V276c0-0.5-0.1-0.9-0.4-1.2s-0.6-0.4-1.1-0.4c-0.4,0-0.8,0.2-1,0.5
            s-0.4,0.8-0.4,1.3v7.4c0,0.5,0.1,0.9,0.4,1.1s0.6,0.4,1.1,0.4s0.8-0.1,1-0.3c0.2-0.2,0.4-0.5,0.4-0.9v-0.6h4.1v0.5
            c0,1.6-0.5,2.9-1.4,3.8S642.1,288.8,640.4,288.8L640.4,288.8z"/>
          <path className="st7" d="M653.5,288.8c-1.6,0-3-0.5-4-1.5s-1.5-2.3-1.5-3.9v-0.3h3.9v0.3c0,0.5,0.2,0.9,0.5,1.2s0.7,0.5,1.2,0.5
            s0.9-0.1,1.2-0.4s0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1s-0.7-0.5-1.1-0.7c-0.5-0.2-1-0.4-1.5-0.6s-0.9-0.4-1.4-0.7
            c-0.5-0.3-0.9-0.6-1.2-1c-0.4-0.4-0.7-0.9-0.9-1.5s-0.3-1.3-0.3-2.1c0-1.6,0.5-2.8,1.4-3.8s2.2-1.4,3.7-1.4s2.8,0.5,3.8,1.4
            s1.4,2.2,1.4,3.7v0.3h-3.9v-0.4c0-0.4-0.1-0.7-0.3-1s-0.5-0.4-0.9-0.4s-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1c0,0.5,0.1,0.8,0.4,1.1
            s0.6,0.5,1,0.7s0.9,0.4,1.3,0.5c0.5,0.2,1,0.4,1.5,0.6s0.9,0.5,1.3,0.9c0.4,0.4,0.7,0.9,1,1.5c0.2,0.6,0.4,1.4,0.4,2.3
            c0,1.6-0.5,2.9-1.5,3.9S655.1,288.8,653.5,288.8L653.5,288.8z"/>
        </g>
      </g>
    </>,
    title: "Regala Sonrisas en Fechas Especiales",
    content: <>
			<p className="mb-2">La Fundación constantemente realiza campañas en fechas especiales de impacto en el imaginario de nuestros infantes, adolescentes, adultos mayores, entre otros como el regreso a clase de los NNA, el mes del niño, día de la madre, día del padre, día de los niños, navidad, etc.</p>
      <p>Estas campañas se realizan en fechas especiales de acuerdo con el cronograma planeado por la Fundación.</p>
		</>,
    bg: "bg-[#A2BA68]",
    text: "text-white"
  },
  {
    button: <>
      <g className="E">
        <path className="st11" d="M917.2,430.3c4.8,21.7,7,43.8,8.4,66c0.6,9.7-1.7,13.3-12.3,13.1c-39.7-0.7-79.4-0.3-119-0.3
          s-80.2-0.2-120.3,0.2c-8,0.1-11-1.9-11.7-10.4c-2.2-28.1-13-53-30.9-74.5c-5.1-6.1-4.6-9.1,0.8-14.4
          c57.3-56.8,114.4-113.9,171.3-171.2c6.3-6.4,9.6-5.5,14.8,0.6c12.5,14.6,25.6,28.9,35.1,45.6c5.8,10.2,12.1,11.1,22.4,9
          c39.2-8.1,72.8,15.4,79.6,54.7c4.2,24.6-8,51.4-31.3,65.3C916.6,418.5,915.5,422.3,917.2,430.3L917.2,430.3z"/>
        <g>
          <path className="st6" d="M876.5,409.3v-90.7H892v90.7H876.5z M883.3,333.7v-15.1h33.4v15.1H883.3z M883.3,371.7v-15h30.3v15H883.3z
            M883.3,409.2v-15.1h33.4v15.1H883.3z"/>
          <path className="st7" d="M873.8,406.5v-90.7h15.5v90.7H873.8z M880.7,330.9v-15.1h33.4v15.1H880.7z M880.7,369v-15H911v15H880.7z
            M880.7,406.5v-15.1h33.4v15.1H880.7z"/>
        </g>
        <g>
          <path className="st7" d="M731.8,451.7l4.2-13.4h3.9V463h-3.8v-17.2l0.2,1.1l-3.6,11.8h-2.1l-3.6-11.4l0.3-1.4v17.2h-3.8v-24.7h4
            L731.8,451.7z"/>
          <path className="st7" d="M749.8,442.4v-4.2h4.2v4.2H749.8z M749.8,462.9v-17.6h4.2v17.6H749.8z"/>
          <path className="st7" d="M764,470.1v-24.8h4.2v24.8H764z M771.4,463.2c-0.8,0-1.5-0.3-2.1-0.8s-1-1.3-1.3-2.2l0.2-2.5
            c0,0.4,0,0.7,0.2,0.9s0.3,0.5,0.5,0.6s0.5,0.2,0.7,0.2c0.4,0,0.7-0.2,1-0.5c0.2-0.3,0.3-0.7,0.3-1.3v-7.1c0-0.5-0.1-0.9-0.3-1.2
            c-0.2-0.3-0.5-0.4-1-0.4c-0.3,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.5-0.2,0.9l-0.4-2.2c0.4-1,0.9-1.8,1.5-2.4
            s1.3-0.9,2.1-0.9c1.1,0,2,0.4,2.6,1.3s0.9,2,0.9,3.6v8.4c0,1.6-0.3,2.8-0.9,3.7S772.5,463.2,771.4,463.2L771.4,463.2z"/>
          <path className="st7" d="M778,462.9v-17.6h4.2v17.6H778z M786.4,449.5h-1.1c-1,0-1.8,0.3-2.3,0.9s-0.8,1.4-0.8,2.4l-0.4-4.4
            c0.5-1,1.2-1.9,1.9-2.5c0.8-0.6,1.7-0.9,2.6-0.9L786.4,449.5L786.4,449.5z"/>
          <path className="st7" d="M788.5,442.4v-4.2h4.2v4.2H788.5z M788.5,462.9v-17.6h4.2v17.6H788.5z"/>
          <path className="st7" d="M795.7,462.9v-17.6h4.3v17.6H795.7z M802.8,462.9v-12.4c0-0.5-0.1-1-0.4-1.3c-0.2-0.3-0.6-0.4-1.1-0.4
            s-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.3l-0.4-2.2c0.4-1.1,0.9-1.9,1.6-2.4s1.5-0.8,2.3-0.8c1.1,0,2,0.4,2.6,1.1s0.9,1.7,0.9,3V463
            L802.8,462.9L802.8,462.9z M809.9,462.9v-12.4c0-0.5-0.1-1-0.3-1.3s-0.6-0.4-1-0.4s-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.3
            l-0.3-2.3c0.4-1.1,0.9-1.9,1.6-2.4s1.5-0.8,2.3-0.8c1.1,0,2,0.4,2.6,1.2s0.9,1.9,0.9,3.3V463L809.9,462.9L809.9,462.9z"/>
          <path className="st7" d="M822.7,463.2c-1.8,0-3.1-0.5-4.1-1.4s-1.5-2.3-1.5-4v-7c0-1.8,0.5-3.2,1.4-4.2c1-1,2.3-1.5,4-1.5
            s3.1,0.5,4.1,1.5s1.4,2.4,1.4,4.1v5h-7.5v-3.1h3.5v-2.1c0-0.5-0.1-0.9-0.4-1.2s-0.6-0.4-1.1-0.4c-0.4,0-0.8,0.2-1,0.5
            s-0.4,0.8-0.4,1.3v7.4c0,0.5,0.1,0.9,0.4,1.1s0.6,0.4,1.1,0.4s0.8-0.1,1-0.3c0.2-0.2,0.4-0.5,0.4-0.9v-0.6h4.1v0.5
            c0,1.6-0.5,2.9-1.4,3.8S824.4,463.2,822.7,463.2L822.7,463.2z"/>
          <path className="st7" d="M831.1,462.9v-17.6h4.2v17.6H831.1z M839.5,449.5h-1.1c-1,0-1.8,0.3-2.3,0.9s-0.8,1.4-0.8,2.4l-0.4-4.4
            c0.5-1,1.2-1.9,1.9-2.5c0.8-0.6,1.7-0.9,2.6-0.9L839.5,449.5L839.5,449.5z"/>
          <path className="st7" d="M694.1,495.5c-1.8,0-3.1-0.5-4.1-1.4s-1.5-2.3-1.5-4v-7c0-1.8,0.5-3.2,1.4-4.2c1-1,2.3-1.5,4-1.5
            s3.1,0.5,4.1,1.5s1.4,2.4,1.4,4.1v5h-7.5v-3.1h3.5v-2.1c0-0.5-0.1-0.9-0.4-1.2s-0.6-0.4-1.1-0.4c-0.4,0-0.8,0.2-1,0.5
            s-0.4,0.8-0.4,1.3v7.4c0,0.5,0.1,0.9,0.4,1.1s0.6,0.4,1.1,0.4s0.8-0.1,1-0.3c0.2-0.2,0.4-0.5,0.4-0.9v-0.6h4.1v0.5
            c0,1.6-0.5,2.9-1.4,3.8S695.8,495.5,694.1,495.5L694.1,495.5z"/>
          <path className="st7" d="M702.5,495.3v-17.6h4.3v17.6H702.5z M709.6,495.3v-12.4c0-0.5-0.1-1-0.4-1.3c-0.2-0.3-0.6-0.4-1.1-0.4
            s-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.3l-0.4-2.2c0.4-1.1,0.9-1.9,1.6-2.4s1.5-0.8,2.3-0.8c1.1,0,2,0.4,2.6,1.1s0.9,1.7,0.9,3v13.8
            L709.6,495.3L709.6,495.3z M716.7,495.3v-12.4c0-0.5-0.1-1-0.3-1.3s-0.6-0.4-1-0.4s-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.3
            l-0.3-2.3c0.4-1.1,0.9-1.9,1.6-2.4s1.5-0.8,2.3-0.8c1.1,0,2,0.4,2.6,1.2s0.9,1.9,0.9,3.3v13.5L716.7,495.3L716.7,495.3z"/>
          <path className="st7" d="M724.3,502.4v-24.8h4.2v24.8H724.3z M731.7,495.5c-0.8,0-1.5-0.3-2.1-0.8s-1-1.3-1.3-2.2l0.2-2.5
            c0,0.4,0,0.7,0.2,0.9s0.3,0.5,0.5,0.6s0.5,0.2,0.7,0.2c0.4,0,0.7-0.2,1-0.5c0.2-0.3,0.3-0.7,0.3-1.3v-7.1c0-0.5-0.1-0.9-0.3-1.2
            c-0.2-0.3-0.5-0.4-1-0.4c-0.3,0-0.5,0.1-0.7,0.2s-0.4,0.3-0.5,0.5s-0.2,0.5-0.2,0.9l-0.4-2.2c0.4-1,0.9-1.8,1.5-2.4
            s1.3-0.9,2.1-0.9c1.1,0,2,0.4,2.6,1.3s0.9,2,0.9,3.6v8.4c0,1.6-0.3,2.8-0.9,3.7S732.9,495.5,731.7,495.5L731.7,495.5z"/>
          <path className="st7" d="M738.3,495.3v-17.6h4.2v17.6H738.3z M746.7,481.8h-1.1c-1,0-1.8,0.3-2.3,0.9s-0.8,1.4-0.8,2.4l-0.4-4.4
            c0.5-1,1.2-1.9,1.9-2.5c0.8-0.6,1.7-0.9,2.6-0.9L746.7,481.8L746.7,481.8z"/>
          <path className="st7" d="M754,495.5c-1.8,0-3.1-0.5-4.1-1.4s-1.5-2.3-1.5-4v-7c0-1.8,0.5-3.2,1.4-4.2c1-1,2.3-1.5,4-1.5
            s3.1,0.5,4.1,1.5s1.4,2.4,1.4,4.1v5h-7.5v-3.1h3.5v-2.1c0-0.5-0.1-0.9-0.4-1.2s-0.6-0.4-1.1-0.4c-0.4,0-0.8,0.2-1,0.5
            s-0.4,0.8-0.4,1.3v7.4c0,0.5,0.1,0.9,0.4,1.1s0.6,0.4,1.1,0.4s0.8-0.1,1-0.3c0.2-0.2,0.4-0.5,0.4-0.9v-0.6h4.1v0.5
            c0,1.6-0.5,2.9-1.4,3.8S755.8,495.5,754,495.5L754,495.5z"/>
          <path className="st7" d="M762.5,495.3v-17.6h4.2v17.6H762.5z M769.4,495.3v-12.4c0-0.5-0.1-1-0.3-1.3s-0.6-0.4-1-0.4s-0.8,0.1-1,0.4
            s-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-2,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2s0.9,1.9,0.9,3.3v13.5L769.4,495.3
            L769.4,495.3z"/>
          <path className="st7" d="M780,495.5c-1.1,0-2-0.4-2.5-1.3s-0.9-2-0.9-3.5v-8.6c0-1.5,0.3-2.7,0.9-3.5s1.5-1.2,2.5-1.2
            c0.8,0,1.5,0.3,2.1,0.8s1.2,1.3,1.5,2.4l-0.4,2.2c0-0.4,0-0.6-0.2-0.9c-0.1-0.2-0.3-0.4-0.5-0.5s-0.4-0.2-0.7-0.2
            c-0.4,0-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.2v7.4c0,0.5,0.1,0.9,0.4,1.1s0.6,0.4,1,0.4c0.3,0,0.5-0.1,0.7-0.2s0.3-0.3,0.5-0.5
            c0.1-0.2,0.2-0.5,0.2-0.9l0.2,2.4c-0.3,1-0.8,1.7-1.4,2.2C781.6,495.3,780.9,495.5,780,495.5L780,495.5z M783.4,495.3v-24.7h4.2
            v24.7H783.4z"/>
          <path className="st7" d="M790.6,474.8v-4.2h4.2v4.2H790.6z M790.6,495.3v-17.6h4.2v17.6H790.6z"/>
          <path className="st7" d="M797.8,495.3v-17.6h4.3v17.6H797.8z M804.9,495.3v-12.4c0-0.5-0.1-1-0.4-1.3c-0.2-0.3-0.6-0.4-1.1-0.4
            s-0.8,0.1-1,0.4s-0.4,0.7-0.4,1.3l-0.4-2.2c0.4-1.1,0.9-1.9,1.6-2.4s1.5-0.8,2.3-0.8c1.1,0,2,0.4,2.6,1.1s0.9,1.7,0.9,3v13.8
            L804.9,495.3L804.9,495.3z M812,495.3v-12.4c0-0.5-0.1-1-0.3-1.3s-0.6-0.4-1-0.4s-0.8,0.1-1.1,0.4c-0.2,0.3-0.4,0.7-0.4,1.3
            l-0.3-2.3c0.4-1.1,0.9-1.9,1.6-2.4s1.5-0.8,2.3-0.8c1.1,0,2,0.4,2.6,1.2s0.9,1.9,0.9,3.3v13.5L812,495.3L812,495.3z"/>
          <path className="st7" d="M819.3,474.8v-4.2h4.2v4.2H819.3z M819.3,495.3v-17.6h4.2v17.6H819.3z"/>
          <path className="st7" d="M831.7,495.5c-1.8,0-3.1-0.5-4.1-1.4s-1.5-2.3-1.5-4v-7c0-1.8,0.5-3.2,1.4-4.2c1-1,2.3-1.5,4-1.5
            s3.1,0.5,4.1,1.5s1.4,2.4,1.4,4.1v5h-7.5v-3.1h3.5v-2.1c0-0.5-0.1-0.9-0.4-1.2s-0.6-0.4-1.1-0.4c-0.4,0-0.8,0.2-1,0.5
            s-0.4,0.8-0.4,1.3v7.4c0,0.5,0.1,0.9,0.4,1.1s0.6,0.4,1.1,0.4s0.8-0.1,1-0.3c0.2-0.2,0.4-0.5,0.4-0.9v-0.6h4.1v0.5
            c0,1.6-0.5,2.9-1.4,3.8S833.4,495.5,831.7,495.5L831.7,495.5z"/>
          <path className="st7" d="M840.1,495.3v-17.6h4.2v17.6H840.1z M847,495.3v-12.4c0-0.5-0.1-1-0.3-1.3s-0.6-0.4-1-0.4s-0.8,0.1-1,0.4
            s-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-2,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2s0.9,1.9,0.9,3.3v13.5L847,495.3
            L847,495.3z"/>
          <path className="st7" d="M853.2,481.5v-3.9h8v3.9H853.2z M859.4,495.4c-1.6,0-2.8-0.4-3.5-1.2c-0.7-0.8-1.1-2-1.1-3.5v-18.2h4.2V490
            c0,0.4,0.1,0.7,0.3,0.9s0.4,0.3,0.8,0.3h1.2v4.1L859.4,495.4L859.4,495.4z"/>
          <path className="st7" d="M868.6,495.5c-1.7,0-3-0.4-3.9-1.3s-1.4-2-1.4-3.5v-8.6c0-1.5,0.5-2.7,1.4-3.5s2.2-1.2,3.9-1.2s3,0.4,3.9,1.2
            c0.9,0.8,1.4,2,1.4,3.5v8.7c0,1.5-0.5,2.7-1.4,3.5S870.3,495.5,868.6,495.5L868.6,495.5z M868.6,491.7c0.4,0,0.6-0.1,0.8-0.3
            s0.3-0.5,0.3-1v-8c0-0.4-0.1-0.7-0.3-0.9c-0.2-0.2-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.9v8c0,0.4,0.1,0.8,0.3,1
            C868,491.6,868.3,491.7,868.6,491.7z"/>
        </g>
        <path d="M797.8,389.4c0.1-1.2,0.3-2.4,0.4-3.7c0.2-3.4,0.3-6.8,0.6-10.2c0.5-7.2-0.6-14.1-3.5-20.8c-1.1-2.5-1.6-2.9-4.3-2.7
          c-2,0.1-3.8-0.3-5.6-1.1c-1.7-0.7-3.6-1.3-5.4-1.7c-2.8-0.6-4.1-3.6-2.4-5.9c0.5-0.6,0.5-1.2,0.1-1.8c-0.2-0.3-0.4-0.6-0.5-1
          c-0.9-2.2-0.7-3.8,1.1-5.3c1.3-1.2,2.7-2.2,4.2-3.2c1.7-1.3,3.4-2.6,5.3-3.8c2.3-1.4,4.1-1.3,6.1,0.5c3,2.6,6,5.1,8.9,7.8
          c1.1,1,2.2,2.3,2.8,3.6c5,11.4,10,22.9,14.9,34.4c3.3,7.7,3.7,15.8,2.9,24c-0.1,1.4-0.2,2.8-0.3,4.3c-0.1,2.1-1,3.8-2.9,4.7
          c-3,1.4-6.1,2.7-9.3,3.6c-7.8,2.4-16,3.3-24.1,3.5c-9.4,0.2-18.8,0.1-28.2,0c-4.1,0-7.9-1-11.3-3.4c-6.4-4.6-8.8-13.2-5.9-21
          c3.6-9.9,17.6-15,26.9-6.5c2.3,2.1,1.5,1.9,4.3,0.7c7.1-3,14-2.6,20.6,1.3c0.9,0.5,1.7,1.4,2.5,2.1c0.6,0.5,1.3,1,1.9,1.5
          C797.5,389.5,797.7,389.4,797.8,389.4z"/>
      </g>
    </>,
    title: "Mi primer emprendimiento",
    content: <>
			<p>La Fundación Arduvi consciente que el desarrollo de los emprendimientos ayuda a desarrollar los proyectos de vida de las personas y a generar empleo, lo que dinamiza la economía local y regional, te invita a apoyar con un capital semilla a las mujeres y hombres en extrema vulnerabilidad y riesgo social a iniciar e impulsar sus emprendimientos, bajo un acompañamiento de la Fundación en el área administrativa, contable, financiera y jurídica, así como una supervisión y rendición de cuentas de los recursos entregados.</p>
		</>,
    bg: "bg-[#4B9E86]",
    text: "text-white"
  },
  {
    button: <>
      <g className="I">
        <path className="st12" d="M964.4,672.6c-6.6,29.6-33.4,50.8-67.1,50.8c-1.6-0.2-6.9,0.5-10.8-1.5c-15-7.5-23.1-2.4-30.9,11.7
          c-11,19.8-26.4,36.7-41.6,53.5c-4.8,5.4-8,7-14.1,0.9C744,731.6,687.8,675.4,631.5,619.3c-5.2-5.1-4.8-8.1-0.4-13.5
          c18.1-22,29.1-47.2,31.3-75.8c0.6-8.1,4-9.6,11.3-9.6c80.7,0.2,161.3,0.2,242,0c8,0,10.7,2.3,9.7,10.2c-2.2,16.5-3.1,33.2-6.2,49.5
          c-1.8,9.6-0.5,13.9,9.2,18.1C956.9,610.6,971.2,641.7,964.4,672.6L964.4,672.6z"/>
        <g>
          <path className="st6" d="M921,618.4v90.7h-15.5v-90.7H921z"/>
          <path className="st7" d="M918.3,615.7v90.7h-15.5v-90.7H918.3z"/>
        </g>
        <g>
          <path className="st7" d="M698.7,550.3V575h-4.2v-24.7H698.7z"/>
          <path className="st7" d="M701.5,575v-17.6h4.2V575H701.5z M708.4,575v-12.4c0-0.5-0.1-1-0.3-1.3s-0.6-0.4-1-0.4s-0.8,0.1-1,0.4
            c-0.2,0.3-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-2,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2s0.9,1.9,0.9,3.3V575H708.4
            L708.4,575z"/>
          <path className="st7" d="M714.7,560.9v-3.6h8.5v3.6H714.7z M716.2,575v-20c0-1.5,0.4-2.7,1.1-3.5c0.7-0.8,1.9-1.2,3.5-1.2h2.3v3.9
            h-1.7c-0.4,0-0.6,0.1-0.8,0.4c-0.2,0.2-0.2,0.5-0.2,0.8v19.5L716.2,575L716.2,575z"/>
          <path className="st7" d="M724.1,575v-17.6h4.2V575H724.1z M732.6,561.5h-1.1c-1,0-1.8,0.3-2.3,0.9c-0.5,0.6-0.8,1.4-0.8,2.4l-0.4-4.4
            c0.5-1,1.2-1.9,1.9-2.5c0.8-0.6,1.7-0.9,2.6-0.9L732.6,561.5L732.6,561.5z"/>
          <path className="st7" d="M738.8,575.2c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3s2.4-1.5,4.3-1.5h1.3l0.2,3.3h-1.3
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.2s0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.5s0.5-0.8,0.5-1.4l0.3,1.5
            c0,0.8-0.2,1.5-0.4,2.1c-0.3,0.6-0.6,1-1.1,1.3S739.5,575.2,738.8,575.2L738.8,575.2z M741.8,575v-12.6c0-0.5-0.1-0.8-0.4-1.1
            s-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.1h-4c0-1.7,0.5-3,1.4-3.9s2.2-1.4,3.9-1.4s3,0.5,4,1.4
            c0.9,0.9,1.4,2.2,1.4,3.9V575H741.8L741.8,575z"/>
          <path className="st7" d="M754.4,575.2c-1.8,0-3.1-0.5-4.1-1.4s-1.5-2.3-1.5-4v-7c0-1.8,0.5-3.2,1.4-4.2c1-1,2.3-1.5,4-1.5
            s3.1,0.5,4.1,1.5s1.4,2.3,1.4,4.1v5h-7.5v-3.1h3.5v-2.1c0-0.5-0.1-0.9-0.4-1.2s-0.6-0.4-1.1-0.4c-0.4,0-0.8,0.2-1,0.5
            s-0.4,0.8-0.4,1.3v7.4c0,0.5,0.1,0.9,0.4,1.1s0.6,0.4,1.1,0.4s0.8-0.1,1-0.3s0.4-0.5,0.4-0.9v-0.6h4.1v0.5c0,1.6-0.5,2.9-1.4,3.8
            S756.1,575.2,754.4,575.2L754.4,575.2z"/>
          <path className="st7" d="M767.4,575.2c-1.6,0-3-0.5-4-1.5s-1.5-2.3-1.5-3.9v-0.3h3.9v0.3c0,0.5,0.2,0.9,0.5,1.2s0.7,0.5,1.2,0.5
            s0.9-0.1,1.2-0.4c0.3-0.3,0.4-0.7,0.4-1.1s-0.1-0.8-0.4-1.1s-0.7-0.5-1.1-0.7c-0.5-0.2-1-0.4-1.5-0.6c-0.5-0.2-0.9-0.4-1.4-0.7
            s-0.9-0.6-1.2-1c-0.4-0.4-0.7-0.9-0.9-1.5s-0.3-1.3-0.3-2.1c0-1.6,0.5-2.8,1.4-3.8c1-1,2.2-1.4,3.7-1.4s2.8,0.5,3.8,1.4
            s1.4,2.2,1.4,3.7v0.3h-3.9v-0.4c0-0.4-0.1-0.7-0.3-1s-0.5-0.4-0.9-0.4s-0.7,0.1-0.9,0.4c-0.2,0.3-0.3,0.6-0.3,1
            c0,0.5,0.1,0.8,0.4,1.1c0.3,0.3,0.6,0.5,1,0.7s0.9,0.4,1.3,0.5c0.5,0.2,1,0.4,1.5,0.6c0.5,0.2,0.9,0.5,1.3,0.9
            c0.4,0.4,0.7,0.9,1,1.5c0.2,0.6,0.4,1.4,0.4,2.3c0,1.6-0.5,2.9-1.5,3.8S769.1,575.3,767.4,575.2L767.4,575.2z"/>
          <path className="st7" d="M773.9,561.2v-3.8h8v3.8H773.9z M780.1,575c-1.6,0-2.8-0.4-3.5-1.2s-1.1-2-1.1-3.5v-18.2h4.2v17.5
            c0,0.4,0.1,0.7,0.3,0.9s0.4,0.3,0.8,0.3h1.2v4.1L780.1,575L780.1,575z"/>
          <path className="st7" d="M784.4,575v-17.6h4.2V575H784.4z M792.9,561.5h-1.1c-1,0-1.8,0.3-2.3,0.9c-0.5,0.6-0.8,1.4-0.8,2.4l-0.4-4.4
            c0.5-1,1.2-1.9,1.9-2.5c0.8-0.6,1.7-0.9,2.6-0.9L792.9,561.5L792.9,561.5z"/>
          <path className="st7" d="M799.5,557.3v13.1c0,0.5,0.1,0.8,0.3,1s0.5,0.3,1,0.3s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1l0.2,1.7
            c-0.3,1-0.7,1.8-1.2,2.3s-1.2,0.8-1.9,0.8c-1.3,0-2.2-0.4-2.8-1.2s-0.9-2-0.9-3.6v-13.1L799.5,557.3L799.5,557.3z M801.9,575
            v-17.6h4.2V575H801.9z"/>
          <path className="st7" d="M814.6,575.2c-1.8,0-3.1-0.4-4.1-1.3c-1-0.8-1.5-2-1.5-3.6v-8.2c0-1.6,0.5-2.9,1.5-3.8s2.3-1.3,4.1-1.3
            s3.1,0.5,4.1,1.3c1,0.9,1.5,2.2,1.5,3.8v1.5H816v-1.2c0-0.5-0.1-1-0.4-1.3s-0.6-0.5-1-0.5s-0.8,0.2-1,0.4s-0.4,0.7-0.4,1.2v7.5
            c0,0.5,0.1,0.8,0.4,1s0.6,0.4,1,0.4s0.8-0.1,1-0.4s0.3-0.6,0.3-1.1v-1.1h4.2v1.4c0,1.5-0.5,2.8-1.5,3.6
            C817.8,574.8,816.4,575.2,814.6,575.2L814.6,575.2z"/>
          <path className="st7" d="M821.2,561.2v-3.8h8v3.8H821.2z M827.3,575c-1.6,0-2.8-0.4-3.5-1.2s-1.1-2-1.1-3.5v-18.2h4.2v17.5
            c0,0.4,0.1,0.7,0.3,0.9s0.4,0.3,0.8,0.3h1.2v4.1L827.3,575L827.3,575z"/>
          <path className="st7" d="M835.8,557.3v13.1c0,0.5,0.1,0.8,0.3,1s0.5,0.3,1,0.3s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1l0.2,1.7
            c-0.3,1-0.7,1.8-1.2,2.3s-1.2,0.8-1.9,0.8c-1.3,0-2.2-0.4-2.8-1.2s-0.9-2-0.9-3.6v-13.1L835.8,557.3L835.8,557.3z M838.3,575
            v-17.6h4.2V575H838.3z"/>
          <path className="st7" d="M845.8,575v-17.6h4.2V575H845.8z M854.3,561.5h-1.1c-1,0-1.8,0.3-2.3,0.9c-0.5,0.6-0.8,1.4-0.8,2.4l-0.4-4.4
            c0.5-1,1.2-1.9,1.9-2.5c0.8-0.6,1.7-0.9,2.6-0.9L854.3,561.5L854.3,561.5z"/>
          <path className="st7" d="M860.5,575.2c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3s2.4-1.5,4.3-1.5h1.3l0.2,3.3h-1.3
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.2s0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.5s0.5-0.8,0.5-1.4l0.3,1.5
            c0,0.8-0.2,1.5-0.4,2.1c-0.3,0.6-0.6,1-1.1,1.3S861.2,575.2,860.5,575.2L860.5,575.2z M863.5,575v-12.6c0-0.5-0.1-0.8-0.4-1.1
            s-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.1h-4c0-1.7,0.5-3,1.4-3.9s2.2-1.4,3.9-1.4s3,0.5,4,1.4
            c0.9,0.9,1.4,2.2,1.4,3.9V575H863.5L863.5,575z"/>
          <path className="st7" d="M718.5,607.5c-1.2,0-2.3-0.2-3.2-0.7s-1.6-1.2-2-2.2c-0.5-0.9-0.7-2.1-0.7-3.4v-12.6c0-1.3,0.2-2.5,0.7-3.4
            s1.2-1.7,2-2.1s2-0.7,3.2-0.7s2.3,0.2,3.2,0.7s1.6,1.2,2,2.2s0.7,2.1,0.7,3.4v2h-4.3v-2c0-0.4-0.1-0.8-0.2-1.1
            c-0.1-0.3-0.3-0.5-0.6-0.7s-0.5-0.2-0.9-0.2c-0.5,0-0.9,0.2-1.2,0.5c-0.3,0.4-0.4,0.8-0.4,1.5v12.6c0,0.6,0.2,1.1,0.4,1.5
            s0.7,0.5,1.2,0.5s1-0.2,1.2-0.5c0.3-0.4,0.4-0.8,0.4-1.5v-2h4.3v2c0,1.3-0.2,2.5-0.7,3.4s-1.1,1.7-2,2.2
            C720.9,607.3,719.8,607.6,718.5,607.5L718.5,607.5z"/>
          <path className="st7" d="M732.2,607.5c-1.7,0-3-0.4-3.9-1.3s-1.4-2-1.4-3.5V594c0-1.5,0.5-2.7,1.4-3.5s2.2-1.2,3.9-1.2s3,0.4,3.9,1.2
            s1.4,2,1.4,3.5v8.7c0,1.5-0.5,2.7-1.4,3.5S733.9,607.5,732.2,607.5L732.2,607.5z M732.2,603.8c0.4,0,0.6-0.1,0.8-0.3
            s0.3-0.5,0.3-1v-8c0-0.4-0.1-0.7-0.3-0.9s-0.5-0.3-0.8-0.3s-0.6,0.1-0.8,0.3s-0.3,0.5-0.3,0.9v8c0,0.4,0.1,0.8,0.3,1
            C731.6,603.6,731.9,603.8,732.2,603.8z"/>
          <path className="st7" d="M740.5,607.3v-17.6h4.3v17.6H740.5z M747.6,607.3v-12.4c0-0.5-0.1-1-0.4-1.3c-0.2-0.3-0.6-0.4-1.1-0.4
            s-0.8,0.1-1,0.4c-0.2,0.3-0.4,0.7-0.4,1.3l-0.4-2.2c0.4-1.1,0.9-1.9,1.6-2.4s1.5-0.8,2.3-0.8c1.1,0,2,0.3,2.6,1.1s0.9,1.7,0.9,3
            v13.8L747.6,607.3L747.6,607.3z M754.7,607.3v-12.4c0-0.5-0.1-1-0.3-1.3s-0.6-0.4-1-0.4s-0.8,0.1-1.1,0.4
            c-0.2,0.3-0.4,0.7-0.4,1.3l-0.3-2.3c0.4-1.1,0.9-1.9,1.6-2.4c0.7-0.5,1.5-0.8,2.3-0.8c1.1,0,2,0.4,2.6,1.2s0.9,1.9,0.9,3.3v13.5
            L754.7,607.3L754.7,607.3z"/>
          <path className="st7" d="M766.4,589.7v13.1c0,0.5,0.1,0.8,0.3,1s0.5,0.3,1,0.3s0.7-0.1,0.9-0.4s0.3-0.6,0.3-1l0.2,1.7
            c-0.3,1-0.7,1.8-1.2,2.3s-1.2,0.8-1.9,0.8c-1.3,0-2.2-0.4-2.8-1.2s-0.9-2-0.9-3.6v-13.1L766.4,589.7L766.4,589.7z M768.9,607.3
            v-17.6h4.2v17.6H768.9z"/>
          <path className="st7" d="M776.4,607.3v-17.6h4.2v17.6H776.4z M783.3,607.3v-12.4c0-0.5-0.1-1-0.3-1.3s-0.6-0.4-1-0.4s-0.8,0.1-1,0.4
            c-0.2,0.3-0.3,0.7-0.3,1.3l-0.4-2.1c0.4-1.1,0.9-2,1.6-2.5s1.4-0.9,2.2-0.9c1.1,0,1.9,0.4,2.5,1.2s0.9,1.9,0.9,3.3v13.5
            L783.3,607.3L783.3,607.3z"/>
          <path className="st7" d="M790.6,586.8v-4.2h4.2v4.2H790.6z M790.6,607.3v-17.6h4.2v17.6H790.6z"/>
          <path className="st7" d="M796.5,593.5v-3.8h8v3.8H796.5z M802.6,607.4c-1.6,0-2.8-0.4-3.5-1.2s-1.1-2-1.1-3.5v-18.2h4.2V602
            c0,0.4,0.1,0.7,0.3,0.9s0.4,0.3,0.8,0.3h1.2v4.1L802.6,607.4L802.6,607.4z"/>
          <path className="st7" d="M810.7,607.5c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3s2.4-1.5,4.3-1.5h1.3l0.2,3.3h-1.3
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.2s0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.5s0.5-0.8,0.5-1.4l0.3,1.5
            c0,0.8-0.2,1.5-0.4,2.1c-0.3,0.6-0.6,1-1.1,1.3S811.4,607.6,810.7,607.5L810.7,607.5z M813.8,607.3v-12.6c0-0.5-0.1-0.8-0.4-1.1
            s-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.1h-4c0-1.7,0.5-3,1.4-3.9s2.2-1.4,3.9-1.4s3,0.5,4,1.4
            c0.9,0.9,1.4,2.2,1.4,3.9v12.6H813.8L813.8,607.3z"/>
          <path className="st7" d="M821.1,607.3v-17.6h4.2v17.6H821.1z M829.5,593.8h-1.1c-1,0-1.8,0.3-2.3,0.9c-0.5,0.6-0.8,1.4-0.8,2.4
            l-0.4-4.4c0.5-1,1.2-1.9,1.9-2.5c0.8-0.6,1.7-0.9,2.6-0.9L829.5,593.8L829.5,593.8z"/>
          <path className="st7" d="M831.6,586.8v-4.2h4.2v4.2H831.6z M831.6,607.3v-17.6h4.2v17.6H831.6z"/>
          <path className="st7" d="M842.7,607.5c-1.3,0-2.3-0.4-3-1.3s-1-2.1-1-3.8c0-1.9,0.5-3.3,1.5-4.3s2.4-1.5,4.3-1.5h1.3l0.2,3.3h-1.3
            c-0.7,0-1.2,0.2-1.5,0.6s-0.5,1-0.5,1.8c0,0.5,0.1,0.9,0.3,1.2s0.5,0.4,0.9,0.4c0.6,0,1-0.2,1.3-0.5s0.5-0.8,0.5-1.4l0.3,1.5
            c0,0.8-0.2,1.5-0.4,2.1c-0.3,0.6-0.6,1-1.1,1.3S843.3,607.6,842.7,607.5L842.7,607.5z M845.7,607.3v-12.6c0-0.5-0.1-0.8-0.4-1.1
            s-0.6-0.4-1.1-0.4c-0.4,0-0.7,0.1-0.9,0.4s-0.3,0.6-0.3,1.1h-4c0-1.7,0.5-3,1.4-3.9s2.2-1.4,3.9-1.4s3,0.5,4,1.4
            c0.9,0.9,1.4,2.2,1.4,3.9v12.6H845.7L845.7,607.3z"/>
        </g>
        <path d="M837.6,682.7c-0.4,0.7-1,0.9-1.8,0.9c-3.7,0-7.3,0-11,0c-1.3,0-1.7-0.4-1.7-1.7c0-1.5,0-3.1,0-4.6c0-2.2-1.7-3.9-3.8-3.9
          s-3.8,1.7-3.8,3.9c0,1.6,0,3.1,0,4.7c0,1.1-0.5,1.6-1.6,1.6c-3.7,0-7.5,0-11.2,0c-1.2,0-1.6-0.4-1.6-1.7c0-3.4,0-6.7,0-10.2
          c-1.3,0-2.7,0-4,0c-2.5-0.1-4.4-1.3-5.5-3.5c-2.1-4.1,0.8-9,5.5-9.2c1.3,0,2.6,0,4,0c0-0.3,0-0.6,0-0.9c0-3.1,0-6.1,0-9.2
          c0-0.8,0.2-1.4,0.9-1.8c10.7,0,21.3,0,32,0c1.9,0.6,3.1,1.8,3.7,3.7C837.6,661.4,837.6,672.1,837.6,682.7z"/>
        <path d="M750.6,700c3.9,0,7.9,0,11.9,0c0,1.3,0,2.6,0,3.9c0.1,2.8,2,5.3,4.7,6s5.7-0.4,7.1-2.9c0.5-1,0.8-2.1,0.9-3.2
          c0.2-1.2,0-2.5,0-3.8c3.1,0,6.2,0,9.4,0c0,0.3,0,0.6,0,0.9c0,3.1,0,6.2,0,9.2c0,1.3,0.4,1.7,1.7,1.7c1.5,0,3.1,0,4.6,0
          c2.2,0,3.9,1.7,3.9,3.8s-1.7,3.8-3.9,3.8c-1.6,0-3.1,0-4.7,0c-1.1,0-1.6,0.5-1.6,1.6c0,3.9,0,7.9,0,11.8c0,0.3,0,0.6,0,1
          c-0.4,0-0.7,0-1,0c-9,0-18-0.1-27,0c-2.9,0-5-0.9-5.9-3.8C750.6,720.1,750.6,710.1,750.6,700z"/>
        <path d="M750.6,667.3c0.1-0.3,0.2-0.5,0.3-0.8c0.8-1.8,2.2-2.9,4.2-2.9c9.7,0,19.4,0,29.1,0c0.1,0,0.2,0,0.3,0c0,3.9,0,7.8,0,11.8
          c-1.4,0-2.7,0-4.1,0c-3.1,0.1-5.7,2.6-6.1,5.7c-0.3,3.1,1.7,6,4.7,6.8c0.7,0.2,1.3,0.2,2,0.2c1.1,0,2.2,0,3.4,0c0,3.1,0,6.2,0,9.3
          c-0.3,0-0.6,0-0.9,0c-3.1,0-6.2,0-9.3,0c-1.3,0-1.7,0.4-1.7,1.7c0,1.5,0,3.1,0,4.6c0,2.2-1.7,3.9-3.8,3.9s-3.8-1.7-3.8-3.9
          c0-1.4,0-2.8,0-4.2c0-1.7-0.3-2-2-2c-4.2,0-8.3,0-12.5,0C750.6,687.4,750.6,677.3,750.6,667.3z"/>
        <path d="M787.1,709.3c0-3.2,0-6.2,0-9.3c0.3,0,0.6,0,0.9,0c3.1,0,6.1,0,9.2,0c1.4,0,1.7-0.4,1.8-1.8c0-1.5,0-2.9,0-4.4
          c0-2.3,1.7-4,3.8-4s3.8,1.7,3.8,4c0,1.5,0,3.1,0,4.6c0,1.2,0.4,1.6,1.6,1.6c3.9,0,7.9,0,11.8,0c0.3,0,0.6,0,1,0c0,0.4,0,0.7,0,1
          c0,9.3,0,18.5,0,27.8c0,3.3-1.9,5.1-5.2,5.1c-9.3,0-18.6,0-27.9,0c-0.3,0-0.6,0-0.9,0c0-4,0-7.9,0-11.9c1.3,0,2.6,0,3.9,0
          c3.2-0.1,5.8-2.5,6.2-5.6c0.4-3-1.6-6-4.6-6.8c-1.3-0.3-2.8-0.2-4.2-0.3C788.1,709.3,787.6,709.3,787.1,709.3z"/>
      </g>
    </>,
    title: "Recuperemos infraestructura comunitaria desde el amor",
    content: <>
			<p className="mb-2">Nuestro trabajo no solo es generar recursos por apoyar el ejercicio de RSE, sino que nuestro fin es transformar entornos, vidas de familias con capacidades especiales, familias con mujeres cabeza de hogar sobrevivientes de violencias de género o familias con extrema vulnerabilidad social, familias migrantes retornadas y familias de acogida.</p>
      <p className="mb-2">Teniendo en cuenta lo anterior, te invitamos a hacer parte de esta transformación a través de donaciones enfocadas al mejoramiento y/o embellecimiento del entorno donde habitan las familias que la Fundación se encuentre interviniendo. Con tu ayuda se pueden mejorar o realizar infraestructuras comunitarias que brinden lugares seguros para el desarrollo de la accesibilidad, recreación, el deporte, el arte, la cultura, la educación y la conectividad, entre otros.</p>
      <p className="mb-2">Todas estas acciones tanto para las personas, así como para los territorios, cuentan con el acompañamiento técnico, legal, jurídico y psicosocial de nuestra Fundación, lo que te permitirá reducir el pago de los impuestos tu empresa y/o organización.</p>
		</>,
    bg: "bg-[#457FB3]",
    text: "text-white"
  },
  {
    button: <>
      <g className="bombillo">
        <path className="st0" d="M784.4,814.5c-27.6,21.7-52,46.5-72.1,75.2c-31.2,44.5-62.4,155.7-63.4,159.9c-8.5-0.1-16.9-0.1-25.3-0.2
          l-2.7,25.9H419.6l-3-25.6c-9.4,0.1-18.9,0.2-28.3,0.3c-7.5-50.7-23.4-98.5-50.6-142.2c-21.6-34.6-48.1-64.9-80.6-89.8
          c-0.7-0.5-1.5-0.8-2.2-1.3c-16.3-12.4-16.5-14.8-2.3-29.1c52.8-52.9,105.8-105.7,158.4-158.9c6.8-6.9,10.2-8.1,19.2-1.3
          c50.6,38.2,110.1,42.7,166.9,7.6c21.3-13.2,21-13.7,38.8,4.1c50.1,50.2,100.2,100.4,150.3,150.6
          C798.1,801.7,798.4,803.5,784.4,814.5L784.4,814.5z"/>
        <path className="st1" d="M388.1,1048.3c26.2-0.3,232.3,1.1,260.8,1.3c0,4.7,0.4,9.4,0.1,14.1c-0.4,6.4,1.2,10.4,8.5,11.5
          c9.1,1.4,14.6,7.5,14.4,16.8c-0.2,9.4-6,14.9-15.1,16.2c-6.9,0.9-7.9,5-7.8,10.8c0,5.7,2,8.6,7.9,9.4c9.3,1.2,14.9,6.8,15.1,16.2
          c0.2,9.4-5.5,15.2-14.5,16.8c-5.9,1.1-7.8,3.9-7.7,9.5c0.1,5.9,1.7,9.8,8.2,10.9c9.8,1.7,15.4,7.6,14.8,17.7
          c-0.5,10.2-7.4,14.9-16.9,15.9c-4.1,0.4-5.6,1.9-6.8,5.9c-4,14.5-14.6,20-29,19.9c-8.1-0.1-16.2,0.5-24.2,0.8
          c-50.5-0.1-101-0.2-151.6-0.2c-9.4-0.2-18.7-0.3-28.1-0.6c-12.8-0.4-22.3-6-26-18.7c-1.4-5-3.5-6.7-8.6-7.1
          c-9.1-0.8-15.2-6.5-15.9-15.6c-0.6-9.9,5.3-15.9,15-17.6c5.5-1,8.2-3.5,8.1-9.4c-0.1-5.6-0.9-10-7.8-10.9
          c-9.2-1.3-15.1-7.2-15.2-16.3c-0.1-9.7,5.9-15.7,15.8-17c6.1-0.8,7.1-5,7.2-10.2c0-5.4-1.8-9-7.6-9.8c-9.9-1.4-15.6-7.6-15.4-17.3
          c0.2-9.2,6.4-14.8,15.5-16c6.2-0.8,7.7-4.3,7.5-9.7C388.5,1060.3,388.5,1053.5,388.1,1048.3L388.1,1048.3z"/>
        <path className="st2" d="M444.1,1241.5c50.5,0.1,101.1,0.2,151.6,0.2c0.5,29.1-13.4,43.3-42.5,43.4c-21.7,0.1-43.5,0-65.2,0
          C457.9,1285.1,444.3,1271.6,444.1,1241.5L444.1,1241.5z"/>
      </g>
      <g>
        <path d="M342.38,787.33h4.53v28.4h-4.32v-19.82l.29,1.25-4.12,13.55h-2.42l-4.12-13.16.39-1.64v19.82h-4.41v-28.4h4.65l4.71,15.43,4.82-15.43Z"/>
        <path d="M352.96,815.36c-.91-.44-1.6-1.07-2.08-1.89-.48-.83-.72-1.82-.72-2.98v-9.96c0-1.15.24-2.12.72-2.94.48-.81,1.17-1.43,2.08-1.86.9-.42,2-.63,3.27-.63s2.37.21,3.28.63c.91.42,1.6,1.04,2.08,1.86.48.81.71,1.79.71,2.94v10.02c0,1.15-.24,2.13-.71,2.95-.48.82-1.17,1.45-2.08,1.88-.91.43-2.01.64-3.28.64s-2.37-.22-3.27-.65ZM357.19,811.27c.22-.26.33-.64.33-1.13v-9.24c0-.47-.11-.83-.33-1.08-.22-.25-.54-.38-.96-.38-.39,0-.7.13-.94.39-.23.26-.35.62-.35,1.07v9.24c0,.5.11.87.34,1.13.23.26.54.39.95.39s.74-.13.96-.39Z"/>
        <path d="M367.14,815.41c-.59-.43-1.04-1.06-1.36-1.88-.31-.83-.47-1.82-.47-2.98v-9.94c0-1.16.16-2.15.48-2.97.32-.82.77-1.45,1.37-1.88.59-.43,1.31-.64,2.14-.64.89,0,1.7.32,2.45.97.75.64,1.34,1.55,1.79,2.71l-.41,2.54c0-.39-.06-.72-.18-1s-.29-.48-.52-.62c-.23-.14-.5-.21-.83-.21-.5,0-.88.16-1.15.49-.27.33-.41.79-.41,1.39v8.48c0,.57.14,1.02.41,1.33s.66.47,1.15.47c.33,0,.6-.07.83-.21.23-.14.4-.34.52-.62s.18-.61.18-1l.27,2.71c-.34,1.11-.87,1.97-1.58,2.59-.72.62-1.56.93-2.52.93-.85,0-1.57-.21-2.16-.64ZM373.13,787.33h4.84v28.4h-4.84v-28.4Z"/>
        <path d="M384.37,815.29c-.96-.49-1.69-1.2-2.2-2.13-.51-.93-.76-2.05-.76-3.37v-8.09c0-1.39.25-2.58.74-3.57s1.21-1.74,2.14-2.26c.93-.51,2.05-.77,3.37-.77s2.48.25,3.43.75c.94.5,1.67,1.23,2.17,2.2.5.96.75,2.13.75,3.5v5.74h-8.69v-3.54h4v-2.42c0-.6-.15-1.07-.44-1.41-.29-.34-.7-.51-1.22-.51s-.88.18-1.14.54c-.27.36-.4.86-.4,1.51v8.48c0,.56.15.99.44,1.29.29.3.71.45,1.26.45.51,0,.9-.12,1.17-.37.27-.25.41-.6.41-1.05v-.7l4.67.04v.57c0,1.86-.55,3.3-1.64,4.33-1.09,1.02-2.63,1.53-4.61,1.53-1.34,0-2.49-.24-3.45-.73Z"/>
        <path d="M402.35,809.57c0,.44.11.79.34,1.03.23.24.54.36.95.36h.61v4.77h-1.78c-1.56,0-2.78-.47-3.65-1.41-.87-.94-1.31-2.26-1.31-3.96v-23.03h4.84v22.25Z"/>
        <path d="M409.11,815.36c-.91-.44-1.6-1.07-2.08-1.89-.48-.83-.72-1.82-.72-2.98v-9.96c0-1.15.24-2.12.72-2.94.48-.81,1.17-1.43,2.08-1.86.9-.42,2-.63,3.27-.63s2.37.21,3.28.63c.91.42,1.6,1.04,2.08,1.86.48.81.71,1.79.71,2.94v10.02c0,1.15-.24,2.13-.71,2.95-.48.82-1.17,1.45-2.08,1.88-.91.43-2.01.64-3.28.64s-2.37-.22-3.27-.65ZM413.34,811.27c.22-.26.33-.64.33-1.13v-9.24c0-.47-.11-.83-.33-1.08-.22-.25-.54-.38-.96-.38-.39,0-.7.13-.94.39-.23.26-.35.62-.35,1.07v9.24c0,.5.11.87.34,1.13.23.26.54.39.95.39s.74-.13.96-.39Z"/>
        <path d="M429.15,815.41c-.59-.43-1.04-1.06-1.36-1.88-.31-.83-.47-1.82-.47-2.98v-9.94c0-1.16.16-2.15.48-2.97.32-.82.77-1.45,1.37-1.88.59-.43,1.31-.64,2.14-.64.89,0,1.7.32,2.45.97.75.64,1.34,1.55,1.79,2.71l-.41,2.54c0-.39-.06-.72-.18-1s-.29-.48-.52-.62c-.23-.14-.5-.21-.83-.21-.5,0-.88.16-1.15.49-.27.33-.41.79-.41,1.39v8.48c0,.57.14,1.02.41,1.33s.66.47,1.15.47c.33,0,.6-.07.83-.21.23-.14.4-.34.52-.62s.18-.61.18-1l.27,2.71c-.34,1.11-.87,1.97-1.58,2.59-.72.62-1.56.93-2.52.93-.85,0-1.57-.21-2.16-.64ZM435.14,787.33h4.84v28.4h-4.84v-28.4Z"/>
        <path d="M446.38,815.29c-.96-.49-1.69-1.2-2.2-2.13-.51-.93-.76-2.05-.76-3.37v-8.09c0-1.39.25-2.58.74-3.57s1.21-1.74,2.14-2.26c.93-.51,2.05-.77,3.37-.77s2.48.25,3.43.75c.94.5,1.67,1.23,2.17,2.2.5.96.75,2.13.75,3.5v5.74h-8.69v-3.54h4v-2.42c0-.6-.15-1.07-.44-1.41-.29-.34-.7-.51-1.22-.51s-.88.18-1.14.54c-.27.36-.4.86-.4,1.51v8.48c0,.56.15.99.44,1.29.29.3.71.45,1.26.45.51,0,.9-.12,1.17-.37.27-.25.41-.6.41-1.05v-.7l4.67.04v.57c0,1.86-.55,3.3-1.64,4.33-1.09,1.02-2.63,1.53-4.61,1.53-1.34,0-2.49-.24-3.45-.73Z"/>
        <path d="M469.63,815.72h-4.84v-28.4h4.84v28.4Z"/>
        <path d="M472.91,795.45h4.88v20.27h-4.88v-20.27ZM480.88,801.41c0-.64-.13-1.12-.39-1.46-.26-.33-.65-.5-1.17-.5s-.89.16-1.14.49c-.25.33-.38.81-.38,1.45l-.41-2.4c.42-1.25,1.01-2.21,1.79-2.88.77-.67,1.64-1.01,2.59-1.01,1.25,0,2.22.45,2.92,1.35.7.9,1.04,2.16,1.04,3.79v15.49h-4.84v-14.32Z"/>
        <path d="M488.03,795.45h9.18v4.43h-9.18v-4.43ZM491.07,814.46c-.85-.9-1.28-2.24-1.28-4.01v-20.9h4.82v20.12c0,.44.11.79.32,1.03.21.24.51.36.89.36h1.39v4.77h-2.13c-1.82,0-3.16-.45-4.01-1.36Z"/>
        <path d="M502.63,815.29c-.96-.49-1.69-1.2-2.2-2.13-.51-.93-.76-2.05-.76-3.37v-8.09c0-1.39.25-2.58.74-3.57s1.21-1.74,2.14-2.26c.93-.51,2.05-.77,3.37-.77s2.48.25,3.43.75c.94.5,1.67,1.23,2.17,2.2.5.96.75,2.13.75,3.5v5.74h-8.69v-3.54h4v-2.42c0-.6-.15-1.07-.44-1.41-.29-.34-.7-.51-1.22-.51s-.88.18-1.14.54c-.27.36-.4.86-.4,1.51v8.48c0,.56.15.99.44,1.29.29.3.71.45,1.26.45.51,0,.9-.12,1.17-.37.27-.25.41-.6.41-1.05v-.7l4.67.04v.57c0,1.86-.55,3.3-1.64,4.33-1.09,1.02-2.63,1.53-4.61,1.53-1.34,0-2.49-.24-3.45-.73Z"/>
        <path d="M515.76,795.45h4.84v20.27h-4.84v-20.27ZM524.92,800.22h-.68c-1.15,0-2.04.35-2.68,1.04-.64.69-.96,1.63-.96,2.81l-.41-5.08c.59-1.21,1.34-2.16,2.26-2.85.92-.69,1.92-1.04,3.02-1.04v5.12h-.55Z"/>
        <path d="M536.54,795.45h4.73l-5.29,20.27h-3.75l-5.33-20.27h4.71l2.5,13.4,2.44-13.4Z"/>
        <path d="M545.91,815.29c-.96-.49-1.69-1.2-2.2-2.13-.51-.93-.76-2.05-.76-3.37v-8.09c0-1.39.25-2.58.74-3.57s1.21-1.74,2.14-2.26c.93-.51,2.05-.77,3.37-.77s2.48.25,3.43.75c.94.5,1.67,1.23,2.17,2.2.5.96.75,2.13.75,3.5v5.74h-8.69v-3.54h4v-2.42c0-.6-.15-1.07-.44-1.41-.29-.34-.7-.51-1.22-.51s-.88.18-1.14.54c-.27.36-.4.86-.4,1.51v8.48c0,.56.15.99.44,1.29.29.3.71.45,1.26.45.51,0,.9-.12,1.17-.37.27-.25.41-.6.41-1.05v-.7l4.67.04v.57c0,1.86-.55,3.3-1.64,4.33-1.09,1.02-2.63,1.53-4.61,1.53-1.34,0-2.49-.24-3.45-.73Z"/>
        <path d="M559.04,795.45h4.88v20.27h-4.88v-20.27ZM567.01,801.41c0-.64-.13-1.12-.39-1.46-.26-.33-.65-.5-1.17-.5s-.89.16-1.14.49c-.25.33-.38.81-.38,1.45l-.41-2.4c.42-1.25,1.01-2.21,1.79-2.88.77-.67,1.64-1.01,2.59-1.01,1.25,0,2.22.45,2.92,1.35.7.9,1.04,2.16,1.04,3.79v15.49h-4.84v-14.32Z"/>
        <path d="M578.22,815.36c-.95-.44-1.68-1.07-2.18-1.91-.5-.84-.75-1.85-.75-3.02v-9.45c0-1.24.25-2.29.75-3.17.5-.88,1.23-1.55,2.18-2.01.95-.46,2.1-.69,3.44-.69s2.49.23,3.46.69c.96.46,1.7,1.14,2.2,2.02.5.89.75,1.95.75,3.2v1.76h-4.77v-1.33c0-.64-.14-1.13-.41-1.48s-.66-.53-1.17-.53-.88.17-1.15.52c-.27.35-.41.82-.41,1.42v8.67c0,.52.14.92.41,1.21.27.29.66.43,1.15.43s.9-.15,1.16-.46c.27-.31.4-.74.4-1.3v-1.23l4.82.06v1.6c0,1.19-.25,2.2-.76,3.05-.51.85-1.24,1.49-2.21,1.94-.96.45-2.12.67-3.48.67s-2.49-.22-3.44-.65Z"/>
        <path d="M590.41,787.33h4.84v4.8h-4.84v-4.8ZM590.41,795.45h4.84v20.27h-4.84v-20.27Z"/>
        <path d="M601.11,815.36c-.91-.44-1.6-1.07-2.08-1.89-.48-.83-.72-1.82-.72-2.98v-9.96c0-1.15.24-2.12.72-2.94.48-.81,1.17-1.43,2.08-1.86.9-.42,2-.63,3.27-.63s2.37.21,3.28.63c.91.42,1.6,1.04,2.08,1.86.48.81.71,1.79.71,2.94v10.02c0,1.15-.24,2.13-.71,2.95-.48.82-1.17,1.45-2.08,1.88-.91.43-2.01.64-3.28.64s-2.37-.22-3.27-.65ZM601.49,790.08l4.8-6.93,3.63,2.89-5.18,6.56-3.26-2.52ZM605.33,811.27c.22-.26.33-.64.33-1.13v-9.24c0-.47-.11-.83-.33-1.08-.22-.25-.54-.38-.96-.38-.39,0-.7.13-.94.39-.23.26-.35.62-.35,1.07v9.24c0,.5.11.87.34,1.13.23.26.54.39.95.39s.74-.13.96-.39Z"/>
        <path d="M613.89,795.45h4.88v20.27h-4.88v-20.27ZM621.86,801.41c0-.64-.13-1.12-.39-1.46-.26-.33-.65-.5-1.17-.5s-.89.16-1.14.49c-.25.33-.38.81-.38,1.45l-.41-2.4c.42-1.25,1.01-2.21,1.79-2.88.77-.67,1.64-1.01,2.59-1.01,1.25,0,2.22.45,2.92,1.35.7.9,1.04,2.16,1.04,3.79v15.49h-4.84v-14.32Z"/>
        <path d="M641.64,787.33h3.4l6.78,28.4h-4.8l-3.67-18.61-3.67,18.61h-4.8l6.78-28.4ZM639.47,806.78h7.73v4.53h-7.73v-4.53Z"/>
        <path d="M654.12,795.45h4.84v20.27h-4.84v-20.27ZM663.28,800.22h-.68c-1.15,0-2.04.35-2.68,1.04-.64.69-.96,1.63-.96,2.81l-.41-5.08c.59-1.21,1.34-2.16,2.26-2.85.92-.69,1.92-1.04,3.02-1.04v5.12h-.55Z"/>
        <path d="M667.63,815.41c-.59-.43-1.04-1.06-1.36-1.88-.31-.83-.47-1.82-.47-2.98v-9.94c0-1.16.16-2.15.48-2.97.32-.82.77-1.45,1.37-1.88.59-.43,1.31-.64,2.14-.64.89,0,1.7.32,2.45.97.75.64,1.34,1.55,1.79,2.71l-.41,2.54c0-.39-.06-.72-.18-1s-.29-.48-.52-.62c-.23-.14-.5-.21-.83-.21-.5,0-.88.16-1.15.49-.27.33-.41.79-.41,1.39v8.48c0,.57.14,1.02.41,1.33s.66.47,1.15.47c.33,0,.6-.07.83-.21.23-.14.4-.34.52-.62s.18-.61.18-1l.27,2.71c-.34,1.11-.87,1.97-1.58,2.59-.72.62-1.56.93-2.52.93-.85,0-1.57-.21-2.16-.64ZM673.62,787.33h4.84v28.4h-4.84v-28.4Z"/>
        <path d="M687.11,810.55c0,.52.12.91.35,1.15.23.25.6.37,1.09.35.47.01.82-.11,1.05-.38.23-.27.35-.67.35-1.2l.27,1.97c-.33,1.2-.79,2.09-1.39,2.69-.6.59-1.34.89-2.23.89-1.45,0-2.52-.45-3.23-1.36-.71-.9-1.06-2.28-1.06-4.11v-15.1h4.79v15.1ZM689.96,795.45h4.79v20.27h-4.79v-20.27Z"/>
        <path d="M706.31,795.45h4.73l-5.29,20.27h-3.75l-5.33-20.27h4.71l2.5,13.4,2.44-13.4Z"/>
        <path d="M713.15,787.33h4.84v4.8h-4.84v-4.8ZM713.15,795.45h4.84v20.27h-4.84v-20.27Z"/>
        <path d="M388.28,822.93l4.75-4.08v8.93h-4.75v-4.84ZM393.85,822.93l4.75-4.08v8.93h-4.75v-4.84Z"/>
        <path d="M405.26,848.05h-4.84v-28.4h4.84v28.4Z"/>
        <path d="M408.54,827.78h4.88v20.27h-4.88v-20.27ZM416.51,833.73c0-.64-.13-1.12-.39-1.46-.26-.33-.65-.5-1.17-.5s-.89.16-1.14.49c-.25.33-.38.81-.38,1.45l-.41-2.4c.42-1.25,1.01-2.21,1.79-2.88.77-.67,1.64-1.01,2.59-1.01,1.25,0,2.22.45,2.92,1.35.7.9,1.04,2.16,1.04,3.79v15.49h-4.84v-14.32Z"/>
        <path d="M427.72,847.69c-.95-.44-1.68-1.07-2.18-1.91-.5-.84-.75-1.85-.75-3.02v-9.45c0-1.24.25-2.29.75-3.17.5-.88,1.23-1.55,2.18-2.01.95-.46,2.1-.69,3.44-.69s2.49.23,3.46.69c.96.46,1.7,1.14,2.2,2.02.5.89.75,1.95.75,3.2v1.76h-4.77v-1.33c0-.64-.14-1.13-.41-1.48s-.66-.53-1.17-.53-.88.17-1.15.52c-.27.35-.41.82-.41,1.42v8.67c0,.52.14.92.41,1.21.27.29.66.43,1.15.43s.9-.15,1.16-.46c.27-.31.4-.74.4-1.3v-1.23l4.82.06v1.6c0,1.19-.25,2.2-.76,3.05-.51.85-1.24,1.49-2.21,1.94-.96.45-2.12.67-3.48.67s-2.49-.22-3.44-.65Z"/>
        <path d="M445.14,841.9c0,.44.11.79.34,1.03.23.24.54.36.95.36h.61v4.77h-1.78c-1.56,0-2.78-.47-3.65-1.41-.87-.94-1.31-2.26-1.31-3.96v-23.03h4.84v22.25Z"/>
        <path d="M454.12,842.87c0,.52.12.91.35,1.15.23.25.6.37,1.09.35.47.01.82-.11,1.05-.38.23-.27.35-.67.35-1.2l.27,1.97c-.33,1.2-.79,2.09-1.39,2.69-.6.59-1.34.89-2.23.89-1.45,0-2.52-.45-3.23-1.36-.71-.9-1.06-2.28-1.06-4.11v-15.1h4.79v15.1ZM456.97,827.78h4.79v20.27h-4.79v-20.27Z"/>
        <path d="M467.66,847.56c-.96-.52-1.71-1.25-2.25-2.18-.53-.93-.8-2.01-.8-3.23v-.33l4.49-.04v.31c0,.56.17,1.02.52,1.38.34.36.79.54,1.34.54s.99-.17,1.32-.5.5-.76.5-1.28c0-.43-.12-.79-.36-1.09-.24-.3-.54-.54-.91-.73-.36-.19-.87-.41-1.52-.67-.12-.05-.24-.1-.36-.15-.12-.04-.24-.09-.36-.15-.03-.01-.06-.03-.1-.04-.04-.01-.07-.03-.1-.04-.81-.35-1.51-.75-2.12-1.21-.61-.46-1.1-1.07-1.49-1.84-.39-.77-.59-1.72-.59-2.85s.25-2.24.74-3.15c.49-.92,1.19-1.63,2.09-2.13.9-.5,1.93-.75,3.09-.75s2.23.24,3.12.73,1.6,1.18,2.1,2.08c.5.9.75,1.93.75,3.11v.35l-4.51.06v-.41c0-.47-.13-.85-.4-1.14-.27-.29-.62-.44-1.04-.44s-.76.16-1.02.47c-.26.31-.39.72-.39,1.21s.13.9.39,1.22c.26.32.59.58,1,.77.4.2.94.41,1.62.64.03.01.05.02.07.03.02,0,.04.02.07.03.03.01.05.02.08.03.03,0,.05.02.08.03.94.33,1.72.69,2.35,1.08.63.4,1.17.99,1.6,1.79.44.79.65,1.85.65,3.16,0,1.21-.26,2.28-.79,3.2-.53.92-1.27,1.64-2.24,2.15-.96.51-2.07.76-3.32.76s-2.34-.26-3.3-.78Z"/>
        <path d="M479.65,819.65h4.84v4.8h-4.84v-4.8ZM479.65,827.78h4.84v20.27h-4.84v-20.27Z"/>
        <path d="M490.34,847.69c-.91-.44-1.6-1.07-2.08-1.89-.48-.83-.72-1.82-.72-2.98v-9.96c0-1.15.24-2.12.72-2.94.48-.81,1.17-1.43,2.08-1.86.9-.42,2-.63,3.27-.63s2.37.21,3.28.63c.91.42,1.6,1.04,2.08,1.86.48.81.71,1.79.71,2.94v10.02c0,1.15-.24,2.13-.71,2.95-.48.82-1.17,1.45-2.08,1.88-.91.43-2.01.64-3.28.64s-2.37-.22-3.27-.65ZM490.72,822.4l4.8-6.93,3.63,2.89-5.18,6.56-3.26-2.52ZM494.57,843.6c.22-.26.33-.64.33-1.13v-9.24c0-.47-.11-.83-.33-1.08-.22-.25-.54-.38-.96-.38-.39,0-.7.13-.94.39-.23.26-.35.62-.35,1.07v9.24c0,.5.11.87.34,1.13.23.26.54.39.95.39s.74-.13.96-.39Z"/>
        <path d="M503.13,827.78h4.88v20.27h-4.88v-20.27ZM511.1,833.73c0-.64-.13-1.12-.39-1.46-.26-.33-.65-.5-1.17-.5s-.89.16-1.14.49c-.25.33-.38.81-.38,1.45l-.41-2.4c.42-1.25,1.01-2.21,1.79-2.88.77-.67,1.64-1.01,2.59-1.01,1.25,0,2.22.45,2.92,1.35.7.9,1.04,2.16,1.04,3.79v15.49h-4.84v-14.32Z"/>
        <path d="M528.17,847.69c-.95-.44-1.68-1.07-2.18-1.91-.5-.84-.75-1.85-.75-3.02v-9.45c0-1.24.25-2.29.75-3.17.5-.88,1.23-1.55,2.18-2.01.95-.46,2.1-.69,3.44-.69s2.49.23,3.46.69c.96.46,1.7,1.14,2.2,2.02.5.89.75,1.95.75,3.2v1.76h-4.77v-1.33c0-.64-.14-1.13-.41-1.48s-.66-.53-1.17-.53-.88.17-1.15.52c-.27.35-.41.82-.41,1.42v8.67c0,.52.14.92.41,1.21.27.29.66.43,1.15.43s.9-.15,1.16-.46c.27-.31.4-.74.4-1.3v-1.23l4.82.06v1.6c0,1.19-.25,2.2-.76,3.05-.51.85-1.24,1.49-2.21,1.94-.96.45-2.12.67-3.48.67s-2.49-.22-3.44-.65Z"/>
        <path d="M543.12,847.69c-.91-.44-1.6-1.07-2.08-1.89-.48-.83-.72-1.82-.72-2.98v-9.96c0-1.15.24-2.12.72-2.94.48-.81,1.17-1.43,2.08-1.86.9-.42,2-.63,3.27-.63s2.37.21,3.28.63c.91.42,1.6,1.04,2.08,1.86.48.81.71,1.79.71,2.94v10.02c0,1.15-.24,2.13-.71,2.95-.48.82-1.17,1.45-2.08,1.88-.91.43-2.01.64-3.28.64s-2.37-.22-3.27-.65ZM547.35,843.6c.22-.26.33-.64.33-1.13v-9.24c0-.47-.11-.83-.33-1.08-.22-.25-.54-.38-.96-.38-.39,0-.7.13-.94.39-.23.26-.35.62-.35,1.07v9.24c0,.5.11.87.34,1.13.23.26.54.39.95.39s.74-.13.96-.39Z"/>
        <path d="M555.9,827.78h4.88v20.27h-4.88v-20.27ZM563.87,833.73c0-.64-.13-1.12-.39-1.46-.26-.33-.65-.5-1.17-.5s-.89.16-1.14.49c-.25.33-.38.81-.38,1.45l-.41-2.4c.42-1.25,1.01-2.21,1.79-2.88.77-.67,1.64-1.01,2.59-1.01,1.25,0,2.22.45,2.92,1.35.7.9,1.04,2.16,1.04,3.79v15.49h-4.84v-14.32Z"/>
        <path d="M583.65,819.65h3.4l6.78,28.4h-4.8l-3.67-18.61-3.67,18.61h-4.8l6.78-28.4ZM581.49,839.1h7.73v4.53h-7.73v-4.53Z"/>
        <path d="M596.13,827.78h4.9v20.27h-4.9v-20.27ZM604.3,833.73c0-.64-.14-1.12-.42-1.46-.28-.33-.69-.5-1.24-.5s-.92.16-1.19.49c-.27.33-.41.81-.41,1.45l-.41-2.52c.43-1.21,1.04-2.14,1.84-2.79.79-.65,1.69-.98,2.68-.98.86,0,1.59.18,2.2.55.61.37,1.06.9,1.38,1.6s.47,1.56.47,2.58v15.9h-4.88v-14.32ZM612.44,833.73c0-.64-.14-1.12-.41-1.46s-.67-.5-1.19-.5c-.55,0-.96.16-1.24.49-.28.33-.42.81-.42,1.45l-.35-2.6c.44-1.2,1.06-2.11,1.86-2.74.79-.63,1.68-.95,2.66-.95.83,0,1.55.2,2.15.61.6.4,1.06.99,1.38,1.76.32.77.48,1.69.48,2.77v15.49h-4.9v-14.32Z"/>
        <path d="M623.59,847.69c-.91-.44-1.6-1.07-2.08-1.89-.48-.83-.72-1.82-.72-2.98v-9.96c0-1.15.24-2.12.72-2.94.48-.81,1.17-1.43,2.08-1.86.9-.42,2-.63,3.27-.63s2.37.21,3.28.63c.91.42,1.6,1.04,2.08,1.86.48.81.71,1.79.71,2.94v10.02c0,1.15-.24,2.13-.71,2.95-.48.82-1.17,1.45-2.08,1.88-.91.43-2.01.64-3.28.64s-2.37-.22-3.27-.65ZM627.81,843.6c.22-.26.33-.64.33-1.13v-9.24c0-.47-.11-.83-.33-1.08-.22-.25-.54-.38-.96-.38-.39,0-.7.13-.94.39-.23.26-.35.62-.35,1.07v9.24c0,.5.11.87.34,1.13.23.26.54.39.95.39s.74-.13.96-.39Z"/>
        <path d="M636.37,827.78h4.84v20.27h-4.84v-20.27ZM645.53,832.54h-.68c-1.15,0-2.04.35-2.68,1.04-.64.69-.96,1.63-.96,2.81l-.41-5.08c.59-1.21,1.34-2.16,2.26-2.85.92-.69,1.92-1.04,3.02-1.04v5.12h-.55Z"/>
        <path d="M647.4,819.65h4.75v4.84l-4.75,4.08v-8.93ZM652.97,819.65h4.75v4.84l-4.75,4.08v-8.93Z"/>
      </g>      
    </>,
    title: 'Modelo de Intervención Arduvi - "Inclusión con Amor"',
    content: <>
      <ul className="list-decimal pl-4">
        <li className="m-3">
          <h4 className="font-bold mb-2">Liderazgo transformacional como modelo de formación interna y de réplica para organizaciones que requieran del proceso:</h4>
          <p className="mb-2">
            Este ítem del modelo Arduvi -Inclusión con Amor- contempla; 1.1. Formación y capacitación continúa de personas, entidades y empresas en: Sensibilización sobre PcD, poblaciones vulnerables y flujos migratorios mixtos como factor de desarrollo y la guía sobre el proceso de inserción sociolaboral y; 1.2. Liderazgo transformacional y Power Skills para empleados del sector privado. 
          </p>
          <ul className="list-decimal pl-8">
            <li>
              Realizar campañas de sensibilización interna y externa para promover la inclusión y el respeto a las personas con discapacidad y otras poblaciones vulnerables. Además, brindar formación a los empleados sobre la importancia de la diversidad y la inclusión en el lugar de trabajo.
            </li>
            <li>
              Colaboraciones con organizaciones y expertos: Establecer alianzas con organizaciones y expertos en discapacidad para desarrollar y mejorar las políticas y prácticas de inclusión dentro de la empresa. Estas colaboraciones pueden ayudar a brindar capacitación, asesoramiento y acceso a recursos especializados.
            </li>
          </ul>
          <p>
            Curso corto, seminario o diplomado que certifica la Fundación ARDUVI a la empresa que se vincula.
          </p>
        </li>
        <li className="m-3">
          <h4 className="font-bold mb-2">Atención psicosocial e intervención en psicología clínica:</h4>
          <p className="mb-2">
            Atención e intervención psicosocial para el personal de la empresa y su núcleo familiar que se identifique requiera de un acompañamiento especializado, basado desde la biología del amor y centrado en la persona.
          </p>
          <ul className="list-disc pl-8 mb-2">
            <li><strong>Levantamiento de línea base:</strong> 1. Ficha de caracterización inicial. 2. Perfil de vulnerabilidad y generatividad del participante y su núcleo familiar. 3. Mapa de pertenencia. </li>
            <li><strong>Desarrollo de gestoría de casos para acceso y restablecimiento de derechos.</strong> Un (01) plan de trabajo del participante y su núcleo familiar y un (01) formulario de seguimiento a la gestoría de casos y sus avances. </li>
            <li><strong>Definición de proceso de atención e intervención en psicología clínica desde el enfoque Ecosistémico.</strong> (Formatos de: Entrevista inicial, valoración inicial y de seguimiento). – Historia Clínica del participante y su núcleo familiar. </li>
            <li><strong>Levantamiento de Línea final del participante y su núcleo familiar.</strong></li>
          </ul>
          <p className="italic text-sm">* Fundación ARDUVI cuenta con las herramientas que nos permiten brindar atención especializada en psicología clínica habilitada por el IDS.</p>
        </li>
        <li className="m-3">
          <h4 className="font-bold mb-2">Inserción sociolaboral:</h4>
          <p className="mb-2">
            En este ítem del modelo Arduvi -Inclusión con Amor- apuesta a incentivar la implementación de políticas internas y prácticas de inclusión sociolaboral que faciliten la contratación y el desarrollo profesional de las personas con capacidades especiales (PcE), poblaciones vulnerables, dentro de la empresa que desee trabajar con nosotros la R.S.E.
          </p>
          <p className="mb-2">
            Fundación Arduvi acompaña el proceso técnico, pedagógico, jurídico-legal y de adaptaciones en el lugar de trabajo, capacitación específica y programas de inserción laboral de personas con capacidades especiales (PcD) y poblaciones vulnerables que requiera la empresa. 
          </p>
          <p className="mb-2">
            Las y los participantes de la Fundación Arduvi con capacidades especiales cuentan con el acompañamiento y atención psicosocial y de intervención en psicología clínica como parte integral de su modelo tanto para ellos, como para sus familias.
          </p>
          <p>
            Así mismo, desde el equipo especializado de la Fundación, se asesora y acompaña a la empresa en <span className="font-bold underline">acceso a beneficios tributarios por la generación de empleo a personas pertenecientes a grupos vulnerables.</span>
          </p>
        </li>
        <li className="m-3">
          <h4 className="font-bold mb-2">Gestión del conocimiento e innovación social:</h4>
          <p className="mb-2">
            Fundación Arduvi dentro de su modelo de atención e intervención psicosocial e inserción sociolaboral brinda la asistencia técnica para propiciar la accesibilidad física y digital de la empresa para garantizar que los espacios físicos, como oficinas, puntos de atención y recaudo, sean accesibles para las personas con capacidades especiales. Además, asegura que los sitios web, aplicaciones móviles y otros medios digitales sean igualmente accesibles.
          </p>
          <ul className="pl-8 list-decimal">
            <li>
              <strong>Productos y servicios inclusivos:</strong> Desarrollar productos y servicios que sean accesibles y útiles para las personas con discapacidad. Esto puede implicar realizar adaptaciones en productos existentes o destacar la inclusión en nuevos lanzamientos
            </li>
          </ul>
        </li>
        <li className="m-3">
          <h4 className="font-bold mb-2">Sello dorado de Inclusión Social y Equidad ARDUVI – “Inclusión con Amor”.</h4>
          <p className="mb-2">
            Este es un reconocimiento a la excelencia y el compromiso que se otorga a las organizaciones que se destacan en la promoción de la inclusión social, laboral, así como en la equidad en el ámbito empresarial/institucional y un compromiso férreo por la salud mental de sus colaboradores.
          </p>
          <p>
            El Sello dorado ARDUVI representa un compromiso por parte de la organización hacia la inclusión de personas con diferentes capacidades, culturas, géneros y otros aspectos que promueven la diversidad y la igualdad de oportunidades en el territorio nacional. La obtención de este sello muestra que la empresa/entidad/organización ha implementado políticas y prácticas que fomentan un ambiente laboral inclusivo y equitativo.
          </p>
        </li>
        <li className="m-3">
          <h4 className="font-bold mb-2">Comunicación estratégica del RSE de la empresa:</h4>
          <p className="mb-2">
            Desde la Fundación, apoyamos y desarrollamos acciones que incentiven las rendiciones de cuenta a las contrapartes de la empresa desde un enfoque de transparencia, visibilización de las apuestas en materia de responsabilidad empresarial y aumento en el alcance de sus entornos digitales.
          </p>
          <p>
            Reportar públicamente las iniciativas y avances en materia de inclusión de personas con capacidades especiales (PcD). Esto permite crear transparencia y rendir cuentas sobre las acciones realizadas, así como compartir aprendizajes con otras empresas y organizaciones.
          </p>
        </li>
      </ul>
      <h4 className="font-bold mb-2">Servicios corporativos*:</h4>
      <p>
        La Fundación ARDUVI “inclusión con Amor” y a través de su Café Arduvi, brinda servicios que son requeridos por entidades del sector público, empresas del sector privado y organizaciones de la cooperación internacional, a saber:
      </p>
      <ul className="list-disc pl-4 mb-2">
        <li>
          Restaurante Café Arduvi atendido por nuestros participantes.  
        </li>
        <li>
          Servicios de catering para empresas y eventos en: Catering de buffet, catering de cóctel, catering de comida de calle, catering de plato servido, catering de estaciones temáticas, catering de desayuno y brunch. y catering de menú degustación. 
        </li>
        <li>
          Línea extensiva del Café Arduvi como laboratorio del modelo de inserción sociolaboral. 
        </li>
      </ul>
    </>
  }
]

function SocialResponsability() {
  return (
    <div className="bg-no-repeat bg-cover bg-fixed py-16">
      <h2 className="text-3xl text-center font-bold tracking-tight text-yellow-button sm:text-4xl py-4">Responsabilidad Social Empresarial con amor</h2>
      <div className="container mx-auto px-4 sm:px-6 lg:gap-x-8">
        <div className="container mx-auto max-w-4xl text-gray-500">
          <div className="flex flex-col lg:flex-row items-center">
            <div className="flex justify-center w-full lg:w-5/12">
              <svg
                viewBox="0 0 1080 1350"
                className="rse-svg"
              >
                {details.map((detail, index) =>(
                  <ModalRSE button={detail.button} title={detail.title} content={detail.content} bg={detail.bg} text={detail.text} key={index}/>
                ))}
              </svg>
            </div>
            <div className="w-full lg:w-7/12">
              <p className="mb-4 text-lg">
                Desde la Fundación Arduvi creemos en la responsabilidad social empresarial como eje fundamental del desarrollo organizacional. Nuestro modelo propio -Inclusión con Amor- permite ayudar a las empresas y organizaciones a contribuir en el mejoramiento y la transformación de la sociedad y grupos comunitarios en los que desarrollan sus ejercicios económicos, superando el cumplimiento de lo establecido en el marco normativo vigente.
              </p>
              <p className="text-lg mb-4">
                A través del establecimiento de convenio de asociación, acuerdo de partes, entre otros instrumentos jurídicos que se definan, la fundación ofrece los siguientes servicios/estrategias para fortalecer la RSE de las distintas organizaciones del país.
              </p>
              <p className="text-lg text-red-600 italic font-bold">"Para conocer más información pulsa sobre cada punto en la imagen"</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SocialResponsability;
